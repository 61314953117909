import React, { useContext, useEffect } from 'react'
import firebase from './../../lib/firebase';
import { StyledFirebaseAuth } from "react-firebaseui";
import { authContext } from './../../context/auth-context/auth-context'
import { Redirect } from 'react-router';

export interface LoginProps {

}


const uiConfig = {
    signInFlow: "popup",
    callbacks: {
        signInSuccessWithAuthResult: () => {
            window.location.href = '/';
            return true;
        }
    },
    signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
};

const Login: React.FC<LoginProps> = () => {
    const { currentUser } = useContext(authContext)
   // if (currentUser) return (<Redirect to='/' />)

    return (
        <span>
            <img
                width="100px"
                className="d-block mx-auto my-5"
                src="https://res.cloudinary.com/dltd4gs4a/image/upload/v1613712350/open-book_tveqmy.png"
            />
            <StyledFirebaseAuth
                className="signbox"
                uiConfig={uiConfig}
                firebaseAuth={firebase.auth()}

            />
        </span>
    );
}

export default Login;