import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonToast,
} from "@ionic/react";
import Select from "react-select";
import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router";
import { DashBoardContext } from "../../context/dashboard/dashboard.context";
import { createTask } from "../../lib/firebase";
const options = [
  { value: "Cleaning", label: "Cleaning" },
  { value: "Maintaining", label: "Maintaining" },
  { value: "Nothing", label: "Nothing" },
];

const AddEditTasks: React.FC = () => {
  const [successModal, setSuccessModal] = useState<boolean>(false);

  const { params } = useParams<{
    page: string;
    subPage: string;
    params: any;
  }>();

  const [selectedOption, setSelectedOption] = useState(null);
  const [showToast,setShowToast] = useState(false)

  const [taskCredential, setTaskCredential] = useState({
    taskName: "",
    taskDescription: "",
    taskType: "",
    taskAssignee: "",
    listingID: "",
    deadLine: "checkin",
    start: "checkout",
    creation: "onNewBooking",
  });
  const {
    taskName,
    taskDescription,
    taskType,
    taskAssignee,
    listingID,
    deadLine,
    start,
    creation,
  } = taskCredential;
  const { addTasks, tasks } = useContext(DashBoardContext);
  const history = useHistory();

  const handleChange = (e) => {
    if (e.value) {
      setTaskCredential({ ...taskCredential, taskType: e.value });
    } else {
      const { name, value } = e.target;
      setTaskCredential({ ...taskCredential, [name]: value });
    }
  };

  const handleCloseModal = () => {
    setSuccessModal(false);
  };
  return (
    <>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message="Successfully Task added"
        duration={200}
      />
      <div>
        <IonLabel> {params} Task</IonLabel>
        <IonGrid>
          <IonRow>
            <IonCol>
              <form noValidate autoComplete="off">
                <IonList>
                  <IonItem>
                    <IonLabel className="input-label" position="stacked">
                      Task Name
                    </IonLabel>
                    <IonInput
                      /* value={values.title} */
                      name="taskName"
                      value={taskName}
                      placeholder="Ex: Cleaning"
                      onIonChange={handleChange}
                    ></IonInput>
                  </IonItem>
                  {/*     <IonItem>
                    <IonSelect
                      value={gender}
                      placeholder="Select Date"
                      onIonChange={(e) => setGender(e.detail.value)}
                    >
                      <IonSelectOption value="sds">sd</IonSelectOption>
                    </IonSelect>
                  </IonItem> */}
                  <IonItem>
                    <IonLabel className="input-label" position="stacked">
                      Task Description
                    </IonLabel>
                    <IonInput
                      onIonChange={handleChange}
                      name="taskDescription"
                      value={taskDescription}
                      placeholder="Optional"
                      /* onIonChange={() => handleChange("title")} */
                    ></IonInput>
                  </IonItem>
                </IonList>
                <Select
                  name="taskType"
                  placeholder="select Template"
                  defaultValue={selectedOption}
                  onChange={(e: any) => {
                    setSelectedOption(e.value);
                    handleChange(e);
                  }}
                  options={options}
                />
                <IonList>
                  {/*   <IonItem lines="none">
                    <IonLabel className="input-label" position="stacked">
                      Date And Time
                    </IonLabel>

                    <IonRow>
                      <IonCol size="12">
                        <IonItem lines="none">
                          <IonSelect
                            value={gender}
                            placeholder="Select Start Date"
                            onIonChange={(e) => setGender(e.detail.value)}
                          >
                            <IonSelectOption value="sds">sd</IonSelectOption>
                          </IonSelect>
                        </IonItem>
                      </IonCol>
                      <IonCol size="12">
                        <IonItem lines="none">
                          <IonSelect
                            value={gender}
                            placeholder="Select End Date"
                            onIonChange={(e) => setGender(e.detail.value)}
                          >
                            <IonSelectOption value="sds">sd</IonSelectOption>
                          </IonSelect>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                  </IonItem> */}
                  {/* 
                  <IonRadioGroup
                    value={selected}
                    onIonChange={(e) => setSelected(e.detail.value)}
                  >
                    <IonListHeader>
                      <IonLabel>Assignee</IonLabel>
                    </IonListHeader>

                    <IonItem>
                      <IonLabel>Biff</IonLabel>
                      <IonRadio slot="start" value="biff" />
                    </IonItem>

                    <IonItem>
                      <IonLabel>Griff</IonLabel>
                      <IonRadio slot="start" value="griff" />
                    </IonItem>

                    <IonItem>
                      <IonLabel>Buford</IonLabel>
                      <IonRadio slot="start" value="buford" />
                    </IonItem>
                  </IonRadioGroup> */}

                  <IonItem>
                    <IonInput
                      value={listingID}
                      name="listingID"
                      onIonChange={handleChange}
                      placeholder="Listing ID"
                    ></IonInput>
                  </IonItem>

                  <IonItem>
                    <IonInput
                      value={taskAssignee}
                      name="taskAssignee"
                      onIonChange={handleChange}
                      placeholder="Search by name or ability"
                    ></IonInput>
                  </IonItem>
                </IonList>

                <IonButton
                  onClick={async () => {
                    setSuccessModal(true);;
                    createTask(...tasks);
                    addTasks([taskCredential]);
                    setShowToast(true)
                    //history.push("/dashboard/Tasks");
                  }}
                >
                  Save
                </IonButton>
                <IonButton fill="outline" onClick={() => history.push('dashboard/tasks')}>
                  Cancel
                </IonButton>
              </form>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};

export default AddEditTasks;
