import React from "react";
import {
  IonText,
  IonButton,
  IonIcon,
  IonContent,
  IonRow,
  IonCol,
} from "@ionic/react";
import { closeCircleOutline, accessibilityOutline } from "ionicons/icons";
import "./IntroModal.css";

interface ModalProps {
  setIntroModal: any;
}

const IntroModal: React.FC<ModalProps> = ({setIntroModal}) => {
  return (
  <IonContent>
    <IonRow className="intro-root ion-align-items-center">

      <IonCol sizeXs="12" sizeMd="6" pushXs="0" pushMd="6" className="modal-bg-img">
      <IonIcon  icon={closeCircleOutline} className="close-icon" onClick={()=>setIntroModal(false)}/>
      </IonCol>
      <IonCol sizeXs="12" sizeMd="6"  pullXs="0" pullMd="6">
      <IonText>
      
        <h4 style={{ fontWeight: "bold" }}>Team permission updates</h4>
        <p>
          Today we rolled out some changes to permission sets. Review your
          team's permissions to keep things running smoothly.
        </p>
        <h6><IonIcon  icon={accessibilityOutline}/> Check out the new task permissions</h6>
        <p>
          Allow cleaners, maintenance workers, and other service providers to
          view and update their assigned tasks.
        </p>
        <h6> <IonIcon  icon={accessibilityOutline}/> Grant new permissions for basic team members</h6>
        <p>Update permissions for basic team members.</p>
        <h6><IonIcon  icon={accessibilityOutline}/> Review who can edit listings and prices</h6>
        <p>
          Pricing and listing permissions have been combined, so make sure
          everyone has the right permissions.
        </p>
        </IonText>
        <IonButton className="new-button" onClick={()=>setIntroModal(false)}>See what's new</IonButton>
      </IonCol>
    </IonRow>
    </IonContent>
  );
};

export default IntroModal;
