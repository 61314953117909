import React from "react";

interface ContainerProps {
  name: string;
}

const TasksHeader: React.FC<ContainerProps> = ({ name }) => {
  return (
    <div>
      <strong>{name}</strong>
      <p>Tasks component here and initialized.</p>
    </div>
  );
};

export default TasksHeader;
