import React, { useContext, useState } from "react";
import { slideContext } from "./../context/slide-context/slide-context";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonListHeader,
  IonRadio,
  IonRadioGroup,
  IonRow,
} from "@ionic/react";
import "./SlideModal.css";
import { Step, templateContext } from "../context/template-context/template-context";

const ListComponent: React.FC<{ val: any }> = ({ val }) => {
  const { setSlideButtonDisableDisable, handleCurrentStep } = useContext(
    slideContext
  );

  return (
    <>
      <IonRadioGroup name="type" value={val} onIonChange={handleCurrentStep}>
        <IonListHeader>
          <h3>select the step type</h3>
        </IonListHeader>

        <IonItem
          lines="none"
          onClick={() => setSlideButtonDisableDisable(false)}
        >
          <IonLabel>check List Item</IonLabel>
          <IonRadio slot="start" value="checklist" />
        </IonItem>

        <IonItem
          lines="none"
          onClick={() => setSlideButtonDisableDisable(false)}
        >
          <IonLabel>Ask For the text Input</IonLabel>
          <IonRadio slot="start" value="input" />
        </IonItem>
        <IonItem
          lines="none"
          onClick={() => setSlideButtonDisableDisable(false)}
        >
          <IonLabel>Ask For a Photo</IonLabel>
          <IonRadio slot="start" value="photo" />
        </IonItem>
      </IonRadioGroup>
    </>
  );
};
const StepInstruction: React.FC<{ val: any }> = ({ val }) => {
  const { setSlideButtonDisableDisable, handleCurrentStep } = useContext(
    slideContext
  );

  return (
    <>
      <h3>Step Instruction</h3>
      <textarea
        name="label"
        value={val.label}
        onChange={(e) => {
          handleCurrentStep(e);
          if (e.target.value.length >= 5) {
            setSlideButtonDisableDisable(false);
          }
        }}
        style={{ width: "95%", height: "200px" }}
      ></textarea>
    </>
  );
};

const AdditionalComponent: React.FC<{ val: any }> = ({ val }) => {
  const { setIsButtonDisable } = useContext(templateContext);
  const { handleCurrentStep } = useContext(slideContext);

  return (
    <>
      <h3>
        Additional Information <br /> Optional
      </h3>
      <textarea
        name="additionalInfo"
        value={val.additionalInfo}
        onChange={(e) => {
          handleCurrentStep(e);

          if (e.target.value.length >= 5) {
            setIsButtonDisable(false);
          }
        }}
        style={{ width: "95%", height: "200px" }}
      ></textarea>
    </>
  );
};

const getAppropritaeComponent = (step: number, currentStep) => {
  switch (step) {
    case 1:
      return <ListComponent val={currentStep.type} />;
    case 2:
      return <StepInstruction val={currentStep.label} />;
    case 3:
      return <AdditionalComponent val={currentStep.additionalInfo} />;
  }
};

const SlideModel: React.FC<{ isTrue: boolean; handleTrue; clearState: () => void; handleStepShow: () => void }> = (props) => {
  const { handleSteps,handleButtonShow } = useContext(templateContext)
  const [stepCount, setStepCount] = useState(1);
  const [showButton, setShowbutton] = useState(true);
  const [showSubmit, setShowSubmit] = useState(false);
  const [currentStep, setCurrentStep] = useState<Step>({
    id: "",
    additionalInfo: "",
    label: "",
    response: "",
    type: "",
  });

  const handleCurrentStep = (e) => {
    const { name, value } = e.target;
    setCurrentStep({ ...currentStep, [name]: value });
  };

  const [slideButtonDisable, setSlideButtonDisableDisable] = useState<boolean>(
    true
  );

  const handleSetIsButtonDisable = (val: boolean) => {
    setSlideButtonDisableDisable(val);
  };

  const stepHandler = () => {
    if (stepCount === 2) {
      setShowbutton(false);
      setShowSubmit(true);
    }
    if (stepCount < 3) {
      setStepCount(stepCount + 1);
    }
  };
  return (
    <slideContext.Provider
      value={{
        slideButtonDisable,
        setSlideButtonDisableDisable: (p: boolean) =>
          handleSetIsButtonDisable(p),
        currentStep,
        handleCurrentStep,
      }}
    >
      <IonGrid
        className={`animate animate-step${props.isTrue ? "-action" : ""}`}
      >
        <IonRow>
          <div style={{ width: "100%" }}>
            <IonCol sizeMd="8" className="ion-padding-vertical">
              <span>Step {stepCount}/3</span>
              <div className="mr-1">
                {getAppropritaeComponent(stepCount, currentStep)}
              </div>
              <IonButtons className="ion-justify-content-between">
                {showButton && (
                  <IonButton
                    disabled={slideButtonDisable}
                    fill="solid"
                    onClick={() => {
                      stepHandler();
                      setSlideButtonDisableDisable(true);
                    }}
                  >
                    Next
                  </IonButton>
                )}
                {showSubmit && (
                  <IonButton
                    fill="solid"
                    onClick={() => {
                      props.handleTrue(false);
                      handleSteps(currentStep)
                      setStepCount(1)
                      setShowSubmit(false)
                      setShowbutton(true)
                      props.handleStepShow()
                      handleButtonShow(true)

                    }}
                  >
                    Submit
                  </IonButton>
                )}
              </IonButtons>
            </IonCol>
          </div>
        </IonRow>
      </IonGrid>
    </slideContext.Provider>
  );
};

export default SlideModel;
