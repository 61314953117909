import { IonButton, IonCol, IonIcon, IonRow } from "@ionic/react";
import {
  caretDownOutline,
  chevronDownOutline,
  magnetOutline,
  medkitOutline,
} from "ionicons/icons";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import React from "react";

import "./reservations.css";
export interface ReservationProps {}

const Reservation: React.FC<ReservationProps> = () => {
  return (
    <div className="reservations">
      <div className="reservations__header">
        <h1 className="reservations__heading">Reservations</h1>
        <div className="reservations__buttons">
          <IonButton fill="outline">
            <IonIcon
              icon={magnetOutline}
              slot="start"
              /*  className="mr-icon" */
            />
            Filter
          </IonButton>
          <IonButton fill="outline">
            Export
            <IonIcon icon={chevronDownOutline} slot="end" />
          </IonButton>
          <IonButton fill="outline">Print</IonButton>
        </div>
      </div>
      <Tabs>
        <TabList>
          <Tab>Upcoming</Tab>
          <Tab>Completed</Tab>
          <Tab>Canceled</Tab>
          <Tab>All</Tab>
        </TabList>
        <div className="health__guide">
          <div className="health__icon">
            <IonIcon icon={medkitOutline} />
          </div>
          <div className="health__content">
            <h3 className="health__heading">
              Healthy and safety Guidence for hosts and guests
            </h3>
            <p>
              As travel Packs up again, we want to help out hosts and guests
              remain safe. Please follow the health and corona Guidelines
            </p>
          </div>
        </div>
        <TabPanel>
          <IonRow className="table-line">
            <IonCol className="cm-color-2">Status</IonCol>
            <IonCol className="cm-color-2">Guests</IonCol>
            <IonCol>
              <span className="check-col">
                Check-Ins
                <IonIcon icon={caretDownOutline} slot="end" />
              </span>
            </IonCol>
            <IonCol className="cm-color-2">Chechout</IonCol>
            <IonCol className="cm-color-2">Booked</IonCol>
            <IonCol className="cm-color-2">Expense</IonCol>
            <IonCol>&nbsp;</IonCol>
          </IonRow>
          <IonRow className="row-align">
            <IonCol>
              <span
                style={{
                  color: "#4E9B59",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              >
                Confirmed
              </span>
            </IonCol>
            <IonCol className="cell-name">Mathew jacobs</IonCol>
            <IonCol className="cm-color">MAR 3, 2021</IonCol>
            <IonCol className="cm-color">Feb 3, 2021</IonCol>
            <IonCol className="cm-color">FEB 4, 2021</IonCol>
            <IonCol className="cm-color"> 1013.56 $</IonCol>
            <IonCol>
              <IonButton fill="outline">Details</IonButton>
            </IonCol>
          </IonRow>
        </TabPanel>
        <TabPanel>
          <h2>Any content 2</h2>
        </TabPanel>
        <TabPanel>
          <h2>Any content 3</h2>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Reservation;
