import React, { useContext, useEffect, useState } from "react";
import "./cleaning.css";


import { Plugins, CameraResultType } from "@capacitor/core";
import { ItemReorderEventDetail } from "@ionic/core";
import parse from "url-parse";
const { Camera } = Plugins;



/* eslint-disable import/first */

import { Accordion, Card } from "react-bootstrap";
import { useHistory } from "react-router";
import firebase, {
    firestore, getMyTeam,
    getProperty,
    getScheduleRealtime,
    getTaskListById,
    storage,
    updateScheduleRealtime,
    uploadImageTask
} from "./../../lib/firebase";

import ConfirmationModal from './../../components/confirmationModal'



import {
    IonButton,
    IonCheckbox,
    IonCol,
    IonInput,
    IonTextarea,
    IonItem,
    IonLabel,
    IonList,
    IonReorderGroup,
    IonRow,
    IonContent,
    IonGrid,
    IonToast,
    IonModal,
    IonHeader,
    IonToolbar,
} from "@ionic/react";


import { defineCustomElements } from "@ionic/pwa-elements/loader";
import Timer from "../../components/timer";
import { authContext } from './../../context/auth-context/auth-context'
import { cogSharp } from "ionicons/icons";
import ExperimantalTimer from "../../components/experimentalTimer";

export interface CleaningProps {
    id?: string;
    action?: string;
}

function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
    // The `from` and `to` properties contain the index of the item
    // when the drag started and ended, respectively
    console.log("Dragged from index", event.detail.from, "to", event.detail.to);

    // Finish the reorder and position the item in the DOM based on
    // where the gesture ended. This method can also be called directly
    // by the reorder group
    event.detail.complete();
}



function getFormmatedTimeForText(timeArray) {

    const newArr: any = [...timeArray]

    let difference = 0;
    timeArray.forEach((time, index) => {
        if (index % 2 == 0) {
            difference += (timeArray[index + 1] - timeArray[index]);
        }
    });
    const daysDifference = Math.floor(difference/1000/60/60/24);
    difference -= daysDifference*1000*60*60*24;
    const hoursDifference = Math.floor(difference/1000/60/60);
    difference -= hoursDifference*1000*60*60;
    const minutesDifference = Math.floor(difference/1000/60);
    difference -= minutesDifference*1000*60;
    const secondsDifference = Math.floor(difference/1000);

/* 
    if(newArr.length % 2 === 1) {
            newArr.push(Date.now())
    }
   const date1 =   newArr[0]
  const date2 =   newArr[newArr.length -1]
  var difference =  new Date(date2).getTime() - new Date(date1).getTime() 

  var daysDifference = Math.floor(difference/1000/60/60/24);
  difference -= daysDifference*1000*60*60*24

  var hoursDifference = Math.floor(difference/1000/60/60);
  difference -= hoursDifference*1000*60*60

  var minutesDifference = Math.floor(difference/1000/60);
  difference -= minutesDifference*1000*60

  var secondsDifference = Math.floor(difference/1000); */
 

   return {minitue: minutesDifference,second: secondsDifference,hour: hoursDifference}
}




function getEmptyTaskList() {
    return {
        title: "placeholder task list title",
        sections: [
            {
                index: 0,
                title: "First Kitchen",
                description: "First kitchen on downstairs",
                tasks: [
                    {
                        index: 0,
                        title: "Wipe Counters",
                        requireImage: false,
                        requireText: false,
                        completed: false,
                        input: '',
                        image: ''
                    },
                    {
                        index: 1,
                        title: "Wipe Counters",
                        requireImage: true,
                        requireText: true,
                        completed: false,
                        input: '',
                        image: ''
                    },
                ],
            },
        ],
    };
}

function updateSchedule(propertyId, schedule) {
    updateScheduleRealtime(propertyId, schedule);
}

const Cleaning: React.FC<CleaningProps> = () => {
    const [isNotClockedIn, setIsNotClockedIn] = useState<boolean>(false)
    const [dataLoaded, setDataLoaded] = useState<boolean>(false)
    const [showCleaningClockOut, setShowCleaningClockOut] = useState<boolean>(false)
    const [clockPen, setCloclPen] = useState<boolean>(false)
    const [team, setTeam] = useState<any>({});
    const [photo, setPhoto] = useState("");
    const [property, setProperty] = useState<any>({});
    const [schedule, setSchedule] = useState<any>({})
    const [sections, setSections] = useState<any>()
    const [currentTaskList, setCurrentTaskList] = useState<any>(getEmptyTaskList());
    const [cleaningNotes, setCleaningNotes] = useState<any>("");
    const [clock, setClock] = useState<any>([]);
    const [timeObj, setTimeObj] = useState<any>({});
    const [currentIndexes, setCurrentIndexes] = useState<any>({ sectionIndex: '', taskIndex: '' });
    const [storageImageUrl, setStorageImageUrl] = useState<any>();
    const [cleaningToast, setCleaningToast] = useState({ isShow: false, message: '' });


    const{ currentUser} = useContext(authContext)

    const url = parse(window.location.href, true);

    const history = useHistory();
    useEffect(() => {
        defineCustomElements(window);
    });

    function updateTask(sectionIndex, taskIndex, taskKey, taskValue) {
        const newSchedule = schedule;
        if (newSchedule) {
            newSchedule.sections[sectionIndex].tasks[taskIndex][taskKey] = taskValue;
            updateScheduleRealtime(property.id, newSchedule);
        }
    }
    const takePicture = async (sectionIndex, taskIndex) => {
        const image: any = await Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            resultType: CameraResultType.Uri,
        });
        var xhr = new XMLHttpRequest;
        xhr.responseType = 'blob';
        xhr.onload = function () {
            var recoveredBlob = xhr.response;
            const storageRef = storage.ref();
            storageRef.child(`properties/${property.id}/cleanings/${schedule.id}/section-${sectionIndex}.task-${taskIndex}.${image.format}`).put(recoveredBlob).then((snapshot) => {
                storageRef.child(`properties/${property.id}/cleanings/${schedule.id}/section-${sectionIndex}.task-${taskIndex}.${image.format}`).getDownloadURL().then(async url => {
                    updateTask(sectionIndex, taskIndex, 'image', url);
                });
            });
        };
        xhr.open('GET', image.webPath);
        xhr.send();
    };

    useEffect(() => {
        if(!Object.keys(currentUser).length) return
        const url = parse(window.location.href, true);
        const getData = async (taskListId, propertyId, scheduleId) => {
            const docRef =  firestore.doc(`schedule/${url.query.propertyId}/dates/${url.query.scheduleId}`)
            const dataRef = await firestore.doc(`schedule/${url.query.propertyId}/dates/${url.query.scheduleId}`).get();
            const data: any = await  dataRef.data()
            //@ts-ignore
           if(data &&  data.trackedTime && data.trackedTime[currentUser.id] && data.trackedTime[currentUser.id].length % 2 !== 0) {
            //@ts-ignore
            data.trackedTime[firebase.auth().currentUser?.uid] = [...data.trackedTime[firebase.auth().currentUser?.uid],  Date.now()]
           await docRef.update(data)
          
           setInterval(() => {
window.location.reload()
           }, 1000)
           } 
 
            //propertyId - taskListId - scheduleId
            const [task, property] = await Promise.all([getTaskListById(taskListId), getProperty(propertyId)]);
            task && setSections(task.sections)
            if (property) {
                setProperty(property[0]);
            }
            getScheduleRealtime(propertyId, scheduleId).onSnapshot( async (doc) => {
                const dataSchedule: any = doc.data()
                setSchedule(doc.data());
                //@ts-ignore
                if(dataSchedule.trackedTime && dataSchedule.trackedTime[firebase.auth().currentUser?.uid]) {
   
                    //@ts-ignore
                  const data =  getFormmatedTimeForText(dataSchedule.trackedTime[firebase.auth().currentUser?.uid])
                setTimeObj(data)
                }
                setDataLoaded(true)

            });
            setCurrentTaskList(task)
        };
        getData(url.query.taskListId, url.query.propertyId, url.query.scheduleId);


    }, [currentUser]);

    useEffect(() => {
        const userId = firebase.auth().currentUser?.uid || '';
        const userEmail = firebase.auth().currentUser?.email || '';
        if (!clock.length && schedule && schedule.trackedTime && userId && schedule.trackedTime[userId]) {
            setClock(schedule.trackedTime[userId]);


          
        }
        getMyTeam(userEmail).then((team) => {
            setTeam(team[0]);
        })
    }, [firebase.auth().currentUser?.uid, schedule])



    useEffect(() => {
        const url = parse(window.location.href, true);
        if (currentTaskList && schedule && !schedule.sections) {
            updateScheduleRealtime(url.query.propertyId, {
                ...schedule,
                sections: [...currentTaskList.sections]
            });
        }





    }, [currentTaskList, schedule]);

    function addTimeToClock() {

        setClock([
            ...clock,
            Date.now()
        ]);

        
    }

    function isClockedOut() {
        return clock.length % 2 == 0;
    }

    function getCleanerName(cleanerId) {
        let memberName = '';
        if (team && team.members) {
            team.members.forEach((member) => {
                if (member.id === cleanerId) {
                    memberName = member.displayName;
                }
            });
        }
        return memberName;
    }

    function getAddedTime(timeArray) {
        let difference = 0;
        timeArray.forEach((time, index) => {
            if (index % 2 == 0) {
                difference += (timeArray[index + 1] - timeArray[index]);
            }
        });
        const daysDifference = Math.floor(difference/1000/60/60/24);
        difference -= daysDifference*1000*60*60*24;
        const hoursDifference = Math.floor(difference/1000/60/60);
        difference -= hoursDifference*1000*60*60;
        const minutesDifference = Math.floor(difference/1000/60);
        difference -= minutesDifference*1000*60;
        const secondsDifference = Math.floor(difference/1000);
        return (hoursDifference + ' hours ' + minutesDifference + ' minutes ' + secondsDifference + 'seconds');
    }

    async function submitCleaning() {
        let error = { message: '' }
        //
        // if (schedule.sections) {
        //     schedule.sections.forEach(element1 => {
        //         element1.tasks.forEach(element2 => {
        //             if (element2.requireImage || element2.requireText) {
        //                 if (!element2.input) error.message = `'Please Fill all necessary Fields`
        //                 if (!element2.image && element2.requireImage) error.message = 'Please Fill all necessary Fields'
        //             }
        //         })
        //     });
        // }
        // if (error && error.message) {
        //     setCleaningToast({ isShow: true, message: error.message })
        //     return;
        // }


        const updateRef = await firestore.doc(`schedule/${url.query.propertyId}/dates/${url.query.scheduleId}`)
        await updateRef.update({
            submittedBy: firebase.firestore.FieldValue.arrayUnion(currentUser.id)
        })
        const docRef = await firestore.doc(`schedule/${url.query.propertyId}/dates/${url.query.scheduleId}`).get()
        const data: any = await docRef.data();



        (Object.keys(data.trackedTime) || []).forEach((userId) => {
            if(!(data.trackedTime[userId].length % 2 == 0)) {
                data.trackedTime[userId] = [...data.trackedTime[userId],  Date.now()]

            }

        })


  

        let finalClock = clock;

        if (!isClockedOut() && !error.message) {
            finalClock = [...clock, Date.now()]
        }
        let totalTimeDifference = 0;
        for (var x = 0; x < finalClock.length; x++) {

        }
        finalClock.forEach((time, index) => {
            if (index % 2 == 0) {
                totalTimeDifference += (finalClock[index + 1] - finalClock[index]);
            }
        });
        schedule.adjustableBillableTime = schedule.adjustableBillableTime || {};
        // @ts-ignore
        schedule.adjustableBillableTime[firebase.auth().currentUser?.uid]= parseFloat((totalTimeDifference/1000/60/60).toFixed(2));

        schedule.trackedTime = data.trackedTime || {};
        // @ts-ignore
        schedule.trackedTime[firebase.auth().currentUser?.uid] = finalClock;

        schedule.cleaningNotes = cleaningNotes;
        schedule.status = 'Completed';



         await updateSchedule(property.id, {
            ...schedule,
        }) 
        setCleaningToast({ isShow: true, message: 'Submitted Cleaning Successfully' })
        setTimeout(() => {
            history.push('dashboard/schedule')

        }
            , 1000);



    }

    const handleStorageImage = (url) => {
        setStorageImageUrl(url)
    }


    useEffect(() => {
        if (!photo) return;
        const url = parse(window.location.href, true);
        const { propertyId, scheduleId } = url.query
        const { sectionIndex, taskIndex } = currentIndexes
        const getData = async () => {
            if (!storageImageUrl) {
                await uploadImageTask(propertyId, scheduleId, sectionIndex, taskIndex, photo, handleStorageImage);
            } else {

                updateTask(sectionIndex, taskIndex, 'image', storageImageUrl)
            }

        }

        getData()



    }, [photo, storageImageUrl])


    const saveTimeToDB = async () => {

         let finalClock = clock;
        const newSchedule = {...schedule}
/*         
        if (!isClockedOut()) {
            finalClock.push(Date.now())
        } */

        newSchedule.trackedTime = schedule.trackedTime || {};

        // @ts-ignore
      newSchedule.trackedTime[firebase.auth().currentUser?.uid] = finalClock; 
    
      console.log(finalClock)

        const updateRef = await firestore.doc(`schedule/${url.query.propertyId}/dates/${url.query.scheduleId}`)

      await updateRef.update(newSchedule) 
 
    }

/*     useEffect(() => {

        if(!Object.keys(currentUser).length) return

        ( async () => {
            const ref = firestore.doc(`schedule/${url.query.propertyId}/dates/${url.query.scheduleId}`)
            const updateRef: any = await firestore.doc(`schedule/${url.query.propertyId}/dates/${url.query.scheduleId}`).get()
            const data = await updateRef.data()
            console.log(data, firebase.auth().currentUser?.uid)
            //@ts-ignore
            if(data.trackedTime[firebase.auth().currentUser?.uid].length % 2 !== 0) {

  
    
                    // @ts-ignore
                    data.trackedTime[firebase.auth().currentUser?.uid] = [...data.trackedTime[firebase.auth().currentUser?.uid], Date.now()]; 
                                      // @ts-ignore

                    const date =  getFormmatedTimeForText(data.trackedTime[firebase.auth().currentUser?.uid])
                    console.log(date)
                    setTimeObj(date)                
            
                  await ref.update(data) 

            }

        })()

    }, [currentUser]) */


    useEffect(() => {


      if(!clock.length) return

                    const getData = async () => {
            let finalClock = clock;
            const newSchedule = {...schedule}
    
            newSchedule.trackedTime = schedule.trackedTime || {};
    
            // @ts-ignore
          newSchedule.trackedTime[firebase.auth().currentUser?.uid] = finalClock; 
        
    
            const updateRef = await firestore.doc(`schedule/${url.query.propertyId}/dates/${url.query.scheduleId}`)
    
          await updateRef.update(newSchedule) 
        }


        getData()
     



    }, [clock])


  return (
      <>
      {
          !dataLoaded ? (
            <h1 className='loader'></h1>
          ) : (
            <IonContent>
            <IonGrid>
  
                <IonRow>
                        <IonToast
                            isOpen={cleaningToast.isShow}
                            onDidDismiss={() => setCleaningToast({ isShow: false, message: '' })}
                            message={cleaningToast.message}
                            duration={2000}
                        />
                        <IonCol sizeMd="8" offsetMd='2' size='12' offset='0'>
                        <IonRow>
                            <IonCol>
  
                                <div className="property-description d-flex">
                                    <img
                                            src={property?.thumbnail ? property.thumbnail : 'https://res.cloudinary.com/dltd4gs4a/image/upload/v1618910694/WhatsApp_Image_2021-04-20_at_2.36.27_AM_xf1gpj.jpg'}
                                        alt=""
                                        className="property-image-details"
                                    />
                                    <div className="ml-2">
                                        <h5 className="ion-no-padding">
                                            {property?.title}
  
                                        </h5>
                                        <h6 className="unit-wrap ion-no-padding">
                                                <span>Unit: {property && property.unit && property.unit.toString()}</span> <span>Access Code: {property?.accessCode}</span>
                                        </h6>
                                    </div>
                                </div>
                                <h6>{property.address?.stringFormat}</h6>
                                <h6>
                                    Property Access:{" "}
                                    <span className="font-weight-normal">
                                        {property.accessCode}
                                    </span>
                                </h6>
                                <h6>
                                    Additional Notes:{" "}
                                    <span className="font-weight-normal">
                                        {" "}
                                        {schedule?.notesToCleaner}
                                    </span>
                                    </h6>
  
                               <Timer timerObject={timeObj} handleTime={addTimeToClock} handleSave={saveTimeToDB}/>

                                {schedule && schedule.status === 'Completed'  ? (
                                    (Object.keys(schedule.trackedTime) || []).map((cleanerId) => {
                                        return (
                                            <div>
                                                <b>{getCleanerName(cleanerId)}</b>
                                                <div key={'cleaner-tracked-time-' + cleanerId}>
                                                    {getAddedTime(schedule.trackedTime[cleanerId])}
                                                </div>
                                                <div>
                                                    <label className='mr-2'>Adjustable Billable Time (Hours)</label>
                                                    <input type='number' step='0.10' value={schedule.adjustableBillableTime[cleanerId]} placeholder='0.00'
                                                      onChange={(e) => {
                                                          schedule.adjustableBillableTime[cleanerId] = parseFloat(e.target.value);
                                                          updateSchedule(property.id, {
                                                              ...schedule
                                                          });
                                                      }}/>
                                                </div>
                                            </div>
                                        );
                                    })
                                  ) :
  
                                  <>
                                  {clockPen && <p style={{color: 'red'}}> Your time is login in..... </p>}
    {/*                                 <IonButton fill="outline" onClick={() => {
                                        if(!clockPen) {
                                          setCloclPen(true)
                                        } else {
                                          setCloclPen(false)
                                        }
                                      addTimeToClock();
                                    }}>Clock {isClockedOut() ? 'In' : 'Out'}</IonButton> */}
                                    </>
                                }
  
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <Accordion defaultActiveKey="0">
                                    <IonReorderGroup
                                        disabled={false}
                                        onIonItemReorder={doReorder}
                                    >
                                        {schedule && schedule.sections &&
                                        ((currentTaskList && currentTaskList.sections) || schedule.status === 'Completed') &&
                                            schedule.sections.map((section, sectionIndex) => (
                                                <Card key={"section-" + sectionIndex}>
                                                    <div
                                                        className="p-0"
                                                    >
                                                        <Card.Header>
                                                            <h1>{section.title || 'Untitled Area'}</h1>
                                                        </Card.Header>
                                                    </div>
                                                    <div>
                                                        <Card.Body>
                                                            <IonReorderGroup
                                                                disabled={false}
                                                                onIonItemReorder={doReorder}
                                                            >
                                                                {section.tasks &&
                                                                    section.tasks.map((task, taskIndex) => (
                                                                        <IonList className='lines flex-column' key={"section-task-" + taskIndex}>
  
                                                                            {schedule && schedule.status === 'Completed' ? (
                                                                                <span>
                                                                                    <IonLabel className="margin-center">
                                                                                        {task.title}
                                                                                    </IonLabel>
                                                                                    <p>
                                                                                        {task.input}
                                                                                    </p>
                                                                                    {task.image ? <img src={task.image} /> : ''}
                                                                                </span>
                                                                            ) :(
                                                                            <span>
                                                                                <IonItem lines="none">
                                                                                    <p></p>
                                                                                    <IonLabel className="margin-center">
                                                                                        {task.title}
                                                                                    </IonLabel>
                                                                                    <IonCheckbox slot="end" checked={schedule.sections[sectionIndex].tasks[taskIndex].completed} onIonChange={e => updateTask(sectionIndex, taskIndex, 'completed', e.detail.checked)} />
                                                                                </IonItem>
                                                                                <IonRow className='d-flex justify-content-center align-items-center'>
                                                                                            <IonCol sizeMd="7" size='12'>
                                                                                        {task.requireText && (
                                                                                            <IonItem>
                                                                                                <IonLabel
                                                                                                    className="margin-center "
                                                                                                    position="stacked"
                                                                                                >
                                                                                                    {" "}
                                                                                                    {task.title}
                                                                                                </IonLabel>
                                                                                                <IonInput debounce={500} onIonChange={(e) => updateTask(sectionIndex, taskIndex, 'input', e.detail.value)} />
                                                                                            </IonItem>
                                                                                        )}
                                                                                    </IonCol>
                                                                                    <IonCol size='2'>
                                                                                        {task.requireImage && (
                                                                                            <IonButton fill="outline" onClick={() => {
                                                                                                setCurrentIndexes({ sectionIndex: sectionIndex, taskIndex: taskIndex })
                                                                                                takePicture(sectionIndex, taskIndex)
                                                                                            }}>
                                                                                                { task.image ? 'Replace' : 'Take' } Photo
                                                                                            </IonButton>
                                                                                        )}
                                                                                        {task.image ? <img src={task.image} /> : ''}
                                                                                    </IonCol>
                                                                                </IonRow>
                                                                            </span>
                                                                            )}
                                                                        </IonList>
                                                                    ))}
                                                            </IonReorderGroup>
                                                        </Card.Body>
                                                    </div>
                                                </Card>
                                            ))}
                                    </IonReorderGroup>
                                </Accordion>
                            </IonCol>
                        </IonRow>
                        {schedule && schedule.status === 'Completed' ? (
                            <IonRow>
                                <IonCol>
                                    <div className="additional-note ion-no-padding">
                                        <h1 style={{ fontSize: "16px" }}>Additional Cleaning Notes:</h1>
                                        <p>{schedule.cleaningNotes || 'No notes'}</p>
                                    </div>
                                </IonCol>
                            </IonRow>
                        ) : (
                            <IonRow>
                                <IonCol>
                                    <div className="additional-note ion-no-padding">
                                        <h1 style={{ fontSize: "16px" }}>Additional Cleaning Notes</h1>
                                                <IonTextarea className="additional-text" value={schedule?.cleaningNotes} debounce={500} onIonChange={(e) => setCleaningNotes(e.detail.value)} />
                                    </div>
                                    <IonButton fill="outline" onClick={  () => {
return  setShowCleaningClockOut(true)

}}>Submit Cleaning</IonButton>
                                </IonCol>
                            </IonRow>
                        )}
                      </IonCol>
                  </IonRow>
                  
          <IonModal
                    cssClass="confirmation-modal"
                    swipeToClose={true}
                    isOpen={showCleaningClockOut}>
                    <ConfirmationModal
                    actionText='Submit'
                     text="Are you sure to Clockout All the cleaners"
                        heading='submit the cleaning'
                        handleCloseModal={() => setShowCleaningClockOut(!showCleaningClockOut)}
                        handleSubmit={() => {
                            submitCleaning()
                        }} />
                </IonModal>
              </IonGrid>
          </IonContent>
  
          )
      }

     
          <IonToast
              isOpen={isNotClockedIn}
              onDidDismiss={() => setIsNotClockedIn(false)}
              message="Your settings have been saved."
              duration={200}
          />


      </>
    )
};

export default Cleaning;


/* 

Need to add zero before every number and also calculate time not even number

Need to work on getting all cleanners working time

// and then filtered them out by data


*/