import {
  IonButton,
  IonCol,
  IonContent,
  IonFabButton,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonRow,
} from "@ionic/react";
import "./cardBox.css";

import { add, bedOutline, keyOutline, waterOutline } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { taskDetailsContext } from "../context/task-details/task-details";
import "./taskdetails-card.css";
import { useHistory } from "react-router";
import { DashBoardContext } from "../context/dashboard/dashboard.context";
import AssigneeCard from "./assignee-card";
import { firestore } from "./../lib/firebase";


const CardBox: React.FC<{ taskDetails }> = ({ taskDetails }) => {
  const [listAssignee, setListAssignee] = useState([]);
  const { setShowCard, assignee } = useContext(taskDetailsContext);
  const { properties } = useContext(DashBoardContext);
  const [showAssigneeModal, setShowAssigneeModal] = useState(false);
  const [assigneeQuery, setAssigneeQuery] = useState("");
  const property = properties[taskDetails.listingID];

  useEffect(() => {
    //API call

    const getData = async () => {

      const userCollection = firestore.collection("users");
      const docRef = await userCollection
        .where("email", "==", assigneeQuery)
        .get();

      const data = docRef.docs.map((el) => el.data());
      //@ts-ignore
      setListAssignee(data);
    };

    getData();
  }, [assigneeQuery]);
  const history = useHistory();
  return (
    <>
      <IonModal isOpen={showAssigneeModal}>
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                <h1
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  Select The Assignee
                </h1>
                <IonItem>
                  <IonLabel position="floating">
                    {" "}
                    Search Assignee By Name
                  </IonLabel>
                  <IonInput
                    debounce={200}
                    onIonChange={(el) => {
                      setAssigneeQuery(el.detail.value!);
                    }}
                    value={assigneeQuery}
                  />
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12">
                <div className="member-search">
                  <IonRow>
                    <h5>Selected Assignee</h5>
                    <IonCol size="12">
                      {assignee.map((el) => {
                      
                        return (
                          // @ts-ignore
                          <span className="assigned-mem">{el.displayName}</span>
                        );
                      })}
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    {listAssignee.length > 0 ? (
                      listAssignee.map((el, idx) => {
                        return <AssigneeCard key={idx} data={el} idx={idx} />;
                      })
                    ) : (
                      <span>No data</span>
                    )}
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonButton>Cancel</IonButton>
                      <IonButton
                        onClick={() => {
                          //Have to make a API call
                          setShowAssigneeModal(false);
                        }}
                      >
                        Submit
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>

      <div>
        <div className="header-card">
          <span onClick={() => setShowCard(false)}>x</span>
        </div>
        <img
          width="100% important"
          height="400px"
          src={property?.images[0]}
          alt=""
        />
        <div style={{ padding: "1rem" }}>
          <h6 className="ion-no-margin task-item__heading">
            {" "}
            5:30AM. <span style={{ color: "#958B3E" }}>SCHEDULED</span>
          </h6>
          <strong className="demo">windows</strong>
          <br />
          <h6 className="task-item__description ion-no-margin">
            <span>
              {" "}
              {property?.address["state"]} {property?.address["city"]}{" "}
              {property?.address["country"]}
            </span>
          </h6>

          <IonRow>
            <IonCol>
              <IonIcon icon={keyOutline} />
              <IonLabel> {property?.bedroomCount} Bedrooms</IonLabel>
            </IonCol>
            <IonCol>
              <IonIcon icon={bedOutline} />
              <IonLabel>{property?.bathroomCount} Beds</IonLabel>
            </IonCol>
            <IonCol>
              <IonIcon icon={waterOutline} />
              <IonLabel>2 shawer</IonLabel>
            </IonCol>
          </IonRow>

          <div style={{ marginTop: "20px" }}>
            <IonLabel className="task-details-label">Assignee</IonLabel>

            <div className="task-details-fab">
              <IonFabButton color="primary" className="ion-fab-shadow">
                <IonIcon icon={add} />
              </IonFabButton>

              <IonLabel
                className="mf-label"
                onClick={() => setShowAssigneeModal(true)}
              >
                Add an Assign
              </IonLabel>
            </div>
          </div>
          {assignee.map((el) => {
            //@ts-ignore
            const { displayName, imageUrl } = el;
            return (
              <div className="assignee-acc-container">
                {imageUrl && <img src={imageUrl} alt="" />}
                <div className="assignee-acc">
                  <span>{displayName}</span>
                  <IonButton className="assignee-remove-btn" fill="outline">
                    Remove
                  </IonButton>
                </div>
              </div>
            );
          })}

          <IonList>
            <IonItem button>
              <IonLabel>Property Information</IonLabel>
            </IonItem>
            <IonItem button>
              <IonLabel
                className="task-details-label-e"
                onClick={() => history.push("/dashboard/tasks/add")}
              >
                Add task
              </IonLabel>
            </IonItem>
            <IonItem button>
              <IonLabel className="task-details-label-e">Cancel</IonLabel>
            </IonItem>
          </IonList>
        </div>
      </div>
    </>
  );
};

export default CardBox;
