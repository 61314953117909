import React, { useState, useEffect, useContext } from "react";
import Timekeeper from 'react-timekeeper';
import {
  IonButton,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonIcon,
  IonInput,
  IonToast,
  IonChip,
} from "@ionic/react";
import { chevronBackOutline, chevronDownOutline } from "ionicons/icons";
import { Formik } from "formik";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import * as Yup from "yup";
import "./AddEditListing.css";
import { AppContext } from "../../context/app-context";
import { Property } from "../../types";
import { CustomLoading } from "../../components/customLoading";
import { useHistory } from "react-router";
import { createPropertyToDb, getProperty, updateProperty } from "../../lib/firebase";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { Plugins, CameraResultType } from "@capacitor/core";
import { authContext } from "../../context/auth-context/auth-context";
const { Camera } = Plugins;

interface ContainerProps {
  name: string;
  action: string;
  listingId?: string;
  preloadedProperty?: Property | null;
}

function getPieceOfAddress(e, index) {
  if (!e.value.structured_formatting.secondary_text ||
      (e.value.structured_formatting.secondary_text.split(",").length - 1) < index) {
    return '';
  }
  return e.value.structured_formatting.secondary_text
      .split(",")[0]
      .trim()
      .replace(/[0-9]/g, "");
}

const AddEditListing: React.FC<ContainerProps> = ({
  action,
  listingId,
  preloadedProperty
}) => {
  console.log(
      action,
  )
  const history = useHistory();
  const [showToast, setShowToast] = useState(false);
  const [checkIn, setCheckIn] = useState('')
  const [checkOut, setCheckOut] = useState('')
  const [showCheckInTime, setShowCheckInTime] = useState<boolean>(false);
  const [showCheckOutTime, setShowCheckOutTime] = useState<boolean>(false);
  const [propertyLoading, setPropertyLoading] = useState<boolean>(true);
  const [propertySaving, setPropertySaving] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const { id } = useContext(authContext)

  const [addressValue, setAddressValue] = useState({
    city: "",
    state: "",
    country: "",
    street: "",
    stringFormat: "",
  });
  const [photo, setPhoto] = useState("");
  const [property, setProperty] = useState<any>(null);
  const [focus, setFocus] = useState<string>("");

  const globalState = useContext(AppContext);

  useEffect(() => {
    defineCustomElements(window);
  });

  const handleLoading = (val: boolean) => {
    setPropertyLoading(val)

  }




  useEffect(() => {
    if (
      globalState &&
      (globalState.state.isListingAdded || globalState.state.isListingUpdated)
    ) {
      globalState.dispatch({
        type: "RESET_LISTING_FLAGS",
      });
      history.push("/dashboard/listings");
    }
  }, [globalState.state.isListingAdded, globalState.state.isListingUpdated]);

  useEffect(() => {
    const getData = async () => {
      setPropertyLoading(true)

      const data: any = preloadedProperty || (await getProperty(listingId))[0];
      setProperty(data)
      setPhoto(data.thumbnail)
      setCheckIn(data.checkIn);
      setCheckOut(data.checkOut);
      setAddressValue({ ...data.address });
      setPropertyLoading(false)


    }
    if (action === 'edit') {
      getData();
    }






  }, [listingId, action])


  const getInitialValues = () => {
    return {
      title: property ? property.title : "",
      bedroomCount: property ? property.bedroomCount : "",
      bathroomCount: property ? property.bathroomCount : "",
      description: property ? property.description : "",
      accessCode: property ? property.accessCode : "",
      unit: property ? property.unit : "",
      address: {
        city: property ? property.address.city : "",
        state: property ? property.address.state : "",
        country: property ? property.address.country : "",
        street: property ? property.address.street : "",
        stringFormat: property ? property.address.stringFormat : "",
      },
      squareMeasurement: property ? property.squareMeasurement : "",
      thumbnail: property ? property.thumbnail : "",
    };
  };

  const updatedAddress = (e: any) => {
    geocodeByPlaceId("ChIJH_imbZDuDzkR2AjlbPGYKVE")
      .then((results) => console.log(results))
      .catch((error) => console.error(error));
    setAddressValue({
      city: getPieceOfAddress(e, 0),
      country: getPieceOfAddress(e, 2),
      state: getPieceOfAddress(e, 1),
      street: e.value.structured_formatting.main_text,
      stringFormat: e.label
    });
  };

  const takePicture = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Uri,
    });
    var imageUrl = image.webPath;
    setPhoto(imageUrl || "");
  };

  const validationSchema = () => {
    return Yup.object().shape({
      title: Yup.string().required("* Property title is required"),
      description: Yup.string().required("* Property description is required"),
      accessCode: Yup.string().required("* Property access code is required"),
      bedroomCount: Yup.string().required("* Bedroom count is required"),
      bathroomCount: Yup.string().required("* Bathroom count is required"),
      address: Yup.string().required("* Property address is required"),
      unit: Yup.string().required("* Property unit # or name is required"),
      squareMeasurement: Yup.string().required("* Property measurement is required"),
      // address: Yup.string().required("* Property address is required"),
    });
  };

  const validate = (getValidationSchema: any) => {
    return (values: any) => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };

  const getErrorsFromValidationError = (validationError: any) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors: any, error: any) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      };
    }, {});
  };

  const handleSubmit = async (values: any) => {
    if (!checkIn || !checkOut) {
      setIsError(true)
      console.log('error')
      return
    }
    const { bathroomCount, bedroomCount, unit, accessCode, squareMeasurement } = values
    const listing = {
      ...values,
      createdBy: id,
      bathroomCount: bathroomCount * 1,
      bedroomCount: bedroomCount * 1,
      squareMeasurement: squareMeasurement * 1,
      unit: unit,
      accessCode: accessCode * 1,
      address: {
        ...addressValue
      },
      checkIn,
      checkOut
    };


  if (action === 'edit') {
    await updateProperty(listing, listingId);
    history.push("/dashboard/listings");
  } else {
    setPropertySaving(true);
    await createPropertyToDb(listing, photo, handleLoading, history);
    console.log(propertyLoading)
    setShowToast(true);
  }
  };

  useEffect(() => {


  }, [action])



  return (
    action === 'add' || !propertyLoading ? (

      <span>
        <CustomLoading loading={propertySaving} />

        <div className="listing-add-edit">
          <IonToast
            isOpen={showToast}
            onDidDismiss={() => setShowToast(false)}
            message="Successfully Property added"
            duration={200}
          />
          { action !== 'edit' &&
            <IonButton
              onClick={() => history.push("/dashboard/listings")}
              fill="clear"
              className="back-btn"
            >
              <IonIcon slot="start" icon={chevronBackOutline} />
              Back to listing
            </IonButton>
          }
          { action !== 'edit' &&
            <IonRow>
              <IonText className="heading">{action} Listing</IonText>
            </IonRow>
          }
          {globalState && globalState.state.error ? (
            <IonGrid className="form-error-section">
              <IonText>{globalState.state.error}</IonText>
            </IonGrid>
          ) : null}
          <IonRow>
            <IonCol sizeMd='8' offsetMd='2' size='12' offset='0'>
              <Formik
                initialValues={property ? { ...property, ...addressValue } : getInitialValues()}
                //validate={validate(validationSchema)}
                onSubmit={handleSubmit}
              >
                {({ values, errors, handleChange, handleSubmit }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <IonGrid>
                        <IonLabel
                          className={
                            focus === "title"
                              ? "listing-focus-labels"
                              : "listing-labels"
                          }
                        >
                          Title
                        </IonLabel>
                        <IonInput
                          name="title"
                          value={values.title}
                          placeholder="Enter Property Title"
                          onIonChange={(e) => handleChange(e)}
                          onIonFocus={() => setFocus("title")}
                          className={
                            focus === "title"
                              ? "listing-focus-fields"
                              : "listing-fields"
                          }
                        ></IonInput>
                        {errors.title ? (
                          <IonText className="listing-errors">
                            {errors.title}
                          </IonText>
                        ) : (
                          <div className="listing-label-margin" />
                        )}
                      </IonGrid>
                      <IonGrid>
                        <IonLabel
                            className={
                              focus === "title"
                                  ? "listing-focus-labels"
                                  : "listing-labels"
                            }
                        >
                          Address
                        </IonLabel>
                        <div
                            style={{
                              width: "100%",
                              zIndex: 99,
                              marginTop: "12px",
                            }}
                        >
                          <GooglePlacesAutocomplete
                              apiKey="AIzaSyBctVpXpDF7LVk2qGvUmWV3PfK2rCgFaP8"

                              selectProps={{
                                addressValue,
                                onChange: updatedAddress,
                                styles: {
                                  menu: provided => ({ ...provided, zIndex: 9999 }),
                                },
                              }}
                          />
                        </div>
                        {errors.address ? (
                            <IonText className="listing-errors">
                              {errors.address}
                            </IonText>
                        ) : (
                            <div className="listing-label-margin" />
                        )}
                      </IonGrid>
                      <IonGrid>
                        <IonLabel
                          className={
                            focus === "unit"
                              ? "listing-focus-labels"
                              : "listing-labels"
                          }
                        >
                          Unit # or Name
                        </IonLabel>
                        <IonInput
                          type='text'
                          name="unit"
                          value={values.unit}
                          placeholder="Enter Unit # or Name"
                          onIonFocus={() => setFocus("unit")}
                          className={
                            focus === "unit"
                              ? "listing-focus-fields"
                              : "listing-fields"
                          }
                          onIonChange={(e) => handleChange(e)}
                        ></IonInput>
                        {errors.unit ? (
                          <IonText className="listing-errors">
                            {errors.unit}
                          </IonText>
                        ) : (
                          <div className="listing-label-margin" />
                        )}
                      </IonGrid>
                      <IonGrid>
                        <IonLabel
                          className={
                            focus === "accessCode"
                              ? "listing-focus-labels"
                              : "listing-labels"
                          }
                        >
                          Access Code
                        </IonLabel>

                        <IonInput
                          name="accessCode"
                          type='number'
                          value={values.accessCode}
                          placeholder="Enter Access Code"
                          onIonFocus={() => setFocus("accessCode")}
                          className={
                            `padding-input ${
                            focus === "accessCode"
                              ? "listing-focus-fields"
                              : "listing-fields"}`
                          }
                          onIonChange={(e) => handleChange(e)}
                        ></IonInput>
                        {errors.accessCode ? (
                          <IonText className="listing-errors">
                            {errors.accessCode}
                          </IonText>
                        ) : (
                          <div className="listing-label-margin" />
                        )}
                      </IonGrid>
                      <IonGrid>

                        <IonChip className='schedule-chip overwrite-chip' onClick={() => setShowCheckInTime(!showCheckInTime)}>
                          <IonLabel>{checkIn ? checkIn : 'select Check In Time'}</IonLabel>
                          <IonIcon icon={chevronDownOutline} />

                        </IonChip>

                        {
                          showCheckInTime && (
                            <Timekeeper
                              time='11:30PM'
                              onDoneClick={e => {
                                setCheckIn(e.formatted12)
                                setShowCheckInTime(false)

                              }}
                            />
                          )
                        }



                      </IonGrid>
                      <IonGrid>

                        <IonChip


                          className='schedule-chip overwrite-chip' onClick={() => setShowCheckOutTime(!showCheckInTime)}>
                          <IonLabel>{checkOut ? checkOut : 'select Check Out Time'}</IonLabel>
                          <IonIcon icon={chevronDownOutline} />

                        </IonChip>

                        {
                          showCheckOutTime && (
                            <Timekeeper
                              time='11:30PM'
                              onDoneClick={(e) => {
                                setCheckOut(e.formatted12)
                                setShowCheckOutTime(false)
                              }}
                            // ...
                            />
                          )
                        }


                        {isError && (<h6 className='red'> You have to specified checkin or checkout time</h6>)}



                      </IonGrid>



                      {photo ? (
                        <IonGrid >
                          <IonLabel className="listing-labels d-block">
                            Listing Photo
                            </IonLabel>
                          <div style={{ position: 'relative' }}>
                            <img
                            src={photo}
                            alt="uploaded image"
                            height="200px"
                              style={{ cursor: "pointer", position: 'relative' }}
                            className="mx-3"
                          />

                            <input onChange={(e: any) => setPhoto(URL.createObjectURL(e.target.files[0]))} style={{ cursor: 'pointer' }} className="learn-btn btn-file-upload" type="file" name="filename" />
                          </div>

                          <div style={{ display: "inline-block", position: 'relative', cursor: 'pointer' }}>
                            <IonButton className='edit-image'>Edit Image</IonButton>
                            <input onChange={(e: any) => setPhoto(URL.createObjectURL(e.target.files[0]))} style={{ cursor: 'pointer' }} className="learn-btn btn-file-upload" type="file" name="filename" />

                          </div>
                        </IonGrid>
                      ) : (
                        <IonGrid>
                          <IonLabel className="listing-labels">
                            Listing Photo
                            </IonLabel>
                            <div className="mx-2 px-2 my-2 py-1 cursor-pointer rounded" style={{ border: 'dotted', position: "relative", cursor: 'pointer' }}>
                              <input onChange={(e: any) => setPhoto(URL.createObjectURL(e.target.files[0]))} style={{ cursor: 'pointer' }} className="learn-btn btn-file-upload" type="file" name="filename" />

                            <IonButton
                              className="learn-btn"
                              fill="outline"

                            >
                              Choose file / Take Photo
                              </IonButton>
                          </div>
                        </IonGrid>
                      )}
                      <IonGrid>
                        <IonLabel
                          className={
                            focus === "bedroomCount"
                              ? "listing-focus-labels"
                              : "listing-labels"
                          }
                        >
                          Number of Bedrooms
                        </IonLabel>
                        <IonInput
                          name="bedroomCount"
                          type='number'
                          step='any'

                          value={values.bedroomCount}
                          placeholder="Enter Number of Bedrooms"
                          onIonChange={(e) => {
                            handleChange(e);
                            console.log(e)

                          }
                          }
                          onIonFocus={() => setFocus("bedroomCount")}
                          className={
                            `padding-input ${focus === "accessCode"
                              ? "listing-focus-fields"
                              : "listing-fields"}`
                          }
                        ></IonInput>
                        {errors.bedroomCount ? (
                          <IonText className="listing-errors">
                            {errors.bedroomCount}
                          </IonText>
                        ) : (
                          <div className="listing-label-margin" />
                        )}
                      </IonGrid>
                      <IonGrid>
                        <IonLabel
                          className={
                            focus === "bathroomCount"
                              ? "listing-focus-labels"
                              : "listing-labels"
                          }
                        >
                          Number of Bathrooms
                        </IonLabel>
                        <IonInput
                          name="bathroomCount"
                          type='number'
                          step='any'
                          value={values.bathroomCount}
                          placeholder="Enter Number of Bathrooms"
                          onIonChange={(e) => handleChange(e)}
                          onIonFocus={() => setFocus("bathroomCount")}
                          className={
                            `padding-input ${focus === "accessCode"
                              ? "listing-focus-fields"
                              : "listing-fields"}`
                          }
                        ></IonInput>
                        {errors.bathroomCount ? (
                          <IonText className="listing-errors">
                            {errors.bathroomCount}
                          </IonText>
                        ) : (
                          <div className="listing-label-margin" />
                        )}
                      </IonGrid>
                      <IonGrid>
                        <IonRow>
{/*                           <IonCol size="12" sizeMd='4'>
                            <IonLabel
                              className={
                                focus === "unitOfMeasure"
                                  ? "listing-focus-labels"
                                  : "listing-labels"
                              }>Unit of Measurement</IonLabel>
                            <IonSelect value={values.unitOfMeasure} placeholder="Select One"
                              name='unitOfMeasure'
                              onIonChange={(e) => handleChange(e)}
                              onIonFocus={() => setFocus("unitOfMeasure")}
                              className={
                                focus === "unitOfMeasure"
                                  ? "listing-focus-fields pr-2"
                                  : "listing-fields pr-2"
                              }>
                              <IonSelectOption value="Square Feet">ft² (square feet)</IonSelectOption>
                              <IonSelectOption value="Square Meters">m² (square meters)</IonSelectOption>
                            </IonSelect>
                          </IonCol> */}
                          <IonCol>
                            <IonLabel
                              className={
                                focus === "squareMeasurement"
                                  ? "listing-focus-labels"
                                  : "listing-labels"
                              }
                            >

                            </IonLabel>
                            <IonInput

                              type='number'
                              name="squareMeasurement"
                              value={values.squareMeasurement}
                              placeholder="Enter Measurement"
                              onIonChange={(e) => {
                                handleChange(e)
                              }}
                              onIonFocus={() => setFocus("squareMeasurement")}
                              className={
                                `padding-input ${focus === "accessCode"
                                  ? "listing-focus-fields"
                                  : "listing-fields"}`
                              }
                            ></IonInput>
                            {errors.squareMeasurement ? (
                              <IonText className="listing-errors">
                                {errors.squareMeasurement}
                              </IonText>
                            ) : (
                              <div className="listing-label-margin" />
                            )}
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                      <IonGrid>
                        <IonLabel
                          className={
                            focus === "description"
                              ? "listing-focus-labels"
                              : "listing-labels"
                          }
                        >
                          Property Description
                        </IonLabel>
                        <IonInput
                          name="description"
                          value={values.description}
                          placeholder="Enter Description"
                          onIonChange={(e) => handleChange(e)}
                          onIonFocus={() => setFocus("description")}
                          className={
                            `padding-input ${focus === "accessCode"
                              ? "listing-focus-fields"
                              : "listing-fields"}`
                          }
                        ></IonInput>
                        {errors.description ? (
                          <IonText className="listing-errors">
                            {errors.description}
                          </IonText>
                        ) : (
                          <div className="listing-label-margin" />
                        )}
                      </IonGrid>
                      <IonGrid>
                        <IonCol></IonCol>
                      </IonGrid>
                      <IonButton type="submit">Submit</IonButton>
                    </form>
                  );
                }}
              </Formik>
            </IonCol>
          </IonRow>
      </div>
    </span>
    ) :
      <CustomLoading loading={true} />

  );
};

export default AddEditListing;
