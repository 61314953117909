import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
} from "@ionic/react";

import React, {useContext, useEffect} from "react";
import { useLocation } from "react-router-dom";
import {
  barChartOutline,
  barChartSharp,
  calendarOutline,
  calendarSharp,
  logInOutline,
  logInSharp,
  logOutOutline,
  logOutSharp,
  peopleOutline,
  peopleSharp,
  homeOutline,
  homeSharp,
  listOutline,
  listSharp,
} from "ionicons/icons";
import "./Menu.css";
import firebase from "../lib/firebase";
import { Accordion, Card, Button } from "react-bootstrap";
import {authContext} from "../context/auth-context/auth-context";

interface AppPage {
  url?: string;
  iosIcon?: string;
  mdIcon?: string;
  title?: string;
  taskPage?: any;
}

const appPages: AppPage[] = [
  {
    taskPage: [
      {
        title: "All task ",
        url: "/dashboard/AllTasks",
        iosIcon: barChartOutline,
        mdIcon: barChartSharp,
      },
      {
        title: "Available task",
        url: "/dashboard/AvailableTask",
        iosIcon: barChartOutline,
        mdIcon: barChartSharp,
      },
      {
        title: "My task",
        url: "/dashboard/Mytask",
        iosIcon: barChartOutline,
        mdIcon: barChartSharp,
      },
    ],
  },

  // Todo
  // {
  //   title: "Dashboard",
  //   url: "/dashboard",
  //   iosIcon: barChartOutline,
  //   mdIcon: barChartSharp,
  // },

  // Todo
  // {
  //   title: "Calendar",
  //   url: "/dashboard/calendar",
  //   iosIcon: calendarOutline,
  //   mdIcon: calendarSharp,
  // },
  {
    title: "Schedule",
    url: "/dashboard/schedule",
    iosIcon: calendarOutline,
    mdIcon: calendarSharp,
  },
  // Todo
  // {
  //   title: "Tasks",
  //   url: "/dashboard/tasks",
  //   iosIcon: bookOutline,
  //   mdIcon: bookSharp,
  // },
  // {
  //   title: "Reservations",
  //   url: "/dashboard/reservations",
  //   iosIcon: newspaperOutline,
  //   mdIcon: newspaperOutline,
  // },
  // {
  //   title: "Activity",
  //   url: "/dashboard/activity",
  //   iosIcon: newspaperOutline,
  //   mdIcon: newspaperOutline,
  // },
  // {
  //   title: "Settings",
  //   url: "/dashboard/settings",
  //   iosIcon: settingsOutline,
  //   mdIcon: settingsSharp,
  // },

];



async function logout() {
  await firebase.auth().signOut();
}

function getAuthMenuOptions() {
  if (firebase.auth().currentUser) {
    return (
      <IonItem lines="none" button onClick={() => firebase.auth().signOut()}>
        <IonIcon slot="start" ios={logOutOutline} md={logOutSharp} />
        <IonLabel>Logout</IonLabel>
      </IonItem>
    );
  }
  return (
    <IonItem lines="none">
      <IonIcon slot="start" ios={logInOutline} md={logInSharp} />
      <IonLabel>Sign in</IonLabel>
    </IonItem>
  );
}

const Menu: React.FC = () => {
  const location = useLocation();
  const taskArr = appPages.slice(0,1)[0].taskPage;
  const { currentUser } = useContext(authContext);

  useEffect(() => {
    if (currentUser.accountType !== 'Cleaner') {
      appPages.push({
        title: "Listings",
        url: "/dashboard/listings",
        iosIcon: homeOutline,
        mdIcon: homeSharp,
      });
    }

    if (currentUser.accountType !== 'Cleaner' && currentUser.accountType !== 'Owner') {
      appPages.push({
        title: "Team",
        url: "/dashboard/teams",
        iosIcon: peopleOutline,
        mdIcon: peopleSharp,
      });
      appPages.push({
        title: "Task Lists",
        url: "/dashboard/lists",
        iosIcon: listOutline,
        mdIcon: listSharp,
      });
    }
  }, [currentUser]);

  return (
    <IonMenu contentId="main" type="overlay" className="side-menu">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader>Clean Stays</IonListHeader>
          <IonNote>Cleaning management and scheduling</IonNote>
          <Accordion style={{ marginBottom: "10px" }}>
            <Card>

              {taskArr.map((el: any, index: any) => {
                return (
                  <Accordion.Collapse
                    eventKey="0"
                    key={"menu-accordion-collapse-" + index}
                  >
                    <IonMenuToggle autoHide={false}>
                      <IonItem
                        className={
                          location.pathname === el.url ? "selected" : ""
                        }
                        routerLink={el.url}
                        routerDirection="none"
                        lines="none"
                        detail={false}
                      >
                        <IonIcon slot="start" ios={el.iosIcon} md={el.mdIcon} />
                        <IonLabel>{el.title}</IonLabel>
                      </IonItem>
                    </IonMenuToggle>
                  </Accordion.Collapse>
                );
              })}
            </Card>
          </Accordion>
          {appPages.slice(1).map((appPage, index) => {
            return (
              <IonMenuToggle key={"menu-toggle-" + index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon
                    slot="start"
                    ios={appPage.iosIcon}
                    md={appPage.mdIcon}
                  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

        <IonList id="labels-list">{getAuthMenuOptions()}</IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
