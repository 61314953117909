import React, { useContext, useState } from "react";
import "./Tasks.css";
import {
  IonButton,
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import { add, arrowBackOutline, arrowForwardOutline } from "ionicons/icons";
import GoogleMapReact from "google-map-react";
import TaskAccordion from "../../components/task-accordion";
import CardBox from "../../components/cardBox";
import {
  taskDetailsContext,
  Assignee,
} from "../../context/task-details/task-details";
import { DashBoardContext } from "../../context/dashboard/dashboard.context";
import { useHistory } from "react-router";
const data = [
  { date: "Friday December,30" },
  { date: "Friday December,1" },
  { date: "Friday December,4" },
  { date: "Friday December,20" },
];

const Tasks: React.FC = () => {
  const [model, setModel] = useState<boolean>(false);
  const [gender, setGender] = useState<string>();
  const [assigned, setAssigned] = useState<Assignee[]>([]);
  const [showCard, setShowCard] = useState<boolean>(false);
  const { taskDetails } = useContext(DashBoardContext);
  const history = useHistory();

  const handleShowCard = (val: boolean) => {
    setShowCard(val);
  };
  const handleAddOrRemoveAssignee = (obj, selected: boolean, idx) => {
    if (!selected) {
      setAssigned([...assigned, obj]);
    } else {
      const array = [...assigned];
      // @ts-ignore
      const res = array.filter((el) => el.id !== obj.id);
      setAssigned(res);
    }
  };

  return (
    <taskDetailsContext.Provider
      value={{
        showCard,
        setShowCard: (val: boolean) => handleShowCard(val),
        assignee: assigned,
        handleAssignee: handleAddOrRemoveAssignee,
      }}
    >
      <div>
        <div>
          <IonGrid>
            <IonRow>
              <IonCol>
                <div>
                  <h1>Schedule</h1>
                  <h2>Lists</h2>
                  <h3>
                    Lists allow you to setup automatically
                    recurring sets of tasks and check list steps for any type of
                    you create.
                  </h3>
                  <IonRow>
                    <IonCol size={showCard ? "4" : "3"}>
                      <IonCard
                        className="template-card"
                        onClick={() =>
                          history.push("/dashboard/template/create")
                        }
                      >
                        <div className="template-container">
                          <IonIcon className="template-icon" icon={add} />
                          <IonLabel className="template-label">
                            Create Task List
                          </IonLabel>
                        </div>
                      </IonCard>
                    </IonCol>
                  </IonRow>
                </div>
                <IonRow>
                  <IonCol color="primary" size="12" /* sizeMd="8" */>
                    <IonRow>
                      <IonCol>
                        <IonRow>
                          <IonCol size="7">
                            <IonItem>
                              <IonSelect
                                value={gender}
                                placeholder="Select Date"
                                onIonChange={(e) => setGender(e.detail.value)}
                              >
                                {data.map((el, index) => (
                                  <IonSelectOption
                                    value={el.date}
                                    key={"select-date-" + index}
                                  >
                                    {el.date}
                                  </IonSelectOption>
                                ))}
                              </IonSelect>
                            </IonItem>
                          </IonCol>
                          <IonCol size="5">
                            <IonRow>
                              <IonCol>
                                <IonButton fill="outline">
                                  <IonIcon icon={arrowForwardOutline}></IonIcon>
                                </IonButton>
                              </IonCol>
                              <IonCol>
                                <IonButton fill="outline">
                                  <IonIcon icon={arrowBackOutline}></IonIcon>
                                </IonButton>
                              </IonCol>
                            </IonRow>
                          </IonCol>

                          <IonCol size="4">
                            <IonItem>
                              <IonSelect
                                value={gender}
                                placeholder="Date"
                                onIonChange={(e) => setGender(e.detail.value)}
                              >
                                <IonSelectOption value="date">
                                  date 1
                                </IonSelectOption>
                                <IonSelectOption value="date">
                                  date 2
                                </IonSelectOption>
                                <IonSelectOption value="date">
                                  date 3{" "}
                                </IonSelectOption>
                                <IonSelectOption value="date">
                                  date 4
                                </IonSelectOption>
                                <IonSelectOption value="date">
                                  date 5
                                </IonSelectOption>
                              </IonSelect>
                            </IonItem>
                          </IonCol>
                          <IonCol size="6">
                            <IonItem>
                              <IonSelect
                                value={gender}
                                placeholder="Filter"
                                onIonChange={(e) => setGender(e.detail.value)}
                              >
                                <IonSelectOption value="filter">
                                  Filter
                                </IonSelectOption>
                                <IonSelectOption value="filter">
                                  Filter
                                </IonSelectOption>
                                <IonSelectOption value="filter">
                                  Filter{" "}
                                </IonSelectOption>
                                <IonSelectOption value="filter">
                                  Filter
                                </IonSelectOption>
                                <IonSelectOption value="filter">
                                  Filter
                                </IonSelectOption>
                              </IonSelect>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                      </IonCol>
                      <IonCol size="4">
                        <IonRow>
                          <IonCol size="12">
                            <IonButton
                              className="btn-create"
                              fill="outline"
                              onClick={() => history.push("/dashboard/task/add")}
                            >
                              Add Task
                            </IonButton>
                          </IonCol>
                          <IonCol size="12">
                            <IonButton
                              className="btn-edit"
                              fill="outline"
                              onClick={() => setModel(true)}
                            >
                              Edit Task
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      </IonCol>
                      {/*   */}
                    </IonRow>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <IonList>
                      <TaskAccordion />
                    </IonList>
                  </IonCol>
                </IonRow>
              </IonCol>

              <IonCol className="ion-no-padding">
                <div style={{ height: "100vh", width: "100%" }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyAWS5Su8cOhm4xUkpaWKJiadC9CirSPWNM",
                    }}
                    defaultCenter={{
                      lat: 59.95,
                      lng: 30.33,
                    }}
                    defaultZoom={10}
                  ></GoogleMapReact>
                </div>
              </IonCol>
              {showCard ? (
                <IonCol className="ion-no-padding">
                  <CardBox taskDetails={taskDetails} />
                </IonCol>
              ) : (
                ""
              )}
            </IonRow>
          </IonGrid>
        </div>
      </div>
    </taskDetailsContext.Provider>
  );
};

export default Tasks;
