import "./TaskList.css";
import ConfirmationModal from "./../components/confirmationModal";
import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonReorder,
  IonReorderGroup,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/react";
import { ItemReorderEventDetail } from "@ionic/core";
import React, { useContext, useEffect, useState } from "react";
import {
  addOutline,
  chevronBackOutline,
  saveSharp,
  settingsOutline,
  trashOutline,
} from "ionicons/icons";
import CustomTable from "./customTable";
import {
  getTaskLists,
  cbAfterAuthLoaded,
  createTaskList,
  getTaskListById,
  editTaskList,
  updateProperty,
} from "../lib/firebase";
import { Accordion, Button, Card } from "react-bootstrap";
import { useHistory, useParams } from "react-router";

import { firestore } from "./../lib/firebase";
import PropertyModal from "./property.component";
import { authContext } from "../context/auth-context/auth-context";

export interface TaskListProps {
  id?: string;
  action?: string;
  property?: any | object | null;
  isViewTaskList?: boolean;
}

function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
  // The `from` and `to` properties contain the index of the item
  // when the drag started and ended, respectively
  console.log("Dragged from index", event.detail.from, "to", event.detail.to);

  // Finish the reorder and position the item in the DOM based on
  // where the gesture ended. This method can also be called directly
  // by the reorder group
  event.detail.complete();
}

function getEmptyTaskList() {
  return {
    title: "placeholder task list title",
    sections: [
      {
        index: 0,
        title: "First Kitchen",
        description: "First kitchen on downstairs",
        tasks: [
          {
            index: 0,
            title: "Wipe Counters",
            requireImage: false,
            requireText: false,
            completed: false,
            input: "",
            image: "",
          },
        ],
      },
    ],
  };
}

const TaskLists: React.FC<TaskListProps> = ({
  id,
  action,
  property,
  isViewTaskList,
}) => {
  const [taskLists, setTaskLists] = useState<any>([]);
  const [addEditList, setAddEditList] = useState(false);
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const [selectedTaskList, setSelectedTaskList] = useState<any>("");
  const [showTaskListDelete, setShowTaskListDelete] = useState<any>("");
  const [taskListIndex, setTaskListIndex] = useState<any>("");
  const [currentSelectedEdit, setCurrentSelectedEdit] = useState<any>();
  const [selectedChecklist, setSelectedChecklist] = useState()
  const history = useHistory();

  const { teamData } = useContext(authContext)

  useEffect(() => {
    taskLists.forEach((taskList) => {
      if (
        property &&
        property.taskLists &&
        property.taskLists.includes(taskList.id)
      ) {
        setSelectedChecklist(property.taskLists[0]);
      }
    });
  }, [taskLists, property]);

  useEffect(() => {
    if (action === "edit") {
      setAddEditList(true);
      const getData = async () => {
        const data: any = await getTaskListById(id);
        const val = data.sections.sort((a, b) => a.index - b.index);
        setCurrentTaskList({
          ...data,
          sections: val,
        });
      };
      getData();
    }
  }, [action]);

  useEffect(() => {
    if (currentSelectedEdit) {
      const getData = async () => {
        const data: any = await getTaskListById(currentSelectedEdit);
        const val = data.sections.sort((a, b) => a.index - b.index);
        setCurrentTaskList({
          ...data,
          sections: val,
        });
      };
      getData();
    }
  }, [currentSelectedEdit]);

  const deleteTaskList = async (id) => {
    const docRef = firestore.doc(`taskLists/${id}`);

    await docRef.delete();
  };

  const handleSubmit = () => {
    deleteSection(taskListIndex);
  };


  //Custom Table
  const columns = [
    {
      name: "Task List",
      selector: "title",
      sortable: true,
      maxWidth: "400px",
      cell: (row) => (
        <IonCheckbox
          checked={row.id === selectedTaskList}
          onIonChange={(e) => {
            if (property) {
              if (e.detail.checked) {
                updateProperty(
                  {
                    ...property,
                    taskLists: [row.id],
                  },
                  property.id
                );
              } else {
                updateProperty(
                  {
                    ...property,
                    taskLists: [],
                  },
                  property.id
                );
              }
            }

            setSelectedTaskList(row.id);
          }}
        />
      ),
    },
    {
      name: "Task List",
      selector: "title",
      sortable: true,
      minWidth: "400px",
      cell: (row) => (
        <IonRow
          className={"ion-align-items-center ion-justify-content-between"}
          style={{ width: "100%" }}
        >
          {/*                     <IonCol
                        size="3"
                        className="listing-bg-img"
                        data-tag="allowRowEvents"
                    ></IonCol> */}
          <IonCol size="9" className="pl-3">
            <IonText>
              <h6 data-tag="allowRowEvents">{row.title}</h6>
            </IonText>
          </IonCol>
        </IonRow>
      ),
    },
    {
      name: <IonIcon icon={settingsOutline} className="setting-icon" />,
      minWidth: "175px",
      center: true,
      cell: (row) => (
        <IonRow className="ion-align-items-center ion-justify-content-between">
          <IonButton
            fill="clear"
            style={{ textTransform: "capitalize" }}
            onClick={() => {
              if (isViewTaskList) {
                setAddEditList(true);
                setCurrentSelectedEdit(row.id);
                return;
              }
              history.push(`/dashboard/lists/edit/${row.id}`);
            }}
          >
            Edit
          </IonButton>
          <IonButton
            fill="clear"
            style={{ textTransform: "capitalize" }}
            onClick={() => {
              deleteTaskList(row.id);
            }}
          >
            Delete
          </IonButton>

          <IonButton
            fill="clear"
            style={{ textTransform: "capitalize" }}
            onClick={() => {
              setShowPropertyModal(true);
            }}
          >
            assigned properties
          </IonButton>
        </IonRow>
      ),
    },
  ];

  const deleteSection = async (sectionIdx) => {
    const docRef = firestore.doc(`taskLists/${id}`);
    const data = currentTaskList.sections.filter(
      (el, idx) => idx !== sectionIdx
    );
    await docRef.update({
      sections: data,
    });
  };

  const deleteTask = async (sectionIdx, taskIdx) => {
    const docRef = firestore.doc(`taskLists/${id}`);

    // 1) Make a copy of  the schedule array
    const newArray = currentTaskList;

    // 2) Have to Delete The Task from the schedule array
    const data = currentTaskList.sections[sectionIdx].tasks.filter(
      (el, idx) => idx !== taskIdx
    );
    newArray.sections[sectionIdx].tasks = data;

    //3) update the firebase

    docRef.update(newArray);
  };

  const [currentTaskList, setCurrentTaskList] = useState(getEmptyTaskList());

  function addNewSection() {
    setCurrentTaskList({
      ...currentTaskList,
      sections: [
        ...currentTaskList.sections,
        {
          index: currentTaskList.sections.length,
          title: "",
          description: "",
          tasks: [
            {
              index: 0,
              title: "",
              requireImage: true,
              requireText: true,
              completed: false,
              input: "",
              image: "",
            },
          ],
        },
      ],
    });
  }

  function addNewTask(sectionIndex) {
    const sections = currentTaskList.sections;
    sections[sectionIndex].tasks.push({
      index: 0,
      title: "",
      requireImage: true,
      requireText: true,
      completed: false,
      input: "",
      image: "",
    });
    setCurrentTaskList({
      ...currentTaskList,
      sections: [...sections],
    });
  }

  function updateSectionOrTask(sectionIndex, taskIndex, key, value) {
    const sections = currentTaskList.sections;
    if (taskIndex === null) {
      sections[sectionIndex][key] = value;
    } else {
      sections[sectionIndex].tasks[taskIndex][key] = value;
    }
    setCurrentTaskList({
      ...currentTaskList,
      sections: [...sections],
    });
  }

  useEffect(() => {
    if(!teamData.id) return;
    cbAfterAuthLoaded(async (user) => {
      await getTaskLists(setTaskLists,teamData.id);
    });
  }, [teamData]);

  const updateIndexReorder = (needToBeUpdate, indexFrom, indexTo) => {
    //needToBeUpdate is the state that we want to update
    let data = [...needToBeUpdate];
    data.forEach((data, idx) => {
      if (data.index === indexFrom) {
        data.index = indexTo;
      } else if (data.index === indexTo) {
        data.index = indexFrom;
      } else {
        data.index = data.index;
      }
    });

    return data;
  };

  return !addEditList ? (
    <span className="container">
      <PropertyModal
        propertyModal={showPropertyModal}
        selectedTasklist={selectedTaskList}
        setPropertyModal={(val: boolean) => setShowPropertyModal(val)}
      />

      <h1 className="sm-text">Task Lists</h1>
      <IonGrid>
        <IonRow>
          <IonCol
            size="12"
            offset="0"
            sizeMd="8"
            offsetMd="2"
            className="mlr-auto"
          >
            <IonButton
              onClick={() => {
                setAddEditList(true);
                setCurrentTaskList(getEmptyTaskList());
              }}
            >
              <IonIcon slot="start" icon={addOutline} />
              Add Task List
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>

      <IonRow>
        <IonCol size="12" sizeMd="8" offsetMd="2" className="mlr-auto">
          <IonGrid>

            {!isViewTaskList ? (
              <CustomTable data={taskLists} columns={columns} />
            ): (
                <IonRow>
                <IonCol>
                    {
                        selectedChecklist && (
                    <h4>selected task</h4>

                        )
                    }
                  <IonSelect
                    className="Task-list-dropdown"
                    value={selectedChecklist}
                    placeholder="Select A task"
                    onIonChange={ async (e) => {  

                      if(property) {
                                     setSelectedChecklist(e.detail.value);
                        updateProperty(
                            {
                              ...property,
                              taskLists: [e.detail.value],
                            },
                            property.id
                          );
                      }
           
                    }}
                  >
                    {taskLists.map((el) => (
                      <IonSelectOption value={el.id}>{el.title}</IonSelectOption>
                    ))}
                  </IonSelect>
                </IonCol>
              </IonRow>
  
            )}
          </IonGrid>
        </IonCol>
      </IonRow>
    </span>
  ) : (
    <>
      <IonRow>
        <IonCol size="12" sizeMd="8" offsetMd="2" className="mlr-auto">
          <span className="container">
            <h1 className="text-center">Add Task List</h1>
            <IonButton
              onClick={() => {
                setAddEditList(false);
                if (action === "edit") {
                  history.goBack();
                }
              }}
              fill="clear"
            >
              <IonIcon slot="start" icon={chevronBackOutline} />
              Cancel Changes
            </IonButton>
            <IonButton
              onClick={async () => {
                try {
                  if (action === "edit") {
                    await editTaskList(currentTaskList, id);
                    setAddEditList(false);
                    history.push("/dashboard/lists");
                  } else if (isViewTaskList) {
                    await editTaskList(
                      currentTaskList,
                      id || currentSelectedEdit
                    );
                    setAddEditList(false);
                  } else if (isViewTaskList === false) {
                    setAddEditList(false);
                    history.push("/dashboard/lists");
                  } else {
                    await createTaskList(currentTaskList,teamData.id);
                    setAddEditList(false);
                  }
                } catch (err) {
                  console.log(err);
                }
              }}
              fill="clear"
              className="float-right"
            >
              <IonIcon slot="start" icon={saveSharp} />
              Save Task List
            </IonButton>

            <IonItem className="my-4">
              <IonLabel position="stacked">Task List Title</IonLabel>
              <IonInput
                value={currentTaskList.title}
                placeholder="Enter Input"
                onIonChange={(e) =>
                  setCurrentTaskList({
                    ...currentTaskList,
                    title: e.detail.value!,
                  })
                }
              ></IonInput>
            </IonItem>

            <Accordion defaultActiveKey="0">
              <IonReorderGroup
                disabled={false}
                onIonItemReorder={(e) => {
                  const data = updateIndexReorder(
                    currentTaskList.sections,
                    e.detail.from,
                    e.detail.to
                  );
                  setCurrentTaskList({
                    ...currentTaskList,
                    sections: data,
                  });
                  doReorder(e);
                }}
              >
                {currentTaskList &&
                  currentTaskList.sections &&
                  currentTaskList.sections.map((section, sectionIndex) => (
                    <Card key={"section-" + sectionIndex} className="mb-1">
                      <div className="position-relative">
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey={"section-" + sectionIndex}
                          className="p-0 cus-width"
                        >
                          <Card.Header className="custom-card-header">
                            <IonInput
                              className="input-task"
                              value={section.title}
                              placeholder="Enter Section Title"
                              debounce={300}
                              onIonChange={(e) =>
                                updateSectionOrTask(
                                  sectionIndex,
                                  null,
                                  "title",
                                  e.detail.value!
                                )
                              }
                            >
                              <IonReorder slot="end" />
                            </IonInput>
                          </Card.Header>
                        </Accordion.Toggle>
                        {action === "edit" && (
                          <IonButton
                            fill="clear"
                            className="taskListDelete"
                            slot="end"
                            onClick={() => {
                              setShowTaskListDelete(true);
                              setTaskListIndex(sectionIndex);
                            }}
                          >
                            <IonIcon icon={trashOutline} />
                          </IonButton>
                        )}
                      </div>
                      <Accordion.Collapse eventKey={"section-" + sectionIndex}>
                        <Card.Body>
                          <IonReorderGroup
                            disabled={false}
                            onIonItemReorder={(e) => {
                              e.stopPropagation();
                              doReorder(e);
                              let currentTaskListReorder = currentTaskList;
                              const data = updateIndexReorder(
                                currentTaskList.sections[sectionIndex].tasks,
                                e.detail.from,
                                e.detail.to
                              );
                              const sortedOrder = data.sort(
                                (a, b) => a.index - b.index
                              );
                              currentTaskListReorder.sections[
                                sectionIndex
                              ].tasks = sortedOrder;
                            }}
                          >
                            {section.tasks &&
                              section.tasks.map((task, taskIndex) => (
                                <IonItem key={"section-task-" + taskIndex}>
                                  <IonInput
                                    value={task.title}
                                    placeholder="Enter Task Title"
                                    debounce={300}
                                    onIonChange={(e) =>
                                      updateSectionOrTask(
                                        sectionIndex,
                                        taskIndex,
                                        "title",
                                        e.detail.value!
                                      )
                                    }
                                  >
                                    <IonReorder slot="end" className="mr-4" />
                                  </IonInput>
                                  <IonItem lines="none">
                                    <IonLabel>Require Image</IonLabel>
                                    <IonCheckbox
                                      onIonChange={(e) => {
                                        const data = { ...currentTaskList };
                                        data.sections[sectionIndex].tasks[
                                          taskIndex
                                        ].requireImage = e.detail.checked;
                                        setCurrentTaskList(data);
                                      }}
                                      slot="end"
                                      checked={task.requireImage}
                                    />
                                  </IonItem>
                                  <IonItem lines="none" className="mr-4">
                                    <IonLabel>Require Text</IonLabel>
                                    <IonCheckbox
                                      onIonChange={(e) => {
                                        const data = { ...currentTaskList };
                                        data.sections[sectionIndex].tasks[
                                          taskIndex
                                        ].requireText = e.detail.checked;
                                        setCurrentTaskList(data);
                                      }}
                                      slot="end"
                                      checked={task.requireText}
                                    />
                                  </IonItem>
                                  {action === "edit" && (
                                    <IonButton
                                      slot="end"
                                      onClick={() =>
                                        deleteTask(sectionIndex, taskIndex)
                                      }
                                    >
                                      Delete
                                    </IonButton>
                                  )}
                                </IonItem>
                              ))}
                            <IonButton
                              onClick={() => addNewTask(sectionIndex)}
                              fill="clear"
                            >
                              <IonIcon slot="start" icon={addOutline} />
                              Add Task
                            </IonButton>
                          </IonReorderGroup>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))}
              </IonReorderGroup>
            </Accordion>

            <IonButton onClick={() => addNewSection()} fill="clear">
              <IonIcon slot="start" icon={addOutline} />
              Add Section
            </IonButton>
          </span>
        </IonCol>
      </IonRow>
      <IonModal
        cssClass="confirmation-modal"
        swipeToClose={true}
        isOpen={showTaskListDelete}
      >
        <ConfirmationModal
          text="Are you sure to Delete this Section"
          heading="Delete Task List Section"
          handleCloseModal={() => setShowTaskListDelete(!showTaskListDelete)}
          handleSubmit={handleSubmit}
        />
      </IonModal>
    </>
  );
};

export default TaskLists;
