import {
  IonAlert,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonLoading,
  IonRadio,
  IonRadioGroup,
  IonRow,
} from "@ionic/react";
import React, { useState, useContext } from "react";

import "./template.css";

import SlideModel from "../../components/SlideModel";

import { addCircleOutline } from "ionicons/icons";
import {
  templateContext,
  Step,
} from "../../context/template-context/template-context";
import Select from "react-select";
import { createTemplate } from "../../lib/firebase";
import { authContext } from "../../context/auth-context/auth-context";

const options = [
  { value: "checkInTime", label: "At Check In Time" },
  { value: "checkOutTime", label: "At check Out time" },
  { value: "noRules", label: "No Rules" },
];

const Step1 = ({ template, handleChange }) => {
  return (
    <>
      <IonRow>
        <IonCol size="12">
          <IonItem>
            <IonLabel position="stacked">
              What should the Template be called
            </IonLabel>
            <IonInput
              onIonChange={handleChange}
              name="templateName"
              value={template.templateName}
            ></IonInput>
          </IonItem>
        </IonCol>
        <IonCol size="12">
          <IonItem>
            <IonLabel position="stacked">
              How would you discribe the Template
            </IonLabel>
            <IonInput
              onIonChange={handleChange}
              name="templateDescription"
              value={template.templateDescription}
            ></IonInput>
          </IonItem>
        </IonCol>
        <IonCol className="mr-1">
          <IonRadioGroup
            value={template.templateType}
            onIonChange={handleChange}
            name="templateType"
          >
            <IonListHeader>
              <IonLabel>Which task is the template Link to</IonLabel>
            </IonListHeader>

            <IonItem>
              <IonLabel>Cleaning</IonLabel>
              <IonRadio slot="start" value="Cleaning" />
            </IonItem>

            <IonItem>
              <IonLabel>Maintainance</IonLabel>
              <IonRadio slot="start" value="Maintainance" />
            </IonItem>

            <IonItem>
              <IonLabel>Photoshot</IonLabel>
              <IonRadio slot="start" value="Photoshot" />
            </IonItem>
            <IonItem>
              <IonLabel>Welcoming</IonLabel>
              <IonRadio slot="start" value="Welcoming" />
            </IonItem>
          </IonRadioGroup>
        </IonCol>
      </IonRow>
    </>
  );
};
const Step2: React.FC<{ handleState: () => void }> = ({ handleState }) => {
  const { handleButtonShow, setIsButtonDisable, steps } = useContext(
    templateContext
  );
  const [isTrue, setIsTrue] = useState<boolean>(false);
  const [showSteps, setShowSteps] = useState(false);
  const handleIsTrue = (val: boolean) => setIsTrue(val);
  return (
    <>
      <h1 className="heading-text">Set The Steps</h1>
      <h3 className="heading-text">
        A checklist of steps will help ensure the task is completed correctly
      </h3>

      <SlideModel
        isTrue={isTrue}
        handleTrue={handleIsTrue}
        clearState={handleState}
        handleStepShow={() => setShowSteps(true)}
      />

      <IonList>
        {showSteps &&
          steps.map((el) => {
            return (
              <IonItem lines="none">
                <div>
                  <h1>{el.label}</h1>
                </div>
              </IonItem>
            );
          })}
      </IonList>

      <IonList
        slot="icon-only"
        className="ion-align-self-center"
        style={{ color: "#399EA2" }}
      >
        {" "}
        <IonIcon slot="icon-only" icon={addCircleOutline} />
        <IonLabel
          slot="center"
          onClick={() => {
            handleButtonShow(false);
            handleIsTrue(true);
            setShowSteps(false);
          }}
        >
          {" "}
          add Your first Step
        </IonLabel>
      </IonList>
    </>
  );
};

const Step3 = () => {
  const { setIsSubmitReady } = useContext(templateContext);
  return (
    <>
      <h1 className="heading-text">Set Scheduling Rules</h1>
      <h3 className="heading-text">
        Choose whether the task created automaticly or manually
      </h3>
      <div style={{ margin: "6px" }}>
        <div className="mr-1">
          <IonRadioGroup>
            <IonListHeader>
              <IonLabel className="label-text">
                The Task will be created
              </IonLabel>
            </IonListHeader>

            <IonItem onClick={() => setIsSubmitReady()}>
              <IonLabel>with every new booking</IonLabel>
              <IonRadio value="cord" />
            </IonItem>

            <IonItem onClick={() => setIsSubmitReady()}>
              <IonLabel>When an owner return to their property</IonLabel>
              <IonRadio value="duesenberg" />
            </IonItem>

            <IonItem onClick={() => setIsSubmitReady()}>
              <IonLabel>The task won't be created automatically</IonLabel>
              <IonRadio value="hudson" />
            </IonItem>
          </IonRadioGroup>
        </div>

        <div style={{ marginTop: "10px" }}>
          <IonLabel>This task will be start in:</IonLabel>
          <Select options={options} />
        </div>
        <div style={{ marginTop: "10px" }}>
          <IonLabel>This task Need to be completed at...</IonLabel>
          <Select options={options} />
        </div>
      </div>
    </>
  );
};

const getAppropritaeComponent = (
  step: number,
  template,
  handleTemplateChange,
  clearState
) => {
  switch (step) {
    case 1:
      return <Step1 template={template} handleChange={handleTemplateChange} />;

    case 2:
      return <Step2 handleState={clearState} />;
    case 3:
      return <Step3 />;
  }
};

const Template: React.FC = () => {
  const {id} = useContext(authContext)
  const [steps, setSteps] = useState<Step[] | []>([]);
  const [isSubmitReady, setIsSubmitReady] = useState<boolean>(false);
  const [buttonShow, setButtonShow] = useState<boolean>(true);
  const [isButtonDisable, setIsButtonDisable] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const [alert, setAlert] = useState<boolean>(false);
  const [template, setTemplate] = useState({
    templateName: "",
    templateDescription: "",
    templateType: "Cleaning",
    listings: ["s"],
  });


  const handleTemplateChange = (e) => {
    const { name, value } = e.target;
    setTemplate({ ...template, [name]: value });
  };

  const handleSetIsButtonDisable = (bool: boolean) => {
    setIsButtonDisable(bool);
  };

  const handleSetSlideButtonDisableDisable = () => {
    setIsSubmitReady(true);
  };

  const handleButtonShow = (val: boolean) => {
    setButtonShow(val);
  };

  const handleStepIncrease = () => {
    const { templateName, templateDescription } = template;
    if (templateName === "" || templateDescription === "") {
      setAlert(true);
    } else {
      if (step < 3) {
        setStep(step + 1);
      }
    }
  };

  const clearState = () => {
    setStep(1);
  };

  const handleSteps = (obj: Step) => {
    setSteps([...steps, obj]);
  };

  return (
    <>
      <IonAlert
        isOpen={alert}
        onDidDismiss={() => setAlert(false)}
        cssClass="my-custom-class"
        header={"Alert"}
        message={"Please Specify valid value"}
        buttons={["ok"]}
      />
      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />

      <templateContext.Provider
        value={{
          isButtonDisable,
          setIsButtonDisable: (val: boolean) => handleSetIsButtonDisable(val),
          isSubmitReady,
          setIsSubmitReady: () => handleSetSlideButtonDisableDisable(),
          buttonShow,
          handleButtonShow,
          steps,
          handleSteps: (val: Step) => handleSteps(val),
        }}
      >
        <IonGrid>
          <IonRow>
            <IonCol>
              <span style={{ fontWeight: "bold" }}>Step {step}</span>
              <div className={`underline underline-${step} `}>&nbsp;</div>

              <div className="container-template">
                {getAppropritaeComponent(
                  step,
                  template,
                  handleTemplateChange,
                  clearState
                )}
                {step > 1 && (
                  <IonButton
                    onClick={() => {
                      setStep(step - 1);
                    }}
                  >
                    {" "}
                    previous
                  </IonButton>
                )}

                {buttonShow && (
                  <IonButton
                    disabled={isButtonDisable}
                    onClick={() => {
                      setIsButtonDisable(true);
                      handleStepIncrease();
                      setButtonShow(false);
                    }}
                  >
                    {" "}
                    Next
                  </IonButton>
                )}

                {isSubmitReady && (
                  <IonButton
                    onClick={ async () => {
                      setShowLoading(true);
                      handleStepIncrease();
                      setIsButtonDisable(true);

                      try {
                      const res =  await createTemplate(id,{ ...template, steps });
                      if (res) {
                        setShowLoading(false);
                      };


                      } catch(err) {
                        console.log(err)
                      }

                    }}
                  >
                    {" "}
                    Submit
                  </IonButton>
                )}
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </templateContext.Provider>
    </>
  );
};

export default Template;
