import React from 'react';
import { IonLoading,  } from '@ionic/react';

interface LoadingProps {
  loading:boolean;
}

export const CustomLoading: React.FC<LoadingProps> = ({loading}) => {

  return (
      <IonLoading
        cssClass='my-custom-class'
        isOpen={loading}
        message={'Please wait...'}
      />
  );
};