import { IonButton, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonTextarea, IonItem, IonLabel, IonList, IonModal, IonRow, IonSelect, IonSelectOption, IonToolbar } from '@ionic/react';
import './cleaning-modal.css'
import { chevronDownOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import { authContext } from '../context/auth-context/auth-context';
import { firestore, getAllProperties, getMyTeam } from '../lib/firebase';
import DatePicker from "react-datepicker";
import ClickOutside from '@leafyjava/react-click-away';
import "react-datepicker/dist/react-datepicker.css";
import TimeKeeper from 'react-timekeeper';


export interface CleaningModalProps {

}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('/');
}

const CleaningModal: React.FC<CleaningModalProps> = () => {
    const [dateError, setDateError] = useState({ isShow: false, message: '' })
    const [selectedProperty, setSelectedProperty] = useState<any>([])
    const { showCleaning, setShowCleaning } = useContext(authContext);
    const [cleaners, setCleaners] = useState<any>([])
    const [showPropertyDropdown, setShowPropertyDropdown] = useState(false);
    const [showCheckInTime, setShowCheckInTime] = useState<boolean>(false)
    const [checkIn, setCheckIn] = useState<string>('')
    const [showCheckOutTime, setShowCheckOutTime] = useState<boolean>(false)
    const [showCleaningDropdown, setShowCLeaningDropdown] = useState<boolean>(false)
    const [selectedCLeaner, setSelectedCleaner] = useState<any>([])
    const [checkOut, setCheckOut] = useState<string>('')
    const [allProperty, setAllProperty] = useState<any>([])
    const [selectedDate, setSelectedDate] = useState<string>('');
    const [cleaningNotes, setCleaningNotes] = useState<string>('');

    const { currentUser, selectedDate: CalendarDate } = useContext(authContext)

    const { teamData } = useContext(authContext)
    useEffect(() => {
        if (teamData && teamData.members) {
            const data = teamData.members.filter(el => el.role.toLowerCase() === 'cleaner');
            setCleaners(data)
        }
    }, [teamData])


    useEffect(() => {
        if (!CalendarDate) return;
        setSelectedDate(CalendarDate)

        return () => {
            setSelectedDate('')
        }
    }, [showCleaning])



    useEffect(() => {

        if (showPropertyDropdown) {
            const getData = async () => {
                const team: any = await getMyTeam(currentUser.email)
                const data: any = await getAllProperties();

                let teamProperty:any = []
                for(let i = 0; i < data.length; i++) {
                  for(let j = 0; j < team[0].properties.length; j++) {
                    if(data[i].id === team[0].properties[j]) {
                     teamProperty.push(data[i])
                    }
                  }
                }
                setAllProperty(teamProperty)
            };
            getData();
        }


    }, [showPropertyDropdown, currentUser])

    const validateDateSelection = async (selectedDate) => {
        if (!selectedProperty[0]) {
            // Show error here telling user to select a property first
            return;
        }
        const data = await firestore.collection(`schedule/${selectedProperty[0].id}/dates`).where('endDate', '==', selectedDate).get()
        const scheduleData = data.docs.map(el => el.data())
        if (data.docs.length) {
            setDateError({ isShow: true, message: 'This Date already Exists' });
            setSelectedDate('');
        }
    }

    const handleSubmit = async (obj) => {
        const ID = new Date(formatDate(selectedDate)).getTime()

        if (!selectedProperty[0]) {
            // Show error here that they must select a property
            return;
        }
        if (!selectedDate) {
            // Show error here that they must select a date for cleaning
            return;
        }
        const assignedTo = cleaners.map(el => el.id)
        const docRef = firestore.doc(`schedule/${selectedProperty[0].id}/dates/${ID}`);

        if (ID) {
            await docRef.set({
                id: ID,
                assignedTo: selectedCLeaner,
                ...obj
            });
            window.location.reload();
        }

    }


    return (
        <IonModal onDidDismiss={() => setShowCleaning(false)}
            cssClass='modal-radius cleaning-modal' isOpen={showCleaning}>
            <IonHeader className='schedule-header'>
                <IonToolbar>
                    <div style={{ display: 'grid', gridTemplateColumns: '10px 1fr' }}>
                        <span onClick={() => setShowCleaning(false)} className='modal-x'>x</span>
                        <h4 className='text-center d-inline-b'>Schedule New Cleaning</h4>
                    </div>

                </IonToolbar>

            </IonHeader>
            <IonContent>
                <IonGrid>

                    <IonRow>
                        <IonCol className='center-col padding-top-schedule' size='12' sizeMd='10'>

                            <IonRow  >

                                <IonCol size='7' >
                                    <div onClick={() => setShowPropertyDropdown(!showPropertyDropdown)} >
                                        <IonLabel className='schedule-label'>Which Property</IonLabel>

                                        <IonChip className='schedule-chip'>
                                            <IonLabel> {selectedProperty.length > 0 ? selectedProperty[0].title : 'Select Property'}</IonLabel>
                                            <IonIcon icon={chevronDownOutline} />
                                        </IonChip>
                                    </div>

                                    {
                                        showPropertyDropdown && (

                                            <ClickOutside onClickOutside={() => setShowPropertyDropdown(false)}>

                                                <div className='select-container'>
                                                    <IonList className='select-list'>

                                                        {
                                                            allProperty.length > 0 ? (
                                                                allProperty.map((el, idx) => (
                                                                    <IonItem key={idx} className='cursor-pointer' onClick={() => {
                                                                        setSelectedProperty([el])
                                                                        setCheckIn(el.checkIn)
                                                                        setCheckOut(el.checkOut)
                                                                        setShowPropertyDropdown(false)

                                                                    }}>
                                                                        <img
                                                                            className='mr-2'
                                                                            width='45px'
                                                                            src="https://res.cloudinary.com/dltd4gs4a/image/upload/v1613535611/home_od6whg.jpg" alt="" />
                                                                        <div style={{ fontSize: '14px' }}>
                                                                            {el.title}
                                                                            <p ><span className="pr-2">Unit: --</span> |<span className="px-2">Access Code: {el.accessCode}</span></p>
                                                                        </div>
                                                                    </IonItem>

                                                                )
                                                                )
                                                            ) : <h3 className='p-2'>Loading...</h3 >
                                                        }



                                                    </IonList>
                                                </div>
                                            </ClickOutside>


                                        )
                                    }


                                </IonCol>

                                <IonCol size='5'>
                                    <IonLabel className='schedule-label'>Which Date</IonLabel>
                                    <DatePicker
                                        selected={selectedDate}
                                        onSelect={async (e) => {
                                            await validateDateSelection(formatDate(e))
                                        }}
                                        onChange={date => {
                                            setSelectedDate(date)
                                            console.log(date)
                                        }}


                                    />


                                    {dateError.isShow && <IonLabel className='text-danger'>{dateError.message}</IonLabel>}




                                </IonCol>

                                <IonCol>
                                    <IonLabel>Check In Time</IonLabel>
                                    <IonChip className='schedule-chip' onClick={() => setShowCheckInTime(!showCheckInTime)} >
                                        <IonLabel>{checkIn ? checkIn : 'select check in time'}</IonLabel>
                                        <IonIcon icon={chevronDownOutline} />

                                    </IonChip>

                                    {
                                        showCheckInTime && (
                                            <ClickOutside onClickOutside={() => setShowCheckInTime(false)}>
                                            <TimeKeeper
                                                time='11:30PM'
                                                onDoneClick={async e => {
                                                    setCheckIn(e.formatted12)
                                                    setShowCheckInTime(false)

                                                }}
                                            />
                                            </ClickOutside>
                                        )
                                    }



                                </IonCol>
                                <IonCol>
                                    <IonLabel>Check Out Time</IonLabel>
                                    <IonChip className='schedule-chip' onClick={() => setShowCheckOutTime(!showCheckOutTime)} >
                                        <IonLabel>{checkOut ? checkOut : 'select check out time'}</IonLabel>
                                        <IonIcon icon={chevronDownOutline} />

                                    </IonChip>

                                    {
                                        showCheckOutTime && (
                                            <ClickOutside onClickOutside={() => setShowCheckOutTime(false)}>

                                            <TimeKeeper
                                                time='11:30PM'
                                                onDoneClick={async e => {
                                                    setCheckOut(e.formatted12)
                                                    setShowCheckOutTime(false)

                                                }}
                                            />
                                            </ClickOutside>
                                        )
                                    }



                                </IonCol>

                                {currentUser && currentUser.accountType && currentUser.accountType.toLowerCase() !== 'owner' && (
                                      <IonCol size='12'>
                                    <div onClick={() => setShowCLeaningDropdown(!showCleaningDropdown)} >

                                        <IonChip className='schedule-chip'>
                                            <IonLabel>{selectedCLeaner.length > 0 ? selectedCLeaner.map((el, idx) => `${idx > 0 ? ',' : ' '}${el.displayName}`) : 'All Cleaners'}</IonLabel>
                                            <IonIcon icon={chevronDownOutline} />
                                        </IonChip>
                                    </div>
                                    {
                                        showCleaningDropdown && (

                                                <ClickOutside onClickOutside={() => setShowCLeaningDropdown(false)}>

                                                    <div style={{ position: "absolute", zIndex: 3323232323232323, width: "100%" }}>
                                                    <IonList className='select-list'>

                                                        {
                                                            cleaners.length > 0 ?
                                                                cleaners.map((el, idx) => (
                                                                    <IonItem key={idx} className='cursor-pointer' onClick={async () => {
                                                                        /* await updateTeamSchedule(selectedProperty.id, selectedSchedule.id, el.id) */
                                                                        setSelectedCleaner([...selectedCLeaner, el])
                                                                        setShowCLeaningDropdown(false)

                                                                    }}>

                                                                        <div style={{ fontSize: '14px' }}>
                                                                            {el.displayName}
                                                                            <p >{el.email}</p>
                                                                        </div>
                                                                    </IonItem>
                                                                )) : <h6 className='ml-2'>no cleaner Available</h6>
                                                        }


                                                    </IonList>
                                                </div>
                                            </ClickOutside>


                                        )
                                    }
                                </IonCol>
                                )}




                            </IonRow>

                            {currentUser && currentUser.accountType && currentUser.accountType.toLowerCase() !== 'owner' && (
                  <IonRow className='margin-row'>
                                <IonCol>
                                    <div className="additional-note ion-no-padding">
                                        <h1 style={{ fontSize: '16px' }}>Additional Cleaning  Notes</h1>
                                        <IonTextarea className="additional-text" value={cleaningNotes} onIonChange={(e) => setCleaningNotes(e.detail?.value!)} />
                                    </div>
                                </IonCol>
                            </IonRow>
                            )}





                            <div className='schedule-button'>
                                <IonButton onClick={async () => {
                                    const obj = {
                                        checkIn,
                                        checkOut,
                                        startDate: formatDate(selectedDate),
                                        endDate: formatDate(selectedDate),
                                        notesToCleaner: cleaningNotes,
                                        propertyId: selectedProperty[0].id,
                                        status: "Not Started",
                                        summary: '',
                                        visible: false,
                                    }
                                    await handleSubmit(obj)
                                    setShowCleaning(false)

                                }

                                }>Schedule Cleaning</IonButton>

                            </div>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>

        </IonModal>
    );
}

export default CleaningModal;
