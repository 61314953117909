import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonText,
  IonButton,
  IonItem,
  IonRow,
  IonCol,
  IonIcon,
  IonInput,
  IonFooter,
  IonContent, IonSelect, IonSelectOption, IonToast,
} from "@ionic/react";
import "./InviteMember.css";
import {
  closeOutline,
  trashOutline,
} from "ionicons/icons";
import { TeamMemberSettings } from "../../types";
import { firestore, inviteMembers, sendMailWhenNewTeamMemberInvited } from "../../lib/firebase";
import { authContext } from "../../context/auth-context/auth-context";
import { useHistory } from "react-router";

interface ContainerProps {
  setShowModal: any;
}

const InviteMember: React.FC<ContainerProps> = ({ setShowModal }) => {
  const { teamData } = useContext(authContext);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [allPendingTeam, setAllPendingTeam] = useState<any>([])
  const [allTeam, setAllTeam] = useState<any>([])
  const [teamIdx, setTeamIdx] = useState<any>([])
  const [teamMembers, setTeamMembers] = useState<TeamMemberSettings[]>([]);
  const [showToast, setShowToast] = useState(false)
  const history = useHistory()

  const handleClearMember = (email: string) => {
    const allTeamMembers = [...teamMembers];
    const emailIndex = allTeamMembers.findIndex((item) => item.email === email);
    allTeamMembers.splice(emailIndex, 1);
    setTeamMembers(allTeamMembers);
  };

  const handleAddEmail = () => {
    const allTeamMembers = [...teamMembers];
    const newEmail = { email: email, role: '' };
    const index = allTeamMembers.findIndex((item) => item.email === email);
    if (index === -1) {
      allTeamMembers.push(newEmail);
      setEmailErr("");
    } else {
      setEmailErr("* This Email is already added!");
    }
    setTeamMembers(allTeamMembers);
    setEmail("");
  };

  // const handlePermissionBox = (email: string) => {
  //   const permissonBox = [...showPermissionBox];
  //   const index = permissonBox.indexOf(email);
  //   if (index === -1) {
  //     permissonBox.push(email);
  //   } else {
  //     permissonBox.splice(index, 1);
  //   }
  //   setShowPermissionBox(permissonBox);
  // };

  // const handleCheckbox = (index: number, value: string) => {
  //   const allEmails = [...selectedEmail];
  //   const foundedIndex = allEmails[index].access.indexOf(value);
  //   if (foundedIndex === -1) {
  //     allEmails[index].access.push(value);
  //   } else {
  //     allEmails[index].access.splice(index, 1);
  //   }
  //   setSelectedEmail(allEmails);
  // };

  useEffect(() => {
    const getData = async () => {
      const collectionRef = await firestore.collection(`teams`).get()
      const pendingRef = await firestore.collection(`pendingTeamInvites`).get();
      const data = collectionRef.docs.map(el => el.data())
      const pendingData = pendingRef.docs.map(el => el.data())
      const teamIdx = data.map(el => el.id)
      setTeamIdx(teamIdx)
      setAllTeam(data);
      setAllPendingTeam(pendingData);

    }
    getData()
  }, [])

  const handleDisable = () => {
    let memberWithoutRole = false;
    teamMembers.forEach((member) => {
      if (!member.role) { memberWithoutRole = true; }
    });
    return memberWithoutRole;
  };

  const handleSubmit = async () => {
    //API called
    //@ts-ignore
    const data = await allTeam.filter(el1 => el1.members.some(el2 => teamMembers.some(el3 => el2.email === el3.email)))
    const pendingData = await teamIdx.filter(el1 => allPendingTeam.some((el2, idx) => teamMembers.some(el3 => {
      const isExist = el2[el1];
      if (!isExist) {
        return false
      }
      return el2[el1].email.toLowerCase() === el3.email.toLowerCase()
    })))
    if (data.length > 0 || pendingData.length > 0) {
      setShowToast(true)

    } else {
      inviteMembers(teamMembers, teamData.id);
      sendMailWhenNewTeamMemberInvited(teamMembers)
      setEmail("");
      setEmailErr("");
      setTeamMembers([]);
      setShowModal(false);
    } 



  };

  return (
    <>
      <IonContent>
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message="This email is already In a Team"
          duration={1000}
        />
        <IonRow className="ion-justify-content-between ion-align-items-center invite-modal">
          <IonRow style={{ width: "100%" }} className="ion-align-items-center">
            <IonCol size="11">
              <h6>Add your team members</h6>
            </IonCol>
            <IonCol size="1">
              <IonIcon
                icon={closeOutline}
                className="close-icon"
                onClick={() => setShowModal(false)}
              />
            </IonCol>
          </IonRow>
          <IonText style={{ paddingLeft: "4px" }}>
            <p>
              They will receive an invite to create an account with the email
              address they use for work.
            </p>
          </IonText>
          <IonCol sizeXs="9">
            <IonItem className="email">
              <IonInput
                placeholder="Enter Email"
                value={email}
                onIonChange={(e) => setEmail(e.detail.value!)}
              />

              {email ? (
                <IonIcon
                  icon={closeOutline}
                  style={{ fontSize: "20px" }}
                  onClick={() => setEmail("")}
                />
              ) : null}
            </IonItem>
          </IonCol>
          <IonCol sizeXs="3">
            <IonButton
              className="add-btn"
              fill="outline"
              onClick={() => {
                handleAddEmail();
              }}
              disabled={!email}
            >
              Add
            </IonButton>
          </IonCol>
          <IonText color="danger" style={{ paddingLeft: "4px" }}>
            <p>{emailErr}</p>
          </IonText>

          {teamMembers.length > 0
            ? teamMembers.map((member, index) => (
                <IonRow style={{ width: "100%" }} key={`email_${index}`}>
                    <IonCol sizeXs="4">{member.email}</IonCol>
                    <IonCol sizeXs="12" sizeSm="6">
                        <IonSelect value={member.role} placeholder="Select One" onIonChange={e => {
                            if (!e.detail.value) return;
                            const team = teamMembers.map((person) => { return person.email !== member.email ? person : {...person, role: e.detail.value} });
                            setTeamMembers(team);
                        }}>
                            <IonSelectOption value="Cleaner">Cleaner</IonSelectOption>
                            <IonSelectOption value="Owner">Owner</IonSelectOption>
                            <IonSelectOption value="Admin">Admin</IonSelectOption>
                        </IonSelect>
                        <IonFooter>
                          <IonButton
                            fill="clear"
                            className="learn-btn"
                            onClick={() => {
                              handleClearMember(member.email);
                            }}
                          >
                            <IonIcon icon={trashOutline} />
                            Remove member
                          </IonButton>
                        </IonFooter>
                    </IonCol>
                </IonRow>
              ))
            : null}
        </IonRow>
        <IonRow className="invite-modal ion-align-items-center ion-justify-content-center">
          {teamMembers.length > 0 ? null : (
            <IonText>
              <p>You have not added any emails yet</p>
            </IonText>
          )}
        </IonRow>
      </IonContent>
      <IonFooter>
        <IonRow className="invite-footer">
          <IonCol size="6">
            <IonButton
              fill="clear"
              className="learn-btn"
              onClick={() => history.push("/dashboard/teams/permissions")}
            >
              Learn about permissions
            </IonButton>
          </IonCol>
          <IonCol size="6">
            <IonButton
              className="add-btn"
              disabled={handleDisable()}
              onClick={() => handleSubmit()}
              style={{ paddingRight: "13px" }}
            >
              Send {teamMembers.length} invites
            </IonButton>
          </IonCol>
        </IonRow>
      </IonFooter>
    </>
  );
};

export default InviteMember;
