import {teams} from "./mockdata.json";

export function getAllTeams(dispatch) {
  try{
    dispatch({ type: "GET_ALL_TEAMS_SUCCESS", teams });
  }catch(error){
    dispatch({ type: "GET_ALL_TEAMS_ERROR", error });
  }
}

export function getTeam(dispatch,id) {
  try{
    const team = teams.find(item=>item.id===id)
    dispatch({ type: "GET_TEAM_SUCCESS", team });
  }catch(error){
    dispatch({ type: "GET_TEAM_ERROR", error });
  }
}

export function getTeamMember(dispatch, teamId, memberId) {
  try{
    const team = teams.find(item=>item.id===teamId);
    const member = team.members.find(item=>item.id===memberId)
    dispatch({ type: "GET_TEAM_MEMBER_SUCCESS", member });
  }catch(error){
    dispatch({ type: "GET_TEAM_MEMBER_ERROR", error });
  }
}

export function addTeamMember() {
  return 
}

export function updateTeamMember(dispatch, memberId, member) {
  try{
    const team = teams[0];
  let tempMember = team.members.find(item=>item.id===memberId)
  tempMember=member;
    dispatch({ type: "UPDATE_TEAM_MEMBER_SUCCESS", tempMember });
  }catch(error){
    dispatch({ type: "UPDATE_TEAM_MEMBER_ERROR", error });
  }
}

export function updateTeam(dispatch, teamId, name, logo, status) {
  try{
    let team = teams.find(item=>item.id===teamId);
    team={...team, settings:{name, logo, status}};
    dispatch({ type: "UPDATE_TEAM_SUCCESS", team });
  }catch(error){
    dispatch({ type: "UPDATE_TEAM_ERROR", error });
  }                                                                           
}

export function removeTeamMember(dispatch, memberId) {
  try{
    const foundedIndex = teams[0].members.indexOf(item=>item.id===memberId)
  if(foundedIndex>=-1){
    teams[0].members.splice(foundedIndex, 1)
  }
    dispatch({ type: "REMOVE_TEAM_MEMBER_SUCCESS", team:teams[0] });
  }catch(error){
    dispatch({ type: "REMOVE_TEAM_MEMBER_ERROR", error });
  }  
}
