import { IonList } from "@ionic/react";
import React from "react";
import { useMediaQuery } from "react-responsive";
import { processTheColumnData } from "../../utils/utils";


// Resusable component

import Table,{ TableRow, TableRowItem, TableRows, TableRowsItem } from './../Table/Table'
export interface teamTableProps {
    teamMember: any[]
}



const TeamTables: React.FC<teamTableProps> = ({teamMember}) => {
  const teamRowItemData: any[] = processTheColumnData(teamMember,'displayName', 'email', 'role');
  const isTablet = useMediaQuery({
    maxWidth: 550
})
  return (
    <Table>
        <TableRows>
            <TableRow label='Name' size='4' />
            <TableRow cssClass='text-center' label='Email' size='4' />
            <TableRow  cssClass="text-end" label='Role' size='4' />
        </TableRows>
        <IonList className="w-100 team-member-list">
        {
            teamRowItemData.map((row) => {
               return (
                    <TableRowsItem>
                     {
                         Object.keys(row).map(key => {
                             
                            const content =  row[key].split('@')
                             return(<TableRowItem 
                                size='4'>
                             {
                                 isTablet && row[key].includes('@') ? `${content[0]} ${content[1]}` : row[key]
                             }
                         </TableRowItem>)})
                     }
                </TableRowsItem>
               )}
            )
        }

        </IonList>

    </Table>
  );
};


export default TeamTables;
