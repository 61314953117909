import React from "react";
import {
  IonText,
  IonButton,
  IonIcon,
} from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";
import "./successModal.css";

interface ModalProps {
  handleCloseModal: any;
  heading:string;
  text:string
}

const SuccessModal: React.FC<ModalProps> = ({handleCloseModal, heading, text}) => {
  return (
    <div className="success-root">
      <IonText color="primary"> 
      <IonIcon  icon={checkmarkCircleOutline} className="check-icon" onClick={handleCloseModal}/>
        <h4 style={{ fontWeight: "bold" }}>{heading}</h4>
        </IonText>
        <IonText>
        <p>
          
          {text}
        </p>
        </IonText>
        <IonButton
           onClick={handleCloseModal}
            >
             Okay
            </IonButton>
    </div>
   
  );
};

export default SuccessModal;
