import React, {useEffect, useState} from 'react';
import './Settings.css';
import 'react-calendar/dist/Calendar.css';
import '../theme/react-calendar-overrides.css';
import firebase, {userProfileData} from '../lib/firebase';
import {IonButton} from "@ionic/react";

const Settings: React.FC = () => {
    const [profile, setProfile] = useState<any>({});

    async function getProfile(uid) {
        const profileData = await userProfileData(uid);
        setProfile(profileData || {})
    }

    useEffect(() => {
        getProfile(firebase.auth().currentUser?.uid);
    }, [firebase.auth().currentUser]);

  return (
    <div className="container py-5">
        <h1>Settings</h1>
        <div>
            Account Name: {firebase.auth().currentUser?.displayName} <br />
            Account Email: {firebase.auth().currentUser?.email} <br />
            Account Type: {profile?.accountType} <br />
            Job Title: {profile?.jobTitle} <br />
        </div>

        <IonButton className='button-sm-res' onClick={() => firebase.auth().signOut()}>Log Out</IonButton>
    </div>
  );
};

export default Settings;
