import React, { useContext, useEffect, useState } from "react";
import {
  IonBadge,
  IonHeader,
  IonToolbar,
  IonRow,
  IonTitle,
  IonCol,
  IonIcon,
  IonButton,
  IonList,
  IonItem,
} from "@ionic/react";
import {
  timeOutline,
  notificationsOutline,
  logInOutline,
  calendarOutline,
} from "ionicons/icons";
import "./header.css";
import { useHistory, useLocation } from "react-router";
import { authContext } from "../context/auth-context/auth-context";
import { NavLink } from "react-router-dom";

export interface Props {
  handleUserDropdown: any;
}

function isActiveRoute(activeRoute, route) {
  if (activeRoute.includes(route)) {
    return " header-link__active";
  }
  return "";
}

const Header: React.FC<Props> = ({ handleUserDropdown }) => {
  const { setShowCleaning } = useContext(authContext);
  const history = useHistory();
  const location = useLocation();
  const [shortName, setShortName] = useState("");
  const { currentUser } = useContext(authContext);

  const getTwoLettersOfName = (name: string) => {
    const nameArray = name.split(" ");
    const initials = nameArray.reduce(
      (acc: any, curr, index) => {
        if (index === 0 || index === nameArray.length - 1) {
          acc = `${acc}${curr.charAt(0).toUpperCase()}`;
        }
        return acc;
      },
      [""]
    );
    return initials;
  };
  useEffect(() => {
    if (currentUser.displayName) {
      const name = getTwoLettersOfName(currentUser.displayName);
      setShortName(name);
    }
  }, [currentUser.displayName]);

  console.log(location.pathname);

  return (
      <IonHeader>

    <IonToolbar className="main-header d-flex header-toolbar">
      <header className="header">
      <div className="d-flex align-items-center justify-content-between w-100">
                    <div>
                            <span className=" brand__logo brand__logo--1">Clean</span>
                           <span className="brand__logo brand__logo--2"> stays</span>
                            </div>

                                          <ul className="header-list">
{
                            currentUser.accountType !== 'Cleaner' &&
                            <li className="header-li custom-merge">
                                <a onClick={() => history.push("/dashboard/listings")} className={'header-link' + isActiveRoute(location.pathname, 'listings')}>
                                    Properties
                                </a>
                            </li>
                        }

                        <li className="header-li custom-merge">
                            <a onClick={() => history.push("/dashboard/schedule")} className={'header-link' + isActiveRoute(location.pathname, 'schedule')}>
                                {/* <IonBadge className="badge-header">134</IonBadge> */}
                                Schedule
                            </a>
                        </li>
 
                        {
                            currentUser.accountType !== 'Cleaner' && currentUser.accountType !== 'Owner' &&
                            <li className="header-li custom-merge">
                                <a onClick={() => history.push("/dashboard/teams")} className={'header-link' + isActiveRoute(location.pathname, 'team')}>
                                    Team
                                </a>
                            </li>
                        }
                        {
                            currentUser.accountType !== 'Cleaner' && currentUser.accountType !== 'Owner' &&
                            <li className="header-li custom-merge">
                                <a onClick={() => history.push("/dashboard/lists")} className={'header-link' + isActiveRoute(location.pathname, 'lists')}>
                                    Task Lists
                                </a>
                            </li>
                        }

{
                            currentUser.accountType !== 'Cleaner' && currentUser.accountType !== 'Owner' &&
                            <li className="header-li custom-merge">
                                <a onClick={() => history.push("/dashboard/reports")} className={'header-link' + isActiveRoute(location.pathname, 'reports')}>
                                    Reports
                                </a>
                            </li>
                        }

                    </ul>
                    <ul className="header-list header-list-rt">
                        <li className="header-li">
                            <IonButton className='new-clr-btn' onClick={() => setShowCleaning(true)}> <IonIcon className='mr-2' icon={calendarOutline} /> Schedule New Cleaning</IonButton>
                        </li>
                        <li className="header-li" style={{ cursor: 'pointer' }} onClick={handleUserDropdown}>
                            {shortName && <span className="name-avator">{shortName}</span>}
                        </li>
                    </ul>
                    </div>
      </header>
    </IonToolbar>
    </IonHeader>

  );
};

export default Header;
