import './timer.css'
import parse from "url-parse";

import React, { Component } from "react";
/* import "./Timer.css";
 */
import EasyTimer from "easytimer.js";
import { IonButton, IonIcon } from "@ionic/react";
import { radioButtonOffOutline, radioButtonOnOutline } from 'ionicons/icons';
import firebase, { firestore } from '../lib/firebase';

const decideZero = (num) => {
  console.log(num)
  if(num >= 0 && num < 10) {
    return "0"
  }   else return ''

}

class Timer extends Component {
  constructor(props) {
    super(props);

    var timer = new EasyTimer();

    this.state = {
      timer_text: this.props.timerObject ? `${decideZero(this.props.timerObject.hour)}${this.props.timerObject.hour}:${decideZero(this.props.timerObject.minitue)}${this.props.timerObject.minitue}:${decideZero(this.props.timerObject.second)}${this.props.timerObject.second}` : timer.getTimeValues().toString(),
      timer: timer,
      timer_state: "stopped"
    };

    //Bind the functions
    this.startTimer = this.startTimer.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    this.pauseTimer = this.pauseTimer.bind(this);
    this.resetTimer = this.resetTimer.bind(this);
    this.logTime = this.logTime.bind(this);

    //Add the listeners
    timer.addEventListener("secondsUpdated", this.onTimerUpdated.bind(this));

    timer.addEventListener("started", this.onTimerUpdated.bind(this));

    timer.addEventListener("reset", this.onTimerUpdated.bind(this));
  }



  onTimerUpdated(e) {
    this.setState({
      ...this.state,
      timer_text: this.state.timer.getTimeValues().toString()
    });
  }

  startTimer() {
    
    if ( this.props.timerObject && !isNaN(this.props.timerObject.hour)  && !isNaN(this.props.timerObject.minitue)) {
      console.log(this.props.timerObject)

     this.state.timer.start({startValues: {minutes:this.props.timerObject.minitue, seconds: this.props.timerObject.second, hours: this.props.timerObject.hour }});
 
    } else {
      this.state.timer.start();

   }

    this.setState({
      ...this.state,
      timer_state: "ticking"
    });
  }

  stopTimer() {
    this.state.timer.stop();

    this.setState({
      ...this.state,
      timer_text: "00:00:00",
      timer_state: "stopped"
    });
  }

  pauseTimer() {
    this.state.timer.pause();

    this.setState({
      ...this.state,
      timer_state: "paused"
    });
  }

  resetTimer() {
    this.state.timer.reset();

    this.setState({
      ...this.state,
      timer_state: "ticking"
    });
  }

  logTime() {
    console.log(this.state.timer.getTimeValues().toString());
    this.props.addTime(this.state.timer.getTimeValues());
  }

  render() {
    return (
      <div className="Timer">
        <div className="timer-text ">
          <h2 className='d-flex align-items-center justify-content-center'>{this.state.timer_text} 
          <IonIcon className={ this.state.timer_state === 'ticking' ? 'icon-green'  : 'icon-red'} icon={radioButtonOnOutline  } />
          </h2>
        </div>
        <div className="timer-buttons text-center">

          <IonButton onClick={() => {
                        this.props.handleTime();

                        this.startTimer()





          }}>Clock In</IonButton>
          <IonButton onClick={() => {
                       this.pauseTimer()
            this.props.handleTime();
 

          }} >Clock Out</IonButton>

        </div>

      </div>
    );
  }
}

export default Timer;
