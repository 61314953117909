import React, { useState, useEffect, useContext } from "react";
import { create_UUID, getBrandImage } from './listing.config'
import _ from "lodash";
import {
  IonButton,
  IonText,
  IonRow,
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonInput,
  IonList,
  IonToast
} from "@ionic/react";
import { chevronBackOutline, trashOutline } from "ionicons/icons";
import "./ViewListing.css";
import { Property } from "../../types";
import { useHistory } from "react-router";
import AddEditListing from "./AddEditListing";
import {addCalendarToDB, getProperty, removeCalendarFromDB} from "../../lib/firebase";
import TaskLists from "../../components/TaskLists";
import { CustomLoading } from "../../components/customLoading";
import { DashBoardContext } from "../../context/dashboard/dashboard.context";

interface ContainerProps {
  name: string;
  action: string;
  listingId?: string;


}
const brandArray = [
  {
    id: 1,
    name: 'airbnb',
    imageUrl: 'https://res.cloudinary.com/dltd4gs4a/image/upload/v1616758320/iconfinder_social_media_social_media_logo_airbnb_2993798_1_pesoi8.png',
    activeClass: 'airBnB-active'
  },
  {
    id: 2,
    name: 'Booking.com',
    imageUrl: 'https://res.cloudinary.com/dltd4gs4a/image/upload/v1616759054/bookingcom-icon_s666gq.png',
    activeClass: 'booking-active'
  },
  {
    id: 3,
    name: 'vrbo',
    imageUrl: 'https://res.cloudinary.com/dltd4gs4a/image/upload/v1616759235/pngfind.com-hennessy-logo-png-3025981_fys89p.png',
    activeClass: 'vrbo-active'
  },
  {
    id: 4,
    name: 'tripAdvisor',
    imageUrl: 'https://res.cloudinary.com/dltd4gs4a/image/upload/v1616759142/tripadvisor-icon-12007_eflsdw.png',
    activeClass: 'tripAdvisor-active'
  },
  {
    id: 5,
    name: 'ical/Others',
    imageUrl: 'https://i.pinimg.com/originals/ff/22/c6/ff22c66b5f7d9b60ec981b2f7411ed0d.png',
    activeClass: 'ical-active'
  },

]





const ViewListing: React.FC<ContainerProps> = ({ name, action, listingId }) => {
  const { cto } = useContext(DashBoardContext);

  const [brandNameError, setBrandNameError] = useState({ isError: false, message: '' });
  const [icsError, setIcsError] = useState({ isError: false, message: '' });
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [calendars, setCalendars] = useState<any>([]);
  const [calendarInput, setCalendarInput] = useState<string>('');
  const [listingData, setListingData] = useState<Property | null>(null);
  const [selectedSegment, setSelecedSegment] = useState<string | undefined>(() => {
    if (cto) return cto;
    return "details"

  });
  const [activeCalendar, setActiveCalendar] = useState('');
  const history = useHistory();


  useEffect(() => {
    if (listingId) {

      getProperty(listingId).then((data: any) => {
        setListingData(data[0]);
        setCalendars(data[0].calendars || []);
      })
    }
  }, [listingId]);

  //Adding Calendar
  const addCalendar = async (calendarBrand, newCalendar: string) => {
    if (!newCalendar.includes('.ics')) {
      setIcsError({ isError: true, message: 'URL for calender needs to include .ics file extension' });
      setTimeout(() => {
        setIcsError({ isError: false, message: '' });
      }, 3000);
    } else if (calendarBrand && newCalendar.includes(calendarBrand) || calendarBrand.includes('ical')) {
      const id = create_UUID();
      await addCalendarToDB(newCalendar, listingId, calendarBrand, id);
      setShowToastMessage(true);
      setCalendars([...calendars, { url: newCalendar, platform: calendarBrand, id }]);
      setBrandNameError({ isError: false, message: '' });
      setIcsError({ isError: false, message: '' });
    } else if (calendarBrand && !newCalendar.includes(calendarBrand)) {
      setIcsError({ isError: true, message: 'URL for calendar must include ' + calendarBrand + '.  Try another URL or select the right reservation platform.' });
      setTimeout(() => {
        setIcsError({ isError: false, message: '' });
      }, 3000);
    } else {
      setBrandNameError({ isError: true, message: 'Please select a calendar type*' });
      setTimeout(() => {
        setBrandNameError({ isError: false, message: '' });
      }, 3000);
    }
  }

  //removing Calendar

  const removeCalendar = (id) => {
    console.log(calendars)
    const filteredData = calendars.filter(el => el.id !== id);
    setCalendars(filteredData);
  }

  return (
    <>
      <IonToast
        isOpen={showToastMessage}
        onDidDismiss={() => setShowToastMessage(false)}
        message="Calendar Have been successfully added"
        duration={200}
      />
      <IonContent>
        <div className="view-listing-sec">
          <IonButton onClick={() => history.push('/dashboard/listings')} fill="clear" className="back-btn">
            <IonIcon slot="start" icon={chevronBackOutline} />
            Back to listings
          </IonButton>
          <IonRow className="ion-justify-content-between">
            <IonCol sizeXs="12" sizeMd="7">
              <IonText className="heading">
                {listingData && listingData.title}
              </IonText>
            </IonCol>
          </IonRow>
          <IonItem>
            <IonSegment onIonChange={e => setSelecedSegment(e.detail.value)} value={selectedSegment}>
              <IonSegmentButton value="details">
                <IonLabel className="listing-details">Listing details</IonLabel>
              </IonSegmentButton>

              <IonSegmentButton value="calendar">
                <IonLabel className="listing-details">Calendar</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="taskLists">
                <IonLabel className="listing-details">Task lists</IonLabel>
              </IonSegmentButton>

            </IonSegment>
          </IonItem>
          {selectedSegment === "taskLists" ? <TaskLists property={listingData} isViewTaskList={true} /> : null}
          {selectedSegment === "details" ? <AddEditListing name="Listing" action="edit" listingId={listingId} preloadedProperty={listingData} /> : null}
          {selectedSegment === "calendar" ? (

            <IonRow>
              {
                listingData === null ? <CustomLoading loading={true} /> : null
              }
              <IonCol sizeMd='8' size='12' offsetMd='2' offset='0'>
              <h4>New Calender</h4>
              <h6>To add a calendar, select your reservation platform</h6>
              <IonRow>
                {
                  brandArray.map((el, index) => (
                    <IonCol size='12' sizeMd='6' sizeLg='4' key={'calendar-brand-' + index}>
                      <button style={{ width: '100%' }} value={el.name} onClick={() => setActiveCalendar(el.name)} className={`calendar-brand ${activeCalendar.includes(el.name) ? `${el.activeClass}` : ''}`} >
                        {el.imageUrl && (<img width='35px' src={el.imageUrl} alt="" />)}
                        <span className='calendar-brand__text'>{el.name}</span>
                      </button>
                    </IonCol>
                  ))

                }


              </IonRow>
              {
                brandNameError.isError && (
                  <IonRow>
                    <IonCol>
                      <IonLabel className='c-err'>{brandNameError.message}</IonLabel>
                    </IonCol>
                  </IonRow>
                )
              }
              {
                icsError.isError && (
                  <IonRow>
                    <IonCol>
                      <IonLabel className='c-err'>{icsError.message}</IonLabel>
                    </IonCol>
                  </IonRow>
                )
              }


              <IonRow>
                  <IonCol size='12' sizeMd='8'>
                  <IonItem >
                    <IonLabel position='floating'>Calender Link</IonLabel>
                    <IonInput value={calendarInput} onIonChange={(e) =>
                      setCalendarInput(e.detail?.value!)
                    } className='calendar-link' />
                  </IonItem>
                </IonCol>
                <IonCol className='calendar-align'>
                  <IonButton onClick={() => {
                    addCalendar(activeCalendar.toLocaleLowerCase(), calendarInput.toLocaleLowerCase());
                    setCalendarInput('')
                  }} >Add New Calendar</IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                  <IonCol >
                  <IonList>
                    {
                      calendars.map((el: any, index) => (
                        <IonItem className='calendar-item-list' lines='none' key={'calendar-item-' + index}>
                          <div className='calendar-item-list__cont font-weight-bold'>

                            <div className="calender-item-list__content">
                              <img src={getBrandImage(el.platform)} width='35px' alt="" />
                              {el.platform}
                              <div className=' ml-4'>
                                <h5>CALENDER URL:&nbsp; <span className='font-weight-normal'>{el.url}</span> </h5>
                                <h5 className='font-weight-normal'>For AirBnB calenders, import non-airbnb calenders from my airbnb calender. The option will import blocked date from Airbnb calander </h5>
                              </div>
                            </div>

                            <div className='calendar-cross' onClick={async () => {
                              await removeCalendarFromDB(el, listingData)
                              removeCalendar(el.id)

                            }}><IonIcon icon={trashOutline} /></div>
                          </div>

                        </IonItem>
                      ))
                    }

                  </IonList>
                </IonCol>
              </IonRow>

            </IonCol>
          </IonRow>) : null}
        </div>
      </IonContent>
    </>
  );
};

export default ViewListing;
