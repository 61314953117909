import React, { useState } from "react";
import {
  IonButton,
  IonLabel,
  IonRow,
  IonCol,
  IonIcon,
  IonContent,
  IonBadge,
  IonItemDivider,
  IonList,
  IonText,
} from "@ionic/react";
import {
  chevronBackOutline,
  chevronDownOutline,
  chevronUpOutline,
} from "ionicons/icons";
import "./TeamPermissions.css";
import { settings } from "../../config/config";
import {useHistory} from "react-router";

const TeamPermissions: React.FC = () => {
  const history = useHistory();
  const [showPermissionDesc, setShowPermissionDesc] = useState<string[]>([]);

  const handlePermissionsDesc = (name: string) => {
    const permissonBox = [...showPermissionDesc];
    const index = permissonBox.indexOf(name);
    if (index === -1) {
      permissonBox.push(name);
    } else {
      permissonBox.splice(index, 1);
    }
    setShowPermissionDesc(permissonBox);
  };

  return (
    <IonContent>
      <div className="team-permissions">
        <IonButton onClick={() => history.goBack()} fill="clear" className="back-btn">
          <IonIcon slot="start" icon={chevronBackOutline} />
          Back to team
        </IonButton>
        <IonLabel>
          <h1>Permission sets</h1>
          <p>
            Assign permissions to team members so they can access the
            information they need to keep things running smoothly.
          </p>
        </IonLabel>
        <IonItemDivider>
          <IonLabel className="permission-sec">
            <IonRow className="ion-justify-content-between ion-align-items-center">
              <IonCol sizeXs="6">
                <h3>All team members</h3>
              </IonCol>
              <IonCol sizeXs="4">
                <IonBadge className="badge">Everyone</IonBadge>
              </IonCol>
            </IonRow>
            <p>
              All team members can adjust their own account settings, including
              login and password.
            </p>
          </IonLabel>
        </IonItemDivider >
        <IonItemDivider className='ion-no-padding'>
          <IonRow style={{ width: "100%" }}>
            <IonCol sizeXs="12">
              <IonLabel className="permission-sec">
                <IonRow className="ion-justify-content-between ion-align-items-center">
                  <IonCol sizeXs="6">
                    <h3>Specific permissions</h3>
                  </IonCol>
                  <IonCol sizeXs="4">
                    <IonBadge className="badge">Assigned</IonBadge>
                  </IonCol>
                </IonRow>
                <p>
                  Each permission set gives team members more control over
                  specific features and info.
                </p>
              </IonLabel>
            </IonCol>
            <IonCol sizeXs="12">
              <IonList>
                {settings.map((item, index) => {
                  return (
                    <div
                      key={`permission_${index}`}
                      onClick={() => handlePermissionsDesc(item.value)}
                      className="list"
                    >
                      <IonRow
                        className="ion-align-items-center"
                        style={{ width: "100%" }}
                      >
                        {showPermissionDesc.indexOf(item.value) === -1 ? (
                          <IonIcon
                            icon={chevronUpOutline}
                            className="chevron-icons"
                          />
                        ) : (
                          <IonIcon
                            icon={chevronDownOutline}
                            className="chevron-icons"
                          />
                        )}

                        <IonIcon icon={item.icon} className="inner-icons" />
                        <IonText color="primary">
                          <h6>{item.name}</h6>
                        </IonText>
                        {item.badge ? (
                          <IonBadge className="badge">{item.badge}</IonBadge>
                        ) : null}
                      </IonRow>
                      <IonRow
                        className="ion-align-items-center"
                        style={{ width: "100%" }}
                      >
                        {showPermissionDesc.indexOf(item.value) ===
                        -1 ? null : (
                          <IonText className="text-padding">
                            <p>With this permission set, team members can:</p>
                            <IonList>
                              {item.detailedDesc?.map((point) => (
                                <li className="list-item">{point}</li>
                              ))}
                            </IonList>
                          </IonText>
                        )}
                      </IonRow>
                    </div>
                  );
                })}
              </IonList>
            </IonCol>
          </IonRow>
        </IonItemDivider>
        <IonItemDivider className='ion-no-padding'>
          <IonLabel className="permission-sec">
            <IonRow className="ion-justify-content-between ion-align-items-center">
              <IonCol sizeXs="6">
                <h3>Account owner permissions</h3>
              </IonCol>
              <IonCol sizeXs="4">
                <IonBadge className="badge">Owner</IonBadge>
              </IonCol>
            </IonRow>
            <div>
              <p>Only the account owner has this permission set</p>
              <h6 style={{ padding: "15px 0px 0px 5px" }}>
                The account owner can
              </h6>
            </div>
            <IonList>
              <li className="list-item">Access all permissions</li>

              <li className="list-item">
                Edit business account details, taxpayer info, and payout
                preferences
              </li>

              <li className="list-item">deactivate the Airbnb account</li>
            </IonList>
          </IonLabel>
        </IonItemDivider>
      </div>
    </IonContent>
  );
};

export default TeamPermissions;
