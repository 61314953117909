import React, { useState, useEffect, useContext } from "react";
import {
  IonText,
  IonButton,
  IonLabel,
  IonCheckbox,
  IonIcon,
  IonBadge,
  IonContent,
  IonList,
  IonItem,
  IonModal,
} from "@ionic/react";
import { chevronForwardOutline, chevronBackOutline } from "ionicons/icons";
import { AppContext } from "../../context/app-context";
import { Member } from "../../types";
import "./TeamMember.css";
import { settings } from "../../config/config";
import SuccessModal from "../../components/successModal";
import { useHistory } from "react-router";

interface TeamMemberProps {
  memberId: string;
}

const TeamMember: React.FC<TeamMemberProps> = ({memberId}) => {
  const globalState = useContext(AppContext);
  const [member, setMember] = useState<Member>();
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const history = useHistory()

  useEffect(() => {
    globalState.dispatch({
      type: "GET_TEAM_MEMBER",
      successAction: globalState.dispatch,
      teamId: "Team 1",
      memberId,
    });
  }, []);

  useEffect(() => {
    if (globalState) {
      globalState.state.member && setMember(globalState.state.member);
    }
  }, [globalState.state.member]);

  useEffect(() => {
    if (globalState) {
      globalState.state.isMemberUpdated && setSuccessModal(true);
    }
  }, [globalState.state.isMemberUpdated]);

  const handleUpdatePermissions = (member) => {
    globalState.dispatch({ type: "UPDATE_TEAM_MEMBER",successAction:globalState.dispatch, memberId, member });
  };

  const handlePermissionCheckbox = (value: string) => {
    let tempMember = member;
    const permissions = (member && member.access) || [];
    const index = permissions.indexOf(value);
    if (index === -1) {
      permissions.push(value);
    } else {
      permissions.splice(index, 1);
    }
    //@ts-ignore
    tempMember = { ...member, access: permissions };
    setMember(tempMember);
  };

  const handleCloseModal = () => {
    setSuccessModal(false);
    globalState.dispatch({ type: "RESET_TEAM_FLAGS" });
  };

  return (
    <IonContent>
      <div className="member-permissions">
        <div style={{ paddingLeft: '14px' }}>
                 <IonButton onClick={() => history.push("/dashboard/teams")} fill="clear" className="back-btn">
          <IonIcon slot="start" icon={chevronBackOutline} />
          Back to team
        </IonButton>
        <IonText>
          <h2 style={{ fontWeight: "bold" }}>
            Manage Permissions
            <IonButton

              fill="clear"
              onClick={() => history.push("/dashboard/teams/permissions")}
              className="settings-btn"
            >
              More details
              <IonIcon slot="end" icon={chevronForwardOutline} />
            </IonButton>
          </h2>
        </IonText>
        </div>

        <IonList>
          {settings.map((item, index) => (
            <IonItem key={index}>
              <IonLabel>
                <IonIcon icon={item.icon} style={{ fontSize: "20px" }} />
                &nbsp; &nbsp;
                {item.badge ? (
                  <IonBadge className="badge">{item.badge}</IonBadge>
                ) : null}
                <br />
                <h2>{item.name}</h2>
                <p>{item.desc}</p>
              </IonLabel>
              <IonCheckbox
                checked={
                  member && member.access.includes(item.value) ? true : false
                }
                color="dark"
                onIonChange={() => handlePermissionCheckbox(item.value)}
                slot="end"
              />
            </IonItem>
          ))}
        </IonList>
        <IonButton
          className='mx-3'
          style={{ textTransform: "none" }}
          onClick={() => handleUpdatePermissions(member)}
          disabled={member && member.access.length === 0 ? true : false}
        >
          Update permissions
        </IonButton>
      </div>
      <IonModal
        isOpen={successModal}
        cssClass="success-modal"
        onDidDismiss={handleCloseModal}
      >
        <SuccessModal handleCloseModal={handleCloseModal} heading="Success!" text="Member permissions are updated successfully."/>
      </IonModal>
    </IonContent>
  );
};

export default TeamMember;
