import { createContext } from "react";

export interface Assignee {
  id: string;
  name: string;
  imageUrl: string;
  email: string;
}

export const taskDetailsContext = createContext({
  showCard: <boolean>false,
  setShowCard: (p: boolean) => {},
  assignee: <Assignee[]>[],
  handleAssignee: (obj: object, selected: boolean, idx: number) => {},
});
