import "react-tabs/style/react-tabs.css";

import React, { useEffect, useState } from "react";
import { useHistory, useParams  } from "react-router";
import ClickOutside from '@leafyjava/react-click-away'

import "./App.css";
import Menu from "./components/Menu";
import Dashboard from "./pages/Dashboard";
import {
  IonApp,
  IonButtons,
  IonHeader,
  IonItem,
  IonList,
  IonMenuButton,
  IonModal,
  IonRouterOutlet,
  IonSplitPane,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Prompt, Redirect, Route } from "react-router-dom";
import firebase, {
  getMyTeam,
  getMyTeamPendingInvites,
  userProfileData,
} from "./lib/firebase";
import { Switch } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { StyledFirebaseAuth } from "react-firebaseui";
import TaskDetails from "./pages/tasks/TaskDetails";
import Header from "./components/header";
import { AppProvider } from "./context/app-context";
import TeamSettings from "./pages/team/TeamSettings";
import TeamPermissions from "./pages/team/TeamPermissions";
import TeamMember from "./pages/team/TeamMember";
import NewUserModal from "./components/new-user-modal";

import "./App.css";
import Team from "./pages/team/Team";
import { authContext } from "./context/auth-context/auth-context";
import CleaningModal from "./components/cleaning-modal";
import Cleaning from "./pages/cleaning/cleaning";
import Login from "./pages/login/login";
import DashBoardPage from "./pages/Dashboard/DashBoard-page";

interface authObject {
  id: string | null;
  email: string | null;
  displayName: string | null;
}

const uiConfig = {
  signInFlow: "popup",
  callbacks: {
    signInSuccessWithAuthResult: () => {
      return true;
    }
  },
  signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
};

const App: React.FC = () => {
  const [showCleaning, setShowCleaning] = useState(false);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [team, setTeam] = useState({});
  const [selectedDate, setSelectedDate] = useState('');
  const [currentUser,setCurrentUser] = useState({})
  const [teamsList, setTeamList] = useState([]);
  const [pendingTeam, setPendingTeam] = useState([]);
  const [showAuthModal, setShowAuthModal] = useState(true);
  const [showUserModal, setShowUserModal] = useState(false);
  const [authObject, setUserAuth] = useState<authObject>({
    displayName: "",
    email: "",
    id: "",
  });

  const handleShow = (t: boolean) => {
    setShowUserModal(t);
  };

  const handleUserDropdown = () => {
    setShowUserDropdown(!showUserDropdown);
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        setUserAuth({
          displayName: user.displayName,
          email: user.email,
          id: user.uid,
        });

        const userData: any = await userProfileData(user.uid);
        if (userData && userData.accountType && userData.accountType === 'Cleaner' && window.location.href.includes('listing')) {
          window.location.replace(window.location.href.replace('listings', 'schedule'));
        }
        if (userData === false) {
          setShowUserModal(true);
        } else {
          setCurrentUser(userData);
        }
        return setShowAuthModal(false);
      }
      if (!window.location.pathname.includes('login')) {
        window.location.href = window.location.origin + `/login`;
      }
      // window.location.href = window.location.origin + '/login';
      return setShowAuthModal(true);
    }, (error) => {
      window.location.reload();
    });
  }, []);

  useEffect(() => {
    const getData = async () => {
      const data: any = await getMyTeam(authObject.email);

      if (data[0]) {
        // Todo.  Check the data array and set the team where ownerId equals userId, so if they own a team, that is the first team to show up.
        setTeam(data[0]);
        setTeamList(data);

        //@ts-ignore
        const data2 = await getMyTeamPendingInvites(data[0].id);
        //@ts-ignore
        setPendingTeam([...data2]);
      }
    }

    if (authObject.email) {
      getData();
    }
  }, [authObject.email]);



  return (
    <AppProvider>
      <authContext.Provider
        value={{
          ...authObject,
          teamData: team,
          teamList: teamsList,
          handleTeamData: (obj) => setTeam(obj),
          selectTeamFromList: (team) => setTeam(team),
          pendingTeam,
          currentUser: currentUser,
          showCleaning,
          setShowCleaning: (val) => setShowCleaning(val),
          setPendingTeam: (obj) => setPendingTeam(obj),
          selectedDate,
          setSelectedDate: (val: string) => setSelectedDate(val)

        }}
      >
        <NewUserModal handleShow={handleShow} isOpen={showUserModal} />

        <IonApp >
          {
            showUserDropdown && (

              <div className='user-dropdownn'>
                <ClickOutside onClickOutside={() => setShowUserDropdown(false)}>

                <IonList>
                  <IonItem className='cursor-pointer' onClick={() => window.location.href = (window.location.origin + '/dashboard/settings') }>Settings</IonItem>
                  <IonItem className='cursor-pointer' onClick={() => firebase.auth().signOut()}>Log Out</IonItem>
                </IonList>
                </ClickOutside>

              </div>
            )
          }
{/* 
          <IonHeader className="ion-hide-md-up mob-header">
            <IonToolbar className='mob-header-bg'>
              <IonButtons slot="start">
                <IonMenuButton />
              </IonButtons>
              <IonTitle style={{ textTransform: "capitalize" }}>
               asas
              </IonTitle>
            </IonToolbar>
          </IonHeader> */}

          <IonReactRouter>
            {/*  Object.keys(currentUser || {}).length && */
              (window.innerWidth > 769 ? <Header handleUserDropdown={handleUserDropdown} /> : <Menu />) || ''
            }


            <IonSplitPane contentId="main">
              <IonRouterOutlet id="main">


                <Switch>

                  {/*Todo*/}
                <Route path="/dashboard/reports" component={DashBoardPage} exact />
                  <Route path="/dashboard/teams" component={Team} exact />
                  <Route
                    path="/dashboard/teams/member"
                    component={TeamMember}
                    exact
                  />
                  <Route
                    path="/dashboard/teams/settings"
                    component={TeamSettings}
                    exact
                  />
                  <Route
                    path="/dashboard/teams/permissions"
                    component={TeamPermissions}
                    exact
                  />
                  <Route
                    path="/dashboard/tasks/:taskID"
                    component={TaskDetails}
                    exact
                  />
                  <Route path="/dashboard/:page" component={Dashboard} exact />
                  <Route
                    path="/dashboard/:page/:subPage"
                    component={Dashboard}
                    exact
                  />
                  <Route
                    path="/dashboard/:page/:subPage/:params"
                    component={Dashboard}
                    exact
                  />
                  <Route
                    path="/login"
                    component={Login}
                    exact
                  />
                  <Route
                    path="/cleaning"
                    component={Cleaning}
                    exact
                  />
                  <Route
                    path='/dashboard'
                    component={DashBoardPage}
                  />
                  {/*Todo*/}
                  <Redirect from="/" to="/dashboard/schedule" exact />
                  {/*                   <Redirect from="/dashboard" to="/dashboard/listings" exact />
 */}                </Switch>
              </IonRouterOutlet>
            </IonSplitPane>

          </IonReactRouter>
          {/*           <IonModal cssClass='user-auth-modal' isOpen={showAuthModal} backdropDismiss={false}>
            <span>
              <img
                  width="100px"
                  className="d-block mx-auto my-5"
                  src="https://res.cloudinary.com/dltd4gs4a/image/upload/v1613712350/open-book_tveqmy.png"
              />
              <StyledFirebaseAuth
                className="signbox"
                uiConfig={uiConfig}
                firebaseAuth={firebase.auth()}
              />
            </span>
          </IonModal> */}

          <CleaningModal />
        </IonApp>
      </authContext.Provider>
    </AppProvider>
  );
};

export default App;
