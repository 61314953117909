import { IonIcon, IonLabel } from "@ionic/react";
import { arrowUpOutline } from "ionicons/icons";
import React, { useContext, useRef, useState } from "react";
import { DashBoardContext } from "../context/dashboard/dashboard.context";
import "./task-accordion.css";
export interface TaskAccordionProps {}


const TaskAccordion: React.FC<TaskAccordionProps> = ({ children }) => {
  const { tasks } = useContext(DashBoardContext);
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");

  const content = useRef<HTMLDivElement>(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "");
    if (content && content.current) {
      setHeightState(
        setActive === "active" ? "0px" : `${content.current!.scrollHeight}px`
      );
    }

    setRotateState(
      setActive === "active" ? "icon-rotate-active" : "icon-rotate"
    );
  }

  return (
    <div>
      <IonLabel style={{ color: "#118B8F" }} onClick={() => toggleAccordion()}>
        1 Cleaning{" "}
        <IonIcon
          className={
            setActive === "active" ? "icon-rotate-active" : "icon-rotate"
          }
          icon={arrowUpOutline}
        ></IonIcon>
      </IonLabel>
      <div
        ref={content}
        style={{
          minHeight: `${setHeight}`,
        }}
        className={`${
          setActive === "active"
            ? "accordion__content--active"
            : "accordion__content"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default TaskAccordion;
