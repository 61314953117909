export const getBrandImage = (brandName) => {

    switch (brandName) {

        case 'airbnb':
            return 'https://res.cloudinary.com/dltd4gs4a/image/upload/v1616758320/iconfinder_social_media_social_media_logo_airbnb_2993798_1_pesoi8.png'

        case 'booking.com':
            return 'https://res.cloudinary.com/dltd4gs4a/image/upload/v1616759054/bookingcom-icon_s666gq.png'
        case 'homeaway/vrbo':
            return 'https://res.cloudinary.com/dltd4gs4a/image/upload/v1616759235/pngfind.com-hennessy-logo-png-3025981_fys89p.png'

        case 'tripadvisor':
            return 'https://res.cloudinary.com/dltd4gs4a/image/upload/v1616759142/tripadvisor-icon-12007_eflsdw.png'
    }



}

export const create_UUID = () => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
}
