import { IonCol } from "@ionic/react";
import React, { useContext, useState } from "react";
import { taskDetailsContext } from "../context/task-details/task-details";
export interface AssigneeCardProps {
  data: {
    id: string;
    name: string;
    imageUrl: string;
  };
  idx: number;
}

const AssigneeCard: React.FC<AssigneeCardProps> = ({ data, idx }) => {
  //@ts-ignore
  const { displayName, email } = data;
  const { handleAssignee } = useContext(taskDetailsContext);
  const [selected, setSelected] = useState(false);
  const handleSelected = (obj, idx) => {
    if (selected) {
      setSelected(!selected);
    } else {
      setSelected(true);
    }
    handleAssignee(obj, selected, idx);
  };
  return (
    <IonCol size="12">
      <div
        className={`member-list ${selected && "member-list-active"}`}
        onClick={() => handleSelected(data, idx)}
      >
        <div className="member-content">
          <span>{displayName}</span>
          <span>{email}</span>
          
        </div>
      </div>
    </IonCol>
  );
};

export default AssigneeCard;
