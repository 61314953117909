import { IonContent, IonPage } from "@ionic/react";
import React, { useContext } from "react";
import { useParams } from "react-router";
import CardBox from "../../components/cardBox";
import { DashBoardContext } from "../../context/dashboard/dashboard.context";
import "./TaskDetails.css";
export interface TaskDetailsProps {}

const TaskDetails: React.FC<TaskDetailsProps> = () => {
  const { taskDetails } = useContext(DashBoardContext);
  return (
    <IonPage className="demo">
      <IonContent>
        <CardBox taskDetails={taskDetails} />
      </IonContent>{" "}
    </IonPage>
  );
};

export default TaskDetails;
