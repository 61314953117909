import { createContext } from "react";

export interface Step {
  id: string | null,
  additionalInfo: string | null,
  label: string | null,
  response: string | undefined,
  type: string | null,
}

export const templateContext = createContext({
  isButtonDisable: true,
  setIsButtonDisable: (p: boolean) => { },
  isSubmitReady: <boolean>false,
  setIsSubmitReady: () => { },
  buttonShow: false,
  handleButtonShow: (val: boolean) => { },
  steps: <Step[]> [],
  handleSteps: (val: Step) => { },
});
