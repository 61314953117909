
   export function getAddedTime(timeArray) {
        let difference = 0;
        timeArray.forEach((time, index) => {
            if (index % 2 == 0) {
                difference += (timeArray[index + 1] - timeArray[index]);
            }
        });
        const daysDifference = Math.floor(difference/1000/60/60/24);
        difference -= daysDifference*1000*60*60*24;
        const hoursDifference = Math.floor(difference/1000/60/60);
        difference -= hoursDifference*1000*60*60;
        const minutesDifference = Math.floor(difference/1000/60);
        difference -= minutesDifference*1000*60;
        const secondsDifference = Math.floor(difference/1000);
        return (hoursDifference + ' hours ' + minutesDifference + ' minutes ' + secondsDifference + 'seconds');
    }

    
export const checkDateRange = (startDate,endDate,dateToCheck) => {
    const from = new Date(startDate);
    const to = new Date(endDate);
    const check = new Date(dateToCheck);

  
    if (from <= check && to >= check) {
      return true;
    } else {
      return false;
    }
          
}

export function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("/");
}

export function getFormmatedTimeForText(timeArray, beforeTime) {
  const newArr = [...timeArray];

  let difference = 0;
  timeArray.forEach((time, index) => {
    if (index % 2 == 0) {
      difference += timeArray[index + 1] - timeArray[index];
    }
  });
  const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
  difference -= daysDifference * 1000 * 60 * 60 * 24;
  const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
  difference -= hoursDifference * 1000 * 60 * 60;
  const minutesDifference = Math.floor(difference / 1000 / 60);
  difference -= minutesDifference * 1000 * 60;
  const secondsDifference = Math.floor(difference / 1000);

  if(beforeTime) {
    const  { minitue,second,hour } = beforeTime;
    return {
      minitue: minutesDifference + minitue ,
      second: secondsDifference + second,
      hour: hoursDifference + hour,
    };
  }

  return {
    minitue: minutesDifference,
    second: secondsDifference,
    hour: hoursDifference,
  };
}

// Function for TableObject modification

export const processTheColumnData = (data,...accpetedKeys) => {
  let newArr = [];
  data.forEach((el) => {
      let obj = {}
      accpetedKeys.forEach(key => {
         obj = {
             ...obj,
             [key]: el[key]
         }
      })
      newArr.push(obj);
  })

  return newArr

}