import { IonIcon, IonList, IonText } from "@ionic/react";
import { closeCircleOutline } from "ionicons/icons";
import React from "react";
import { useMediaQuery } from "react-responsive";

import { processTheColumnData } from "../../utils/utils";
// Resusable component

import Table,{ TableRow, TableRowItem, TableRows, TableRowsItem } from './../Table/Table'
export interface teamTableProps {
    pendingTeamMembers: any[],
    tableHeading: string

}



const PendingTeamTables: React.FC<teamTableProps> = ({pendingTeamMembers,tableHeading}) => {
  const teamRowItemData: any[] = processTheColumnData(pendingTeamMembers,'email', 'role');
  const isTablet = useMediaQuery({
    maxWidth: 550
})
  return (
    <div className="team-pending-table">
    <IonText className="team-pending-table-heading">
        {tableHeading}   
    </IonText>
    <Table>
        <TableRows>
            <TableRow label='Name' size='4' />
            <TableRow cssClass='text-center' label='Role' size='4' />
        </TableRows>
        <IonList className="w-100 team-member-list">

        
        {
            pendingTeamMembers.length > 0 ? (
                      teamRowItemData.map(row => 
                <TableRowsItem>
                     {
                         Object.keys(row).map(key =>{ 
                        const content =  row[key].split('@')

                         return (
                           <TableRowItem size='4'>
                        {
                                 isTablet && row[key].includes('@') ? `${content[0]} ${content[1]}` : row[key]
                        }
                         </TableRowItem>   
                         )}
                    )
                     }
                     <TableRowItem size='4'>
                     <IonText className="team-member-details-text">
                                <IonIcon
                                  className="pending-delete-icon"
                                  icon={closeCircleOutline}
                                />
                              </IonText>
                     </TableRowItem>
                </TableRowsItem>
            )
            ) : (
                <IonText>No pending Team Data</IonText>
            )
        }

</IonList>
    </Table>
  </div>
  );
};


export default PendingTeamTables;
