import { createContext } from "react";

export const userContext = createContext({
  currentUser: {
    id: "",
    accountType: "",
    displayName: "",
    email: "",
    jobTitle: "",
    location: "",
  },
});
