import './team-update-modal.css'
import { IonButton, IonCol, IonContent, IonGrid, IonInput, IonItem, IonList, IonModal, IonRow, IonSelect, IonSelectOption } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { removePendingMember, removeTeamMembers, updatePendingTeamData, updateTeamMemberRole } from '../../lib/firebase';
import { authContext } from './../../context/auth-context/auth-context'

export interface TeamUpdateModalProps {
    data: any,
    isShow: boolean,
    setIsShow: any,
    setShowToast: any,
    setDeleteToast: any

}


const TeamUpdateModal: React.FC<TeamUpdateModalProps> = ({ data, isShow, setIsShow, setShowToast, setDeleteToast }) => {
    const { email, role, type, id, idx } = data;
    const { teamData } = useContext(authContext)

    const [newRole, setNewRole] = useState<any>(role)
    const [changedTeamUser, setChangedTeamUser] = useState<any>({})


    useEffect(() => {

        if (Object.keys(data).length === 0) return;

        setNewRole(role)

    }, [data])

    return (
        <IonModal isOpen={isShow} onDidDismiss={() => setIsShow(false)}>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <h4 className='ml-3'>Update Team Data</h4>
                            <IonList>
                                <div className='fix-margin'>
                                    <IonItem className='team-modal-input fixed-layout' lines='none'>
                                        <IonInput disabled value={email} />
                                    </IonItem>
                                    <IonItem lines='none' className='select-modal fixed-layout'>
                                        <IonSelect value={newRole} okText="Okay" cancelText="Dismiss" onIonChange={(e: any) => {
                                            setNewRole(e.detail.value);
                                            setChangedTeamUser({
                                                ...data,
                                                role: e.detail.value
                                            })

                                        }}>
                                            <IonSelectOption value="Cleaner">Cleaner</IonSelectOption>
                                            <IonSelectOption value="Admin">Admin</IonSelectOption>
                                            <IonSelectOption value="Owner">Owner</IonSelectOption>
                                        </IonSelect>
                                    </IonItem>
                                </div>

                                <div className='ml-2'>

                                    <IonButton onClick={() => {
                                        if (type === 'memberOnly') {
                                            updateTeamMemberRole({ ...data, role: newRole }, teamData.id)
                                        } else {
                                            updatePendingTeamData(data, newRole);

                                        }
                                        setIsShow(false)
                                        setShowToast()

                                    }
                                    }>Update</IonButton>
                                    <IonButton onClick={() => {
                                        if (type === 'memberOnly') {
                                            removeTeamMembers(id, teamData.id)
                                        } else {
                                            removePendingMember(email);

                                        }
                                        setIsShow(false)
                                        setDeleteToast()
                                    }}>Delete</IonButton>
                                </div>

                            </IonList>
                        </IonCol>


                    </IonRow>
                </IonGrid>
            </IonContent>

        </IonModal>
    );
}

export default TeamUpdateModal;