import { accessibilityOutline } from "ionicons/icons";

export const memberSettings = [
  { name: "Tasks",value: "tasks", checked: false },
  { name: "Guest management", value: "guests", checked: false },
  { name: "Listing management",value: "listings", checked: false },
  { name: "Finances", value: "finances",checked: false },
  { name: "Team management", value: "team", checked: false },
];

export const settings = [
  {
    name: "Tasks",
    value: "tasks",
    desc: "View assigned tasks, claim available tasks, and edit task status",
    detailedDesc: [
      "View tasks assigned to them and claim available tasks",
      "Mark tasks as started, in progress, and done",
      "View reservation details for tasks assigned to them",
      "Submit reports of broken or found items",
    ],
    icon: accessibilityOutline,
    badge: "New",
  },
  {
    name: "Guest management",
    value: "guests",
    desc:
      "Edit reservations and calendar. Pre-approve, contact, review guests, and send offers to guests.",
    detailedDesc: [
      "Edit reservations and block the calendar",
      "Contact customer service",
      "Message guests and send special offers or pre-approvals",
      "Write and respond to guest reviews",
      "Create and assign tasks",
    ],
    icon: accessibilityOutline,
    badge: "",
  },
  {
    name: "Listing management",
    value: "listings",
    desc:
      "Create listings, set pricing, edit listings, edit availability, create and edit calendar rules.",
    icon: accessibilityOutline,
    badge: "Updated",
    detailedDesc: [
      "Create listings",
      "Edit listing content and status",
      "Deactivate listings",
      "Edit pricing and availability settings",
      "Create and edit rule-sets",
      "Accept and decline invites from owners to access and manage their listings",
      "Remove team access to an owner's listing",
      "Review transaction history,earnings, pricing, and availability for all listings",
      "Create and assign tasks",
    ],
  },
  {
    name: "Finances",
    value: "finances",
    desc: "Review and create reports for earnings and transaction history.",
    icon: accessibilityOutline,
    badge: "",
    detailedDesc: [
      "Review and create reports for earnings and transaction history",
      "Create and assign tasks",
    ],
  },
  {
    name: "Team management",
    value: "team",
    desc: "Edit team member permissions and manage invitations.",
    icon: accessibilityOutline,
    badge: "",
    detailedDesc: [
      "Add and remove team members",
      "Edit team member permissions",
      "Review team activity",
      "Create and assign tasks",
    ],
  },
];
