import {
  IonButtons,
  IonCard,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPage,
  IonProgressBar,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import ClickOutside from "@leafyjava/react-click-away";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./Dashboard-page.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import DashboardCard from "./dashboard-card";
import {
  arrowBackCircleOutline,
  arrowForwardCircleOutline,
  calendarOutline,
  chevronDownOutline,
  chevronForwardCircleOutline,
} from "ionicons/icons";
import { authContext } from "../../context/auth-context/auth-context";
import {
  firestore,
  getAllTeamProperties,
  getCleanerWorkingTime,
  getMyTeam,
  getPropertyById,
} from "../../lib/firebase";
import { checkDateRange, getAddedTime } from "../../utils/utils";
import { Accordion, Button, Card } from "react-bootstrap";
import {
  getConvertedCleanerObject,
  getConvertedPropertiesObject,
} from "./Dashboard.utils";
import useForceUpdate from "use-force-update";

export interface DashBoardPageProps {}

const arr = [1, 2, 3, 4];

const getFormattedDate = (date) => {
  return new Date(date).toLocaleDateString();
};

function getFormmatedTimeForText(timeArray, beforeTime?) {
  const newArr: any = [...timeArray];

  let difference = 0;
  timeArray.forEach((time, index) => {
    if (index % 2 == 0) {
      difference += timeArray[index + 1] - timeArray[index];
    }
  });
  const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
  difference -= daysDifference * 1000 * 60 * 60 * 24;
  const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
  difference -= hoursDifference * 1000 * 60 * 60;
  const minutesDifference = Math.floor(difference / 1000 / 60);
  difference -= minutesDifference * 1000 * 60;
  const secondsDifference = Math.floor(difference / 1000);

  if (beforeTime) {
    const { minitue, second, hour } = beforeTime;
    return {
      minitue: minutesDifference + minitue,
      second: secondsDifference + second,
      hour: hoursDifference + hour,
    };
  }

  return {
    minitue: minutesDifference,
    second: secondsDifference,
    hour: hoursDifference,
  };
}

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("/");
}

const Name = ({ userId }) => {
  const [name, setName] = useState("");

  useEffect(() => {
    const getCleanerUserName = async (id) => {
      if (!id) return;

      const docRef = await firestore.doc(`users/${id}`).get();
      const data: any = await docRef.data();
      setName(data.displayName);
    };

    getCleanerUserName(userId);
  }, [userId]);

  return <h6 className="ion-no-margin">{name ? name : "Loading..."}</h6>;
};

const Property = ({ propertyId }) => {
  const [{ thumbnail, unit }, setProperty] = useState<any>({});

  useEffect(() => {
    const getPropertyDetails = async (id) => {
      if (!id) return;

      const docRef = await firestore.doc(`properties/${id}`).get();
      const data: any = await docRef.data();

      setProperty({ unit: data.unit, thumbnail: data.thumbnail });
    };

    getPropertyDetails(propertyId);
  }, [propertyId]);

  return (
    <>
      <IonCol>
        <img
          width="75px"
          height="75px"
          style={{ imageRendering: "pixelated", margin: "10px 0px" }}
          src={thumbnail}
        />
      </IonCol>
      <IonCol>
        <IonText>{unit}</IonText>
      </IonCol>
    </>
  );
};

function isFilteredOut(filters, date, currentUser) {
  // date = currentSchedule
  if (
    filters.cleaners.length &&
    !filters.cleaners.some((cleaner) => date.assignedTo.includes(cleaner.id))
  ) {
    return true;
  }
  if (
    filters.properties.length &&
    !filters.properties.some((property) => date.propertyId === property.id)
  ) {
    return true;
  }

  return false;
}

const DashBoardPage: React.FC<DashBoardPageProps> = () => {
  const currentDate = new Date();
  const forceUpdate = useForceUpdate();

  const onWeekBackFromNow = new Date(
    new Date().setDate(currentDate.getDate() - 6)
  );
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showCalendar, setShowCalendar] = useState(false);
  const [schedules, setSchedules] = useState<any[]>([]);
  const [filteredScheduleCleaner, setFilteredScheduleCleaner] = useState<any>({});
  const [filteredScheduleProperty, setFilteredScheduleProperty] = useState<any>({});
  const [currentSegmentValue, setCurrentSegmentValue] = useState<
    string | undefined
  >("cleaners");
  const [teamData, setTeamData] = useState<any>({
    teamCleaner: [],
    teamProperties: [],
    showTeamCleaner: false,
    showTeamProperties: false,
    team: [],
  });

  const {
    showTeamCleaner,
    showTeamProperties,
    teamProperties,
    teamCleaner,
    team,
  } = teamData;

  const { currentUser, teamData: teamCredentials } = useContext(authContext);

  const [filteredValue, setFilteredValue] = useState<any>({
    properties: [],
    cleaners: [],
    dateRange: {
      startDate: onWeekBackFromNow,
      endDate: currentDate,
      key: "selection",
    },
  });
  const { properties, cleaners, dateRange } = filteredValue;

  const getFilteredTeamSchedule = async (
    schedule,
    dateRange = filteredValue.dateRange,
    team?,
    teamProperties?,
    segment?
  ) => {
    const currSegVal = segment || currentSegmentValue;
    if (currSegVal === "cleaners") {
      setFilteredScheduleCleaner(
        getConvertedCleanerObject(schedule, dateRange, team, teamProperties)
      );
    } else {
      setFilteredScheduleProperty(
        getConvertedPropertiesObject(schedule, dateRange, team, teamProperties)
      );
    }
  };
  const handleSelectedDateRange = (ranges) => {
    setFilteredValue({ ...filteredValue, dateRange: { ...ranges.selection } });
    getFilteredTeamSchedule(schedules, ranges.selection, team, teamProperties);
  };

  const next = () => {
    if (arr.length <= currentSlide + 1) {
      setCurrentSlide(0);
      return;
    }

    setCurrentSlide(currentSlide + 1);
  };
  const pre = () => {
    if (currentSlide < 1) return;

    setCurrentSlide(currentSlide - 1);
  };

  useEffect(() => {
    if (!Object.keys(currentUser).length) return;

    (async function () {
      const data = await getMyTeam(currentUser.email);
      const team = await getMyTeam(currentUser.email);
      const teamProperties = await getAllTeamProperties(team[0]);
      const teamCleaner = team[0].members.filter(
        (member) => member.role === "Cleaner"
      );
      const schedules = await getCleanerWorkingTime(data[0]);

      setTeamData({
        ...teamData,
        teamCleaner,
        teamProperties: teamProperties,
        team: team[0],
      });
      setSchedules(schedules);
      getFilteredTeamSchedule(
        schedules,
        filteredValue.dateRange,
        team[0],
        teamProperties
      );
    })();

    return () => {};
  }, [currentUser]);

  const getPropertyDetails = async (id) => {
    const propertyRef = await firestore.doc(`properties/${id}`).get();
    const data = await propertyRef.data();
    return;
  };

  return (
    <IonPage>
      
      <IonHeader className="ion-hide-md-up mob-header">
            <IonToolbar className='mob-header-bg'>
              <IonButtons slot="start">
                <IonMenuButton />
              </IonButtons>
              <IonTitle className='header-title' style={{ textTransform: "capitalize", }}>
               Reports
              </IonTitle>
            </IonToolbar>
          </IonHeader>
      <IonContent className='report-content'>
        <IonGrid>
          <IonRow>
            <IonCol size="12" offset="0">
              <IonGrid className="ion-no-padding report-grid">
                  <IonRow className='space-top-report'>
                    <IonCol>
                      <div className="d-flex responsive-chip">
                      <div className=" space-report-chip">
                      <IonChip
                        className="report-chip"
/*                         onClick={() => setShowCalendar(!showCalendar)}
 */                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <IonLabel className="dates-label">
                           All Team Members
                          </IonLabel>
                        </div>
                        <IonIcon icon={chevronDownOutline} />
                      </IonChip>
{/* 
                      {showCalendar && (
                        <ClickOutside
                          onClickOutside={() => setShowCalendar(false)}
                        >
                          <div>
                            <DateRange
                              ranges={[filteredValue.dateRange]}
                              onChange={handleSelectedDateRange}
                            />
                          </div>
                        </ClickOutside>
                      )} */}
                      </div>
                      <div className=" space-report-chip">
                      <IonChip
                        className="report-chip"
                        onClick={() => setShowCalendar(!showCalendar)}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <IonIcon icon={calendarOutline} />
                          <IonLabel className="dates-label">
                            Dates (
                            {getFormattedDate(
                              filteredValue.dateRange.startDate
                            )}{" "}
                            -{" "}
                            {getFormattedDate(filteredValue.dateRange.endDate)}){" "}
                          </IonLabel>
                        </div>
                        <IonIcon icon={chevronDownOutline} />
                      </IonChip>

                      {showCalendar && (
                        <ClickOutside
                          onClickOutside={() => setShowCalendar(false)}
                        >
                          <div>
                            <DateRange
                              ranges={[filteredValue.dateRange]}
                              onChange={handleSelectedDateRange}
                            />
                          </div>
                        </ClickOutside>
                      )}
                      </div>
                      </div>
                     
                    </IonCol>
                  </IonRow>

                  


                <div>
                  <IonSegment
                    value={currentSegmentValue}
                    className='segment-main'
                    onIonChange={(e) => {
                      setCurrentSegmentValue(e.detail.value)
                      getFilteredTeamSchedule(
                        schedules,
                        dateRange.selection,
                        team,
                        teamProperties,
                        e.detail.value
                      );



                    }}
                  >
                    <IonSegmentButton className='segment-report' value="cleaners">
                       cleaners
                    </IonSegmentButton>
                    <IonSegmentButton className='segment-report' value="properties">
                       Properties
                    </IonSegmentButton>
                  </IonSegment>


                  <IonItem lines='none' className="w-100 report-table-header">
                    <IonRow className="mt-3 ml-4 w-100">
                      <IonCol>
                        <IonText className='report-table-header-text'>
                           Name
                        </IonText>
                        
                       </IonCol>

                      <IonCol className='ion-text-end'>

                      <IonText className='report-table-header-text'>

                        Worked Time
                        </IonText>
                      </IonCol>
                    </IonRow>
                  </IonItem>

                  {currentSegmentValue === "cleaners" && (
                    <Accordion>
                      {Object.keys(filteredScheduleCleaner).length > 0
                        ? Object.keys(filteredScheduleCleaner).map((el,id) => {
                            return Object.keys(filteredScheduleCleaner[el]).map(
                              (el1: any, idx: number) => {
                                const overViewData =
                                  filteredScheduleCleaner[el][el1];
                                return (

                                  <div  style={{
                                    marginBottom: '25px',
                                    background: 'white'
                                  }}>
                                    <Accordion.Toggle
                          eventKey={`section-index-${idx}`}
                          className='w-100 p-0'

                                    >
                                    <Card.Header className='d-flex justify-content-between report-header'>
                                      
                                    <span className='d-block  report-main-content-table' style={{
                                      marginLeft: '16px'
                                    }}>
                                     {overViewData.name}{" "}
                            
                                      </span>

                                      <span className='d-block  report-main-content-table' style={{
                                        marginRight: '16px'
                                      }}>
                                          {overViewData.totalWorkedTime.hour}h{" "}
                                          {overViewData.totalWorkedTime.minitue}
                                          m{" "}
                                          {overViewData.totalWorkedTime.second}s
                                      </span>
                                    </Card.Header>
                                    </Accordion.Toggle>

                                    <Accordion.Collapse className='collapse-adjust' eventKey={`section-index-${idx}`}>
                                    <Card className='report-card border-0'>
                                      <div className=''>
                                        <ul className='p-0 report-inner-table'>

                                      {Object.keys(
                                        filteredScheduleCleaner[el][el1].properties
                                      ).map((el2) => {
                                        const { propertyName, workedTime } =
                                          filteredScheduleCleaner[el][el1]
                                            .properties[el2];
                                        return (
                                          <li className='report-list-item'>
                                            <IonGrid>

                                            <IonRow>
                                              <IonCol size='4'>
                                                <IonText className='report-table-label '>Property Name</IonText>
                                                <br />
                                                <IonText className='report-table-content '>{propertyName}</IonText>
                                                
                                              </IonCol>
                                              <IonCol size='4'  className='text-center'>
                                              <IonText  className='report-table-label margin-control-date'>Date</IonText>
                                              <br />

                                                <IonText className='report-table-content '>9/09/20</IonText>
                                                
                                                </IonCol>
                                              <IonCol size='4' className='text-end'>
                                              <IonText  className='report-table-label margin-control-time'>Time</IonText>
                                              <br />
                                              <div className='d-flex align-items-center justify-content-end'>
                                                     <IonText className='report-table-content report-table-content--margin font-fix'>
                                                     { workedTime.hour}hr {workedTime.minitue}m {workedTime.second}s
                                   
                                                </IonText>
                                                <span className='edit-btn' >
                                                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.49945 3.94181L3.07042 10.0577L0.722168 17.4614L7.47221 15.0469L12.9012 9.09209L8.49945 3.94181ZM15.3964 3.45848L13.4888 1.36615C13.322 1.16346 13.1183 1.00142 12.8908 0.890506C12.6633 0.779597 12.4172 0.72229 12.1682 0.72229C11.9193 0.72229 11.6731 0.779597 11.4457 0.890506C11.2182 1.00142 11.0145 1.16346 10.8477 1.36615L9.23292 3.13731L13.6347 8.12648L15.3954 6.19525C15.5766 6.00899 15.7219 5.78482 15.8228 5.53596C15.9237 5.28711 15.9782 5.01862 15.983 4.74631C15.941 4.25172 15.7317 3.79229 15.3964 3.45848Z" fill="#1E9294"/>
</svg>

                                                </span>
                                              </div>

                                           
                                                

                                                </IonCol>
                                            </IonRow>
                                            </IonGrid>
                                          </li>
                                        );
                                      })}
                                          </ul>

                                      </div>

                                    </Card>
                                    </Accordion.Collapse>
                                    </div>
                                );
                              }
                            );
                          })
                        : "nothinh"}
                    </Accordion>
                  )}

                  {currentSegmentValue === "properties" && (
                    <Accordion>
                      {Object.keys(filteredScheduleProperty).length
                        ? Object.keys(filteredScheduleProperty).map((el) => {
                            return Object.keys(filteredScheduleProperty[el]).map(
                              (el1: any, idx: number) => {
                                const overViewData =
                                  filteredScheduleProperty[el][el1];
                                return (

                                  
                                  <div  style={{
                                    marginBottom: '25px',
                                    background: 'white'
                                  }}>
                                    <Accordion.Toggle
                          eventKey={`section-index-${idx}`}
                          className='w-100 p-0'

                                    >
                                    <Card.Header className='d-flex justify-content-between report-header'>
                                      
                                    <span className='d-block  report-main-content-table' style={{
                                      marginLeft: '16px'
                                    }}>
                                     {overViewData.name}{" "}
                            
                                      </span>

                                      <span className='d-block  report-main-content-table' style={{
                                        marginRight: '16px'
                                      }}>
                                          {overViewData.totalWorkedTime.hour}h{" "}
                                          {overViewData.totalWorkedTime.minitue}
                                          m{" "}
                                          {overViewData.totalWorkedTime.second}s
                                      </span>
                                    </Card.Header>
                                    </Accordion.Toggle>

                                    <Accordion.Collapse className='collapse-adjust' eventKey={`section-index-${idx}`}>
                                    <Card className='report-card border-0'>
                                      <div >
                                        <ul className='report-inner-table p-0'> 

                                      {Object.keys(
                                        filteredScheduleProperty[el][el1].cleaners
                                      ).map((el2) => {
                                        const { cleanerName, workedTime } =
                                        filteredScheduleProperty[el][el1]
                                            .cleaners[el2];
                                        return (
                                          <li className='report-list-item'>
                                            <IonGrid>

                                            <IonRow>
                                              <IonCol size='4'>
                                                <IonText className='report-table-label '>Property Name</IonText>
                                                <br />
                                                <IonText className='report-table-content '>{cleanerName}</IonText>
                                                
                                              </IonCol>
                                              <IonCol size='4'  className='text-center'>
                                              <IonText  className='report-table-label margin-control-date'>Date</IonText>
                                              <br />

                                                <IonText className='report-table-content '>9/09/20</IonText>
                                                
                                                </IonCol>
                                              <IonCol size='4' className='text-end'>
                                              <IonText  className='report-table-label margin-control-time'>Time</IonText>
                                              <br />
                                              <div className='d-flex align-items-center justify-content-end'> 
                                                     <IonText className='report-table-content report-table-content--margin font-fix'>
                                                 { workedTime.hour}hr {workedTime.minitue}m {workedTime.second}s
                                   
                                                </IonText>
                                                <span className='edit-btn' >
                                                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.49945 3.94181L3.07042 10.0577L0.722168 17.4614L7.47221 15.0469L12.9012 9.09209L8.49945 3.94181ZM15.3964 3.45848L13.4888 1.36615C13.322 1.16346 13.1183 1.00142 12.8908 0.890506C12.6633 0.779597 12.4172 0.72229 12.1682 0.72229C11.9193 0.72229 11.6731 0.779597 11.4457 0.890506C11.2182 1.00142 11.0145 1.16346 10.8477 1.36615L9.23292 3.13731L13.6347 8.12648L15.3954 6.19525C15.5766 6.00899 15.7219 5.78482 15.8228 5.53596C15.9237 5.28711 15.9782 5.01862 15.983 4.74631C15.941 4.25172 15.7317 3.79229 15.3964 3.45848Z" fill="#1E9294"/>
</svg>

                                                </span>
                                              </div>

                                           
                                                

                                                </IonCol>
                                            </IonRow>
                                            </IonGrid>
                                          </li>
                                        );
                                      })}
                                          </ul>

                                      </div>

                                    </Card>
                                    </Accordion.Collapse>
                                    </div>

                                );

                              }
                            );
                          })
                        : "nothinh"}
                    </Accordion>
                  )}
                </div>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default DashBoardPage;
