import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "./Dashboard.css";
import Tasks from "./tasks/Tasks";
import Listings from "./listings/Listings";
import Calendar from "./Calendar";
import Settings from "./Settings";
import Template from "./tasks/Template";
import AddEditListing from "./listings/AddEditListing";
import TasksHeader from "../components/TasksHeader";
import AddEditTasks from "./tasks/AddEditTasks";
import { DashBoardContext } from "./../context/dashboard/dashboard.context";
import Team from "./team/Team";
import TeamSettings from "./team/TeamSettings";
import TeamMember from "./team/TeamMember";
import TeamPermissions from './team/TeamPermissions';
import DashBoardPage from "./Dashboard/DashBoard-page";
import Reservation from "./reservations/reservations";
import ViewListing from "./listings/ViewListing";
import ActivityPage from './activity/Activity';
import Schedule from "./schedule/schedule";
import TaskLists from "../components/TaskLists";

function getPageContent(page: string, subPage?: any, params?: any) {

  if (subPage) page += `/${subPage}`;
  switch (page) {
    case "dashboard": {
      return (
        <div>
          <DashBoardPage />
        </div>
      );
    }
    case "reservations":
      return <Reservation />;
    case "listings/view":
      return (
        <ViewListing name="Listings" action={subPage} listingId={params} />
      );
    case "listings":
      return <Listings name="Listings" />;
    case "listings/view":
      return (
        <ViewListing name="Listings" action={subPage} listingId={params} />
      );
    case "listings/add":
      return <AddEditListing name="Listings" action={subPage} />;
    case "listings/edit":
      return (
        <AddEditListing name="Listings" action={subPage} listingId={params} />
      );
    case "template/create":
      return (
        <div>
          <Template />
        </div>
      );
    case "calendar":
      return (
        <div>
          <Calendar />
        </div>
      );
    case "lists":
      return <TaskLists />;
    case "lists/edit":
      return <TaskLists isViewTaskList={false} action={subPage} id={params} />;

    case "teams":
      return <Team />;
    case "teams/settings":
      return <TeamSettings teamId={params} />;
    case "teams/member":
      return <TeamMember memberId={params} />;
    case "teams/permissions":
      return <TeamPermissions />;
    case "tasks":
      return (
        <div>
          <Tasks />
        </div>
      );
    case "task/add":
      return (
        <div>
          <AddEditTasks />
        </div>
      );

    case "schedule":
      return (
        <div>
          <Schedule />
        </div>
      )
    case "activity":
      return <ActivityPage />;
    case "settings":
      return (
        <div>
          <Settings />
        </div>
      );
    default:
      return (
        <div>
          <TasksHeader name="Tasks" />
        </div>
      );
  }
}

const getTaskData = () => {
  const data = [
    {
      taskName: "task1",
      taskDescription: "hello",
      taskType: "Cleaning",
      taskAssignee: "asaduzzaman",
      listingID: "oyCfjCuuGTPLwwNvkQDj",
      deadLine: "deadLine",
      start: "start",
    },
  ];

  return data;
};

const getPropertyData = () => {
  const data = {
    oyCfjCuuGTPLwwNvkQDj: {
      id: "oyCfjCuuGTPLwwNvkQDj",
      createdBy: "ko9BhZhslsfYu3ekAtjog6vLGW92",
      airbnbCalendarURL: "https://www.airbnb.com/calendar/ical/4337457….",
      amenities: ",Kitchen Basics,Fire extinguisher,Smoke alarm,Ca….",
      airbnbListingURL: "https://www.airbnb.com/rooms/43374572?s=67&uniq…..",
      maxOccupancy: "24",
      title: "Large Luxury Townhouse | Huge Pool/Spa | 24 Guest",
      images: [
        "https://res.cloudinary.com/dltd4gs4a/image/upload/v1613535611/home_od6whg.jpg",
        "https://firebasestorage.googleapis.com/v0/b/room-filler-engine.apps…..",
      ],
      vrboListingURL: "https://www.vrbo.com/1977510?unitId=2542027&n….",
      rules: "Privacy on this end unit calls for a backyard barbeque or ord….",
      bedroomCount: "6",
      link: "townhouse",
      vrboCalendarURL: "http://www.vrbo.com/icalendar/575e56cfb2d64de191a376….",
      bathroomCount: "4.5",
      description: "NEW Spacious Townhome w/ Views of Zion Natio….",
      owner: {
        name: "Asaduzzaman Himel",
        description: "Nothing",
        phone: "01766968687",
        email: "asaduzzamanhimel12@gmail.com",
      },
      published: true,
      address: {
        state: "Utah",
        city: "Hurricane",
        country: "USA",
        street: "2123 W 440 N",
        stringFormat: "2123 W 440 N, Hurricane, Utah 84737, USA",
      },
    },
  };

  return data;
};

const Dashboard: React.FC = () => {


  const [model, setModel] = useState<boolean>(false);
  const { page, subPage, params } = useParams<{
    page: string;
    subPage: string;
    params: any;
  }>();


  const [cto, setCto] = useState<String>('');

  const [tasks, setTasks] = useState<any>([]);
  const [properties, setProperties] = useState<any>([]);
  const [taskDetails, setTaskDetails] = useState({});

  /* FUNCTION for updateing the States */

  const addTasks = (task) => {
    setTasks([...tasks, ...task]);
  };
  const addTaskDetails = (task: object) => {
    setTaskDetails(task);
  };

  /* This Effect is use for fetching All the task from Firebase */
  useEffect(() => {
    const asyncData = async () => {
      const [taskData, PropertyData] = await Promise.all([
        getTaskData(),
        getPropertyData(),
      ]);

      addTasks([...taskData]);
      setProperties(PropertyData);
    };

    asyncData();
  }, []);



  return (
    
    <>
      <DashBoardContext.Provider
        value={{
          tasks,
          addTasks,
          properties,
          taskDetails,
          addTaskDetails: (task) => addTaskDetails(task),
          cto,
          setCto: (val: any) => setCto(val)
          
        }}
      >
        <IonPage>
          <IonHeader className="ion-hide-md-up">
            <IonToolbar>
              <IonButtons slot="start">
                <IonMenuButton />
              </IonButtons>
              <IonTitle style={{ textTransform: "capitalize" }}>
                {page}
              </IonTitle>
            </IonToolbar>
          </IonHeader>

          <IonContent fullscreen>
            <IonHeader collapse="condense">
              <IonToolbar>
                <IonTitle size="large">{page}</IonTitle>
              </IonToolbar>
            </IonHeader>

            {getPageContent(page, subPage, params)}
          </IonContent>
        </IonPage>
      </DashBoardContext.Provider>
    </>
  );
};

export default Dashboard;
