import React, { useContext, useEffect, useState } from "react";
import {
  IonText,
  IonButton,
  IonList,
  IonItem,
  IonModal,
  IonContent,
  IonRow,
  IonCol,
  IonSegment,
  IonSegmentButton,
  IonGrid,
  IonToast,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonTitle,
} from "@ionic/react";
import "./Team.css";
import { AppContext } from "../../context/app-context";
import IntroModal from "./IntroModal";
import SuccessModal from "../../components/successModal";
import { authContext } from "../../context/auth-context/auth-context";
import InviteMember from "./InviteMember";
 
import ClickOutside from "@leafyjava/react-click-away";
import {
  firestore,
  getAllProperties,
  getMyTeamPendingInvites,
  sendMailWhenPropertyAssignedToAdmin,
} from "./../../lib/firebase";
import TeamUpdateModal from "./team-update-modal";
import TeamTables from "../../components/TeamTable/TeamTable";
import PendingTeamTables from "../../components/PendingTable/PendingTable";
import { useMediaQuery } from "react-responsive";


const PropertyItem = ({ data, handleAdd, handleDelete, member }) => {
  const [selected, setSelected] = useState(() => {
    return member
      ? member.some(
          (el) =>
            el.propertiesAssigned &&
            el.propertiesAssigned.some((el2) => data.id === el2)
        )
      : false;
  });
  return (
    <IonItem
      onClick={() => {
        if (!selected) {
          handleAdd(data);
        } else {
          handleDelete(data);
        }
        setSelected(!selected);
      }}
    >
      <input
        type="radio"
        onChange={(e) => {
          console.log(e);
        }}
        checked={selected}
      ></input>
      <IonItem key={1} className="cursor-pointer">
        <img
          className="mr-2"
          width="45px"
          src="https://res.cloudinary.com/dltd4gs4a/image/upload/v1613535611/home_od6whg.jpg"
          alt=""
        />
        <div style={{ fontSize: "14px" }}>
          {data.title}
          <p>
            <span className="pr-2">Unit: {data.unit}</span> |
            <span className="px-2">Access Code: {data.accessCode}</span>
          </p>
        </div>
      </IonItem>
    </IonItem>
  );
};

const Team: React.FC = ({}) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteToast, setShowDeleteToast] = useState(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [isShow, setIsShow] = useState(false);
  const [pendingTeamArr, setPendingTeamArr] = useState<any>([]);
  const [showTeamDropdown, setShowTeamDropdown] = useState<boolean>(false);
  const [selectedSegment, setSelectedSegment] = useState<any>("teamMembers");
  const [selectedTeamMember, setSelectedTeamMember] = useState<any>({});
  const [properties, setProperties] = useState<any>([]);
  const [selectedProperty, setSelectedProperty] = useState<any>([]);
  const { pendingTeam, teamData } =  useContext(authContext);
  const [selectedIdx, setSelectedIdx] = useState<any>();
  const [currentSelectedMember, setCurrentSelectedMember] = useState<any>([]);

  const [introModal, setIntroModal] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [propertyModal, setPropertyModal] = useState<boolean>(false);
  const globalState = useContext(AppContext);
  const [teamFreshData, setTeamFreshData] = useState<any>([]);
  const [pendingTeamFreshData, setPendingTeamFreshData] = useState<any>([]);
  const [teamsData,setTeamsData] = useState({
    team: {},
    teamMember: [],
    pendingTeamMembers: [],
    teamOwners: [],
    pendingTeamOwners: [],
  });
  const isTablet = useMediaQuery({
    	minWidth: 550
  })

  const { teamMember,pendingTeamMembers,teamOwners,pendingTeamOwners } = teamsData;

  const handleAdd = async (el) => {
    const team = teamData;
    sendMailWhenPropertyAssignedToAdmin(team.members[selectedIdx].email);
    if (selectedProperty.length > 0) {
      const data = selectedProperty.map((el) => el.id);
      team.members[selectedIdx]["propertiesAssigned"] = [...data, el.id];
      setSelectedProperty([...selectedProperty, el]);
      const docRef = firestore.doc(`teams/${teamData.id}`);
      await docRef.set(team);
    } else {
      const data = [el.id];
      team.members[selectedIdx]["propertiesAssigned"] = data;
      setSelectedProperty([...selectedProperty, el]);
      const docRef = firestore.doc(`teams/${teamData.id}`);
      await docRef.set(team);
    }
  };
  const handleDelete = async (obj) => {
    const team = teamData;
    const property = selectedProperty.filter((el) => el.id !== obj.id);
    const data1 = property.map((el) => el.id);
    team.members[selectedIdx]["propertiesAssigned"] = data1;
    const docRef = firestore.doc(`teams/${teamData.id}`);
    await docRef.set(team);
    const data = selectedProperty.filter((el) => el.id !== obj.id);
    setSelectedProperty(data);
  };


  // Have to review This code to Make it more readable and standard

  useEffect(() => {
    if (!teamData) return;

    const getData = async () => {
      const data = firestore.doc(`teams/${teamData.id}`);
      data.onSnapshot(async (snap) => {
        let ownerArray: any = [];
        let teamArray: any = [];
        let teamTempData: any = snap.data();
        if (snap.exists) {
          teamTempData.members.forEach((member) => {
            if (member.role.includes("Owner")) {
              ownerArray.push(member);
            } else {
              teamArray.push(member);
            }
          });
        }

        const data = await getMyTeamPendingInvites(teamData.id);
        const pendingOwners: any = [];
        const pendingMembers: any = [];
        //@ts-ignore
        data.forEach((pendingTeamMember:any) => {
          if(pendingTeamMember[teamTempData.id].role.includes('Owner')) {
            pendingOwners.push(pendingTeamMember[teamData.id]);
          } else {
            pendingMembers.push(pendingTeamMember[teamData.id]);

          }

        });
        

        setTeamsData({
          ...teamsData,
          teamMember: teamArray.sort((a,b) => a.role.length - b.role.length ),
          teamOwners:ownerArray,
          team: teamTempData,
          pendingTeamMembers: pendingMembers,
          pendingTeamOwners: pendingOwners
          
        })

        setTeamFreshData(snap.data());
      });
    };

    getData();
  }, [teamData,pendingTeam]);

  useEffect(() => {
    if (!teamFreshData && !teamData && !teamData.id && pendingTeam!) return;

    const getData = async () => {
      const data = firestore.collection(`pendingTeamInvites`);

      data.onSnapshot((snap) => {
        let pending: any = [];
        snap.docs
          .map((el) => el.data())
          .forEach(async (el) => {
            pending.push(el[teamData.id]);
          });
        const data1 = pending.filter((el) => el !== undefined);
        setPendingTeamFreshData(data1);
      });
    };
    if (teamData && teamFreshData) {
      getData();
    }
  }, [pendingTeam, teamData, teamFreshData]);

  useEffect(() => {
    globalState.dispatch({
      type: "GET_ALL_TEAMS",
      successAction: globalState.dispatch,
    });
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("shownIntroModal")) {
      setIntroModal(true);
    }
    localStorage.setItem("shownIntroModal", Date.now() + "");
  }, []);

  useEffect(() => {
    if (globalState) {
      globalState.state.isTeamUpdated && setSuccessModal(true);
    }
  }, [globalState.state.isTeamUpdated]);

  useEffect(() => {
    const getData = async () => {
      const data = await getAllProperties();
      setProperties(data);
    };
    getData();
  }, []);


  const handleCloseModal = () => {
    setSuccessModal(false);
  };

  const handleClickOutside = () => {
    setShowTeamDropdown(false);
  };




  return (
    //@ts-ignore
    <IonContent className="bg-main-content">
      <IonHeader className="ion-hide-md-up mob-header">
        <IonToolbar className="mob-header-bg">
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle
            className="header-title"
            style={{ textTransform: "capitalize" }}
          >
            Teams
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      {/* Property Modal Start */}
      <IonModal
        isOpen={propertyModal}
        onDidDismiss={() => setPropertyModal(false)}
      >
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol>Please select Properties</IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonList>
                  {properties.map((el, index) => (
                    <PropertyItem
                      key={"property-item-" + index}
                      member={currentSelectedMember}
                      data={el}
                      handleAdd={handleAdd}
                      handleDelete={handleDelete}
                    />
                  ))}
                </IonList>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonModal>
      {/* Property Modal END */}

      <div className="team-root">
        <IonText>
          <ClickOutside onClickOutside={handleClickOutside}>
            <div className="d-flex align-items-center">
              {teamData && teamData.settings && teamData.settings.logo && (
                <img
                  src={
                    "https://res.cloudinary.com/dltd4gs4a/image/upload/v1632911593/Rectangle_1497bgmain_qsoc82.png"
                  }
                  className="team-image"
                  alt="team-image"
                />
              )}
              <h2
                className="team-btn-header "
                onClick={() => setShowTeamDropdown(!showTeamDropdown)}
                style={{
                  fontWeight: "bold",
                  position: "relative",
                  cursor: "pointer",
                }}
              >
                {(teamData && teamData.settings && teamData.settings.name) ||
                  "Unnamed Team"}
              </h2>
            </div>
          </ClickOutside>
        </IonText>

        <div className="team-brief">
          <IonText className="team-brief__heading">About the team</IonText>
          <p className="team-brief__paragraph">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Accumsan
            tortor posuere ac ut consequat semper viverra nam. Fames ac turpis
            egestas maecenas pharetra. Odio ut enim blandit volutpat maecenas
            volutpat. Erat velit scelerisque in dictum non
          </p>
        </div>

        <IonSegment
          className="segment-main"
          value={selectedSegment}
          onIonChange={(e) => setSelectedSegment(e.detail.value)}
        >
          <IonSegmentButton className="segment-report" value="teamMembers">
            Team Members
          </IonSegmentButton>
          <IonSegmentButton className="segment-report" value="owners">
            Owners
          </IonSegmentButton>
        </IonSegment>
        <IonButton
          className="learn-btn align-left ml-3 team-btn"
          onClick={() => setShowModal(true)}
        >
          Invite a team member
        </IonButton>


        {
          selectedSegment === 'teamMembers' ? (
            <>

     {
          teamMember &&  teamMember.length > 0  && (
            <TeamTables teamMember={teamMember} />          
          )    
     }

{
          pendingTeamMembers &&  pendingTeamMembers.length > 0  && (
            <PendingTeamTables pendingTeamMembers={pendingTeamMembers} tableHeading='Pending Team Members' />          
          )    
     }
            </>

          ) : (
            
            <>
                 {
          teamOwners &&  teamOwners.length > 0  && (
            <TeamTables teamMember={teamOwners} />          
          )    
     }
                      {
          pendingTeamOwners &&  pendingTeamOwners.length > 0  && (
            <PendingTeamTables pendingTeamMembers={pendingTeamOwners} tableHeading='Pending Team Owners' />          
          )    
     }


            </>
          )
        }
   
      </div>
      <IonModal isOpen={introModal}>
        <IntroModal setIntroModal={setIntroModal} />
      </IonModal>
      <IonModal
        isOpen={successModal}
        cssClass="success-modal"
        onDidDismiss={handleCloseModal}
      >
        <SuccessModal
          handleCloseModal={handleCloseModal}
          heading="Success!"
          text="Member is removed successfully."
        />
      </IonModal>

      <IonModal
        isOpen={showModal}
        swipeToClose={true}
        onDidDismiss={() => setShowModal(false)}
      >
        <InviteMember setShowModal={setShowModal} />
      </IonModal>

      <TeamUpdateModal
        setIsShow={setIsShow}
        isShow={isShow}
        data={selectedTeamMember}
        setShowToast={() => setShowToast(true)}
        setDeleteToast={() => setShowDeleteToast(true)}
      />
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message="Role have been changed"
        duration={1000}
      />

      <IonToast
        isOpen={showDeleteToast}
        onDidDismiss={() => setShowDeleteToast(false)}
        message="Team Member Has been successfully deleted"
        duration={1000}
      />
    </IonContent>
  );
};

export default Team;
