import React, {useState} from 'react';
import './Calendar.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../theme/react-calendar-overrides.css';

const Properties: React.FC = () => {
  const [selectedDay, setSelectedDay] = useState(new Date());

// const mark = [
//   '04-03-2020',
//   '03-03-2020',
//   '05-03-2020'
// ]
  return (
    <div>
        <Calendar
            onChange={(date: any) => {setSelectedDay(date)}}
            value={selectedDay}
            className="calendar-main"
            // tileContent={(date)=> ((mark.find(x=>x==='03-03-2020'))?<div className='demo-event'/>:<div />)}
        />
    </div>
  );
};

export default Properties;
