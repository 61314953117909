import { checkDateRange, formatDate, getFormmatedTimeForText } from "../../utils/utils";
export const getConvertedCleanerObject =  (schedule: [], dateRange,teamData,teamProperty) => {

    const tempFilteredSchedule: any = {};
    let date = new Date(dateRange.startDate);
    const stringRange = `${formatDate(new Date(date))}-${formatDate(
        new Date(dateRange.endDate)
    )}`;
    tempFilteredSchedule[stringRange] = {};
    schedule.forEach(async(schduleDate: any) => {

        if
            (
            !checkDateRange(formatDate(new Date(date)), formatDate(new Date(dateRange.endDate)), schduleDate.endDate)
            || !schduleDate.trackedTime
        ) return


         Object.keys(schduleDate.trackedTime).forEach( async (userId) => {

            if(!tempFilteredSchedule[stringRange][userId]) {
                console.log(userId,teamData.members)
                const userName = teamData.members.filter(member => member.id === userId )
                tempFilteredSchedule[stringRange][userId] = {
                    name: userName[0].displayName,
                    totalWorkedTime: 0,
                    numberOfProperties: 0,
                    properties: {},
                }     
            } else {
                tempFilteredSchedule[stringRange][userId] = {
                    ...tempFilteredSchedule[stringRange][userId],
                } 
            }

            const { totalWorkedTime, numberOfProperties } = tempFilteredSchedule[stringRange][userId];
            tempFilteredSchedule[stringRange][userId] = {
                ...tempFilteredSchedule[stringRange][userId],
                totalWorkedTime: getFormmatedTimeForText(schduleDate.trackedTime[userId], totalWorkedTime),
                numberOfProperties: numberOfProperties + 1,

            };
            
            if(!tempFilteredSchedule[stringRange][userId].properties[
                schduleDate.propertyId
            ]) {
                tempFilteredSchedule[stringRange][userId].properties[
                    schduleDate.propertyId
                ] = {

                };
            } else {
                tempFilteredSchedule[stringRange][userId].properties[
                    schduleDate.propertyId
                ] = {
                    ...tempFilteredSchedule[stringRange][userId].properties[
                        schduleDate.propertyId
                    ]
                };

            }
            const property = teamProperty.filter(property => property.id ===  schduleDate.propertyId)
     
                tempFilteredSchedule[stringRange][userId].properties[
                schduleDate.propertyId
            ] = {
                propertyName: property[0].title,
                workedTime: getFormmatedTimeForText(schduleDate.trackedTime[userId],tempFilteredSchedule[stringRange][userId].properties[schduleDate.propertyId].workedTime),
                
            };
        });

    })

    return tempFilteredSchedule;

}


export const getConvertedPropertiesObject = (schedule: [], dateRange,teamData,teamProperties) => {
    const tempFilteredSchedule: any = {};
    let date = new Date(dateRange.startDate);
    const stringRange = `${formatDate(new Date(date))}-${formatDate(
        new Date(dateRange.endDate)
    )}`;
    tempFilteredSchedule[stringRange] = {};
    schedule.forEach((schduleDate: any) => {
        if
            (
            !checkDateRange(formatDate(new Date(date)), formatDate(new Date(dateRange.endDate)), schduleDate.endDate)
            || !schduleDate.trackedTime
        ) return

        if(!tempFilteredSchedule[stringRange][schduleDate.propertyId]) {
        const property = teamProperties.filter((property) => property.id === schduleDate.propertyId);
            tempFilteredSchedule[stringRange][schduleDate.propertyId] = {
                totalWorkedTime:  0,
                numberOfProperties: 0,
                name:property[0].title,
                cleaners: {},
    
            }
        } else {
            tempFilteredSchedule[stringRange][schduleDate.propertyId] = {
            ...tempFilteredSchedule[stringRange][schduleDate.propertyId],
            }

        }

        Object.keys(schduleDate.trackedTime).forEach((userId) => {

            const { totalWorkedTime, numberOfProperties } = tempFilteredSchedule[stringRange][schduleDate.propertyId];
            tempFilteredSchedule[stringRange][schduleDate.propertyId] = {
                ...tempFilteredSchedule[stringRange][schduleDate.propertyId],
                totalWorkedTime: getFormmatedTimeForText(schduleDate.trackedTime[userId], totalWorkedTime),
                numberOfProperties: numberOfProperties + 1,

            };
            /* This part can cause some bug in future because it resating the property Object each time  */
            if (!tempFilteredSchedule[stringRange][schduleDate.propertyId].cleaners[
                userId
            ]) {
                tempFilteredSchedule[stringRange][schduleDate.propertyId].cleaners[
                    userId
                ] = {};

            }
            const user  = teamData.members.filter(member => member.id === userId)
            tempFilteredSchedule[stringRange][schduleDate.propertyId].cleaners[
                userId
            ] = {
                cleanerName: user[0].displayName,
                workedTime: getFormmatedTimeForText(schduleDate.trackedTime[userId], tempFilteredSchedule[stringRange][schduleDate.propertyId].cleaners[
                    userId
                ].workedTime ),
            };
        });
    })

    return tempFilteredSchedule;

}