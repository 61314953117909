import React, { useState, useEffect, useContext } from "react";
import _, { property } from "lodash";
import {
  IonButton,
  IonText,
  IonRow,
  IonCol,
  IonContent,
  IonModal,
  IonSearchbar,
  IonChip,
  IonSelect,
  IonSelectOption,
  IonList,
  IonIcon
} from "@ionic/react";
import { AppContext } from "../../context/app-context";
import "./Listings.css";
import CustomTable from "../../components/customTable";
import ConfirmationModal from "../../components/confirmationModal";
import SuccessModal from "../../components/successModal";
import { useHistory } from "react-router";
import { firestore, getMyTeam, deleteProperty, } from "./../../lib/firebase";
import { authContext } from "../../context/auth-context/auth-context";
import { warningOutline } from "ionicons/icons";
import { DashBoardContext } from "../../context/dashboard/dashboard.context";
import Properties from "../Calendar";
interface ContainerProps {
  name: string;
}

const Listings: React.FC<ContainerProps> = ({ name }) => {
  const [searchText, setSearchText] = useState("");
  const [roomCount, setRoomCount] = useState([])
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [isPropertyLoaded, setIsPropertyLoaded] = useState<boolean>(false);
  const [listingData, setListingData] = useState<any>([]);
  const [filteredListing, setFilteredListingData] = useState<any>([]);
  const [selectedListing, setSelectedListing] = useState("");
  const [currentSelectedListing, setCurrentSelectedListing] = useState<any>("");
  const globalState = useContext(AppContext);
  const { teamData, currentUser } = useContext(authContext);
  const { setCto } = useContext(DashBoardContext);
  const history = useHistory();

  useEffect(() => {
    globalState.dispatch({
      type: "GET_ALL_LISTINGS",
      successAction: globalState.dispatch,
    });


    return () => {
      setCto('')
    }
  }, []);



  const columns = [
    {
      name: "Listing",
      selector: "title",
      sortable: true,
      minWidth: "400px",

      cell: (row) => {
        return (
        <IonRow
          className="ion-align-items-center ion-justify-content-between"
          style={{ width: "100%" }}
        >

          <IonCol
            size="3"
            className=""
            data-tag="allowRowEvents"
          >
            <img className='listing-bg-img' src={row.thumbnail ? row.thumbnail : 'https://res.cloudinary.com/dltd4gs4a/image/upload/v1618910694/WhatsApp_Image_2021-04-20_at_2.36.27_AM_xf1gpj.jpg'} alt="" />

          </IonCol>
          <IonCol size="9" className="pl-3">
            <IonText>
              <h6 data-tag="allowRowEvents">{row.title}</h6>
              <p data-tag="allowRowEvents">
                <span className="pr-2">Unit: {row.unit || '--'}</span> |
                <span className="px-2">Access Code: {row.accessCode}</span>
              </p>
            </IonText>
          </IonCol>
        </IonRow>
        )
      },
    },

    {
      name: "Bedrooms",
      selector: "bedroomCount",
      sortable: true,
      maxWidth: "150px",


    },
    // {
    //   name: "Beds",
    //   selector: "beds",
    //   sortable: true,
    // },
    {
      name: "Baths",
      selector: "bathroomCount",
      sortable: true,
      maxWidth: "150px",

    },
    {
      name: "Location",
      selector: (row) => row.address.city,
      sortable: true,
      maxWidth: "330px",
      cell: (row) => `${row.address.stringFormat}`,

    },
    {
      name: "",
      minWidth: "175px",
      center: true,
      cell: (row) => (
        <IonRow className="ion-align-items-center ion-justify-content-between">
          <IonButton
            fill="clear"
            onClick={() => history.push(`/dashboard/listings/view/${row.id}`)}
            style={{ textTransform: "capitalize" }}
          >
            Edit
            </IonButton>
          <IonButton
            fill="clear"
            style={{ textTransform: "capitalize" }}
            onClick={() => {
              setCurrentSelectedListing(row)
              setShowConfirmationModal(true);
              setSelectedListing(row.id);
            }}
          >
            Delete
            </IonButton>

          {
            !row.calendars.length && (
              <IonButton
                fill="clear"
                style={{ textTransform: "capitalize" }}
                onClick={() => {
                  setCto('calendar')
                  history.push(`listings/view/${row.id}`)
                }}
              >
                <IonIcon style={{ color: "rgb(255 0 0)" }} slot='icon-only' icon={warningOutline} />
                <IonText className='ml-1'> Add Calendar </IonText>

              </IonButton>
            )
          }
          {
            !row.taskLists.length && (
              <IonButton
                fill="clear"
                style={{ textTransform: "capitalize" }}
                onClick={() => {
                  setCto('taskLists')
                  history.push(`listings/view/${row.id}`)

                }}
              >
                <IonIcon style={{ color: "rgb(255 0 0)" }} slot='icon-only' icon={warningOutline} />
                <IonText className='ml-2'>Add Task</IonText>
              </IonButton>
            )
          }



        </IonRow>
      ),
    },
  ];



  const handleDeleteProperty = () => {


    const data = teamData.properties.filter(el => el !== currentSelectedListing.id)
    deleteProperty(currentSelectedListing.id, teamData.id, data)
  };

  const handleCloseModal = () => {
    setSuccessModal(false);
    globalState.dispatch({ type: "RESET_LISTING_FLAGS" });
  };

  const onRowClicked = (evt) => {
    history.push(`/dashboard/listings/view/${evt.id}`);
  };


  useEffect(() => {
    const freshProperty = listingData;
    let modifiedProperty;
    if (searchText) {
      const data = listingData.filter(el => el.title.toLowerCase().includes(searchText));
      modifiedProperty = data
    } else if (!searchText) {
      modifiedProperty = freshProperty
    }
    if (roomCount.length > 0 && modifiedProperty) {
      const data = modifiedProperty.filter(el1 => roomCount.some(el2 => {
        if (el2 > 4) {
          return el1.bedroomCount * 1 >= el2 * 1;
        }
        return el1.bedroomCount === el2
      }))
      modifiedProperty = data
    } else if (roomCount.length > 0) {
      const data = listingData.filter(el1 => roomCount.some(el2 => {
        if (el2 > 4) {
          return el1.bedroomCount * 1 >= el2 * 1;
        }
        return el1.bedroomCount === el2
        }))
      modifiedProperty = data
    }



    if (modifiedProperty) {
      setFilteredListingData(modifiedProperty)

    }


  }, [searchText, roomCount, listingData])


  useEffect(() => {
    if (currentUser && currentUser.id && teamData) {
        const getData = async () => {
        const docRef = firestore.collection('properties');
          const team = await getMyTeam(currentUser.email);
          docRef.onSnapshot(async el => {
            const allProperty = await el.docs.map(el => el.data())
            let teamProperty:any = []
            for(let i = 0; i < allProperty.length; i++) {
              for(let j = 0; j < team[0].properties.length; j++) {
                if(allProperty[i].id === team[0].properties[j]) {
                 teamProperty.push(allProperty[i])
                }
              }
            }


          
            setListingData(teamProperty)
            setFilteredListingData(teamProperty)
            setIsPropertyLoaded(true)
        })
      }
      getData()
    }
  }, [teamData]);


  return (
    <IonContent>
      <div className="listing-sec">
        <IonRow>
          <IonCol sizeMd='8' className='mlr-auto'>
          <IonRow className="ion-align-items-center ion-justify-content-between">
          <IonCol>
            <IonText className="heading">
              {listingData.length}&nbsp;{name}
            </IonText>
          </IonCol>
          <IonCol>
            <IonButton
              color="primary"
              onClick={() => history.push("/dashboard/listings/add")}
              style={{ float: "right" }}
            >
              Add Property
              </IonButton>

          </IonCol>
        </IonRow>
        <IonRow className="ion-align-items-center">
          <IonCol sizeXs="12" sizeMd="4">
            <IonSearchbar
              placeholder="Search listings"
              value={searchText}
              onIonChange={(e) => setSearchText(e.detail.value!)}
              className="listing-searchbar"
            />
          </IonCol>
          <IonCol sizeXs="12" sizeMd="8">
            <IonChip class='postion-set' outline={true} className="filter-chip">
              <IonList>
                <IonSelect multiple={true} className='room-label' cancelText="cancel" value={roomCount} placeholder="Rooms and beds" onIonChange={e => setRoomCount(e.detail.value)}>
                  <IonSelectOption value="1">1</IonSelectOption>
                  <IonSelectOption value="2">2</IonSelectOption>
                  <IonSelectOption value="3">3</IonSelectOption>
                  <IonSelectOption value="4">4</IonSelectOption>
                  <IonSelectOption value="5">5+</IonSelectOption>
                </IonSelect>
              </IonList>

            </IonChip>

            <IonButton className='button-sm-res' onClick={() => {
              setRoomCount([])
            }}>Clear Filters</IonButton>

          </IonCol>
        </IonRow>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size='12' sizeMd='10' className='mlr-auto' >
            {
              isPropertyLoaded ? (
                        <CustomTable
          data={filteredListing}
          columns={columns}
          onRowClicked={onRowClicked}
        />
              ) : (<div className='loader'></div>)
            }

          </IonCol>
        </IonRow>

        <IonModal
          isOpen={showConfirmationModal}
          cssClass="confirmation-modal"
          swipeToClose={true}
          onDidDismiss={() => setShowConfirmationModal(false)}
        >
          <ConfirmationModal
            handleCloseModal={() => setShowConfirmationModal(false)}
            heading="Delete Listing"
            text="Are you sure to delete this property?"
            handleSubmit={handleDeleteProperty}

          />
        </IonModal>
        <IonModal
          isOpen={successModal}
          cssClass="success-modal"
          onDidDismiss={() => setSuccessModal(false)}
        >
          <SuccessModal
            handleCloseModal={handleCloseModal}
            heading="Success!"
            text="Listing is deleted successfully."
          />{" "}
        </IonModal>
      </div>
    </IonContent>
  );
};

export default Listings;