import { properties } from "./mockdata.json";

export function getAllListings(dispatch) {
  try {
    dispatch({ type: "GET_ALL_LISTINGS_SUCCESS", listings: properties });
  } catch (error) {
    dispatch({ type: "GET_ALL_LISTINGS_ERROR", error });
  }
}

export function getListing(dispatch, id) {
  try {
    const property = properties.find((item) => item.id === id);
    dispatch({ type: "GET_LISTING_SUCCESS", listing: property });
  } catch (error) {
    dispatch({ type: "GET_LISTING_ERROR", error });
  }
}

export function addListing(dispatch, listing) {
  try {
    const tempProperties = [...properties, listing];
    dispatch({ type: "ADD_LISTING_SUCCESS", listings: tempProperties });
  } catch (error) {
    dispatch({ type: "ADD_LISTING_ERROR", error });
  }
}

export function updateListing(dispatch, listingId, listing) {
  try {
    let property = properties.find((item) => item.id === listingId);
    property = listing;
    dispatch({ type: "UPDATE_LISTING_SUCCESS", listing: property });
  } catch (error) {
    dispatch({ type: "UPDATE_LISTING_ERROR", error });
  }
}

export function deleteListing(dispatch, listingId) {
  try {
    const foundedIndex = properties.indexOf((item) => item.id === listingId);
    if (foundedIndex >= -1) {
      properties.splice(foundedIndex, 1);
    }
    dispatch({ type: "DELETE_LISTING_SUCCESS", listings: properties });
  } catch (error) {
    dispatch({ type: "DELETE_LISTING_ERROR", error });
  }
}
