import React from "react";
import {
  IonText,
  IonButton,
  IonIcon,
  IonRow,
  IonCol
} from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";
import "./confirmationModal.css";

interface ModalProps {
  handleCloseModal: any;
  heading:string;
  text:string;
  handleSubmit: any;
  actionText?: string
}

const ConfirmationModal: React.FC<ModalProps> = ({ handleCloseModal, heading, text, handleSubmit, actionText }) => {
  return (
    <div className="success-root">
      <IonText color="primary"> 
      <IonIcon  icon={checkmarkCircleOutline} className="check-icon" onClick={handleCloseModal}/>
        <h4 style={{ fontWeight: "bold" }}>{heading}</h4>
        </IonText>
        <IonText>
        <p>  
          {text}
        </p>
        </IonText>
        <IonRow>
          <IonCol>
          <IonButton
           onClick={handleCloseModal}
            >
             Cancel
            </IonButton>
          </IonCol>
          <IonCol>
          <IonButton
            onClick={() => {
              handleSubmit()
              handleCloseModal()
            }}
            >
             {actionText ? actionText : 'delete'}
            </IonButton>
          </IonCol>
        </IonRow>
    </div>
   
  );
};

export default ConfirmationModal;
