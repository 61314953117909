import { IonButton,  IonChip, IonCol, IonGrid, IonIcon,  IonTextarea, IonItem, IonLabel, IonList, IonRow, IonSegment, IonSegmentButton } from '@ionic/react';
import { addOutline, calendarOutline, chevronBackOutline, chevronDownOutline, chevronForwardOutline, } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { authContext } from '../../context/auth-context/auth-context';
import ClickOutside from '@leafyjava/react-click-away';
import firebase from "firebase/app";
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './schedule.css';
import { firestore, getAllTeamProperties, getCleaners, getMyTeam, getTeamSchedule, updateTeamSchedule, updateScheduleItem, createMailForAvailableCleaners, sendMailWhenCleanerSigned, sendMailToAdminOnCleanClaim } from '../../lib/firebase';
import TimeKeeper from 'react-timekeeper';
export interface ScheduleProps {


}


const months = ['january', 'February', 'March', 'April', 'May', 'June', 'july', 'August', 'Sepetember', 'october', 'November', 'December']

const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
]


function getSingleProperty(allProperties, currentDay) {
    try {

        return allProperties.filter((property) => property.id === currentDay.propertyId)[0];

    } catch (e) {

        return {
            title: '',
            thumbnail: '',
        };
    }
}


function getPropertyTaskIds(property) {
    console.log(property)
    if (property && property.taskLists) {
        return property.taskLists[0];
    }
    return '';
}


const AssignCleanerList = ({ currentEl, cleaners, handleAdd, handleRemove, date }) => {
    const [isChecked, setIsChecked] = useState(() => {
        return date.assignedTo.some(el => el === currentEl.id)
    });

    return (
        <IonItem onClick={(e) => {
            e.stopPropagation();
            setIsChecked(!isChecked)
            if (isChecked) {
                handleRemove(date.propertyId, date.id, 'assignedTo', currentEl.id)
            } else {
                handleAdd(date.propertyId, date.id, 'assignedTo', currentEl.id)
            }

        }}>
            <input className='mr-2' type='radio' checked={isChecked} onChange={() => {}} />
            {currentEl.email}
        </IonItem>
    )
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('/');
}

function getDateTitle(day, date, currentDateFormatted) {
    return day === currentDateFormatted ? (
        <h5 className='font-weight-bold text-dark custom-styles'>{getFormattedDates(date.endDate)
        } - Today</h5>
    ) : (
            <h5>{getFormattedDates(date.endDate)}</h5>
    )
}

function isFilteredOut(filters, date, currentUser) {
    // date = currentSchedule
    if (filters.cleaners.length && !filters.cleaners.some(cleaner=> date.assignedTo.includes(cleaner.id))) {
        return true;
    }
    if (filters.properties.length && !filters.properties.some(property=> date.propertyId === property.id)) {
        return true;
    }
    if (filters.status &&
        (
            (filters.status === 'assigned' && !date.assignedTo.length) ||
            (filters.status === 'unassigned' && date.assignedTo.length)
        )
    ) {
        return true;
    }
    if (
        (filters.selectedSegment === 'myCleanings' && !date.assignedTo.includes(currentUser.id)) ||
        (filters.selectedSegment === 'availableCleanings' && date.assignedTo.includes(currentUser.id))
    ) {
        return true;
    }
    return false;
}
const getFormattedDates = (date) => {
    let myDate = new Date(date);
    let myDateString = days[myDate.getDay()] + ', ' + months[myDate.getMonth()] + ' ' + myDate.getDate();

    return myDateString;


}

const Schedule: React.FC<ScheduleProps> = () => {
    const currentDate = new Date();
    const currentDateFormatted = formatDate(new Date());
    const oneWeekFromNow = new Date((new Date()).setDate(currentDate.getDate() + 6));
    const oneMonthFromNow = new Date((new Date()).setMonth(currentDate.getMonth() + 1));
    const history = useHistory();
    const [showNavbar, setShowNavbar] = useState(false);
    const [width, setWidth] = useState<number>();
    const [cleaners, setCleaners] = useState<any>([]);
    const [navbarCleanerDropdown, setNavbarCleanerDropdown] = useState<boolean>(false);
    const [navSelectedCleaner, setNavSelectedCleaner] = useState<any>([]);
    const [activeDropdown, setActiveDropdown] = useState<string>('');
    const [selectedFilters, setSelectedFilters] = useState<any>({
        selectedSegment: 'allCleanings',
        cleaners: [],
        properties: [],
        status: '',
        timeframe: 'Week',
        dateRange: {
            startDate: currentDate,
            endDate: oneWeekFromNow,
            key: 'selection',
        }
    });
    const [showCalendar, setShowCalendar] = useState(false);
    const [showAssignDropdown, setShowAssignDropdown] = useState(null);
    const [allProperties, setAllProperties] = useState<any>([]);
    const [selectedProperty, setSelectedProperty] = useState<any>([]);
    const [selectedSchedule, setSelectedSchedule] = useState<any>({});
    const [checkIn, setCheckIn] = useState<any>();
    const [checkOut, setCheckOut] = useState<any>();
    const [team, setTeam] = useState<any>();
    const [membersAsObject, setMembersAsObject] = useState({});
    const [teamSchedule, setTeamSchedule] = useState<any>([]);
    const [filteredSchedule, setFilteredSchedule] = useState<any>({});
    const { currentUser, showCleaning, setShowCleaning, teamData, selectedDate, setSelectedDate } = useContext(authContext);
    const [cleaningNotes, setCleaningNotes] = useState('');


    const updateDimensions = () => {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);


    useEffect(() => {
        const getData = async () => {
            const myTeam = await getMyTeam(firebase.auth().currentUser?.email);
            setTeam(myTeam[0]);
            const members = {};
            myTeam[0].members.forEach(member => {
                members[member.id] = member;
            })
            setMembersAsObject(members);
            const [cleaners, properties] = await Promise.all([getCleaners(myTeam[0].id), getAllTeamProperties(myTeam[0])]);
            setCleaners(cleaners);
            setAllProperties(properties.filter(el =>  el !== undefined));
            getUpdatedTeamSchedule(myTeam[0]);
            
        }
        if (firebase.auth().currentUser?.email) {
            getData()
        }
    }, [firebase.auth().currentUser?.email]);

    useEffect(() => {
        setCheckIn(selectedProperty.checkIn)
        setCheckOut(selectedProperty.checkOut)
    }, [selectedProperty])

    const getUpdatedTeamSchedule = async (myTeam) => {
        let schedule = await getTeamSchedule(myTeam);
        const myRole = myTeam.members.filter(el => el.id === firebase.auth().currentUser?.uid);
        if (myRole[0].role.toLowerCase() === 'cleaner') {
            schedule = schedule.filter(schedule => schedule.assignedTo
                .includes(firebase.auth().currentUser?.uid) || schedule.visible
            );
        }
        getFilteredTeamSchedule(schedule, selectedFilters.dateRange, true);
    }

    /* START Code for Filtering Cleanings */



    const getFilteredTeamSchedule = (schedule, dateRange = selectedFilters.dateRange, updateTeamSchedule = false) => {
        const tempFilteredSchedule = {};
        let date = new Date(dateRange.startDate); // date object
        tempFilteredSchedule[formatDate(new Date(date))] = {schedule: []}; // formate the date and setting the object with that formatted date value;

        // Looping will continue until the current date is greater then 
        while (date < dateRange.endDate) { 
            date.setDate(date.getDate() + 1); // increasing date value equavelnt to i++
            tempFilteredSchedule[formatDate(new Date(date))] = {schedule: []};
        }

        schedule.forEach((date) => {
            if (tempFilteredSchedule[date.endDate]) {
                tempFilteredSchedule[date.endDate].schedule.push(date);
            }
        })
        console.log(tempFilteredSchedule, schedule)
        setTeamSchedule(schedule);
        setFilteredSchedule(tempFilteredSchedule);
    }



    const updateActiveDropdown = (newFilter = '') => {
        return setActiveDropdown(newFilter);
    }

    const updateDayWeekMonthFilter = (timeframe) => {
        let dateRange;
        if (timeframe === 'Day') {
            dateRange = {
                ...selectedFilters.dateRange,
                endDate: new Date(selectedFilters.dateRange.startDate).setDate(new Date(selectedFilters.dateRange.startDate).getDate())
            }
        } else if (timeframe === 'Week') {
            dateRange = {
                ...selectedFilters.dateRange,
                endDate: new Date(selectedFilters.dateRange.startDate).setDate(new Date(selectedFilters.dateRange.startDate).getDate() + 6)
            }
        } else if (timeframe === 'Month') {
            dateRange = {
                ...selectedFilters.dateRange,
                endDate: new Date(selectedFilters.dateRange.startDate).setMonth(new Date(selectedFilters.dateRange.startDate).getMonth() + 1)
            }
        }
        setSelectedFilters({...selectedFilters, timeframe: timeframe, dateRange: dateRange});
        getFilteredTeamSchedule(teamSchedule, dateRange);
    }

    const traverseDayWeekMonths = (timeframe, isFuture) => {
        let dateRange;
        const startDate = new Date(selectedFilters.dateRange.startDate);
        const endDate = new Date(selectedFilters.dateRange.endDate);
        if (timeframe === 'Day') {
            dateRange = {
                ...selectedFilters.dateRange,
                startDate: startDate.setDate(startDate.getDate() + (isFuture ? 1 : -1)),
                endDate: endDate.setDate(endDate.getDate() + (isFuture ? 1 : -1))
            }
        } else if (timeframe === 'Week') {
            dateRange = {
                ...selectedFilters.dateRange,
                startDate: startDate.setDate(startDate.getDate() + (isFuture ? 6 : -6)),
                endDate: endDate.setDate(endDate.getDate() + (isFuture ? 6 : -6))
            }
        } else if (timeframe === 'Month') {
            dateRange = {
                ...selectedFilters.dateRange,
                startDate: startDate.setMonth(startDate.getMonth() + (isFuture ? 1 : - 1)),
                endDate: endDate.setMonth(endDate.getMonth() + (isFuture ? 1 : -1))
            }
        }
        setSelectedFilters({...selectedFilters, dateRange: dateRange});
        getFilteredTeamSchedule(teamSchedule, dateRange);
    }

    
    const handleSelectedDateRange = (ranges) => {
        setSelectedFilters({ ...selectedFilters, dateRange: { ...ranges.selection } });
        getFilteredTeamSchedule(teamSchedule, ranges.selection);
    }


    /* END Code for Filtering Cleanings */

    const updateScheduleData = async (propertyID, dateID, field, value) => {
        const documentRef = firestore.doc(`schedule/${propertyID}/dates/${dateID}`);
        await documentRef.update({
            [field]: value
        })
    }

    const handleAddAssignCleaner = (propertyID, dateID, field, value) => {
        getFilteredTeamSchedule(teamSchedule.map(schedule => {
            if (schedule.id === dateID) {
                sendMailWhenCleanerSigned(value)
                const data = [...schedule.assignedTo, value]
                updateScheduleData(propertyID, dateID, field, data)
                updateScheduleItem(propertyID, dateID, 'visible', false);
                return { ...schedule, visible: false, assignedTo: [...schedule.assignedTo, value] }
            }
            return schedule;
        }), selectedFilters.dateRange, true);
    }

    const handleRemoveAssignCleaner = (propertyID, dateID, field, value) => {
        getFilteredTeamSchedule(teamSchedule.map(schedule => {
            if (schedule.id === dateID) {
                const data = schedule.assignedTo.filter(el => el !== value);
                updateScheduleData(propertyID, dateID, field, data);
                return { ...schedule, assignedTo: [...data] }
            }
            return schedule;
        }), selectedFilters.dateRange, true);
    }

    const getFormattedDate = (date) => {
        return new Date(date).toLocaleDateString()
    }

    return (
        <>
            {/* @ts-ignore */}
            <div className="overlay" style={{ visibility: showNavbar ? 'visible' : 'hidden' }}>
                <ClickOutside onClickOutside={() => setShowNavbar(false)}>

                    <nav style={{ maxWidth: `${showNavbar ? '350px' : '0px'}` }} className={`navbar-side `} >
                        <div className="nav-header">
                            <span onClick={() => setShowNavbar(false)}>x</span>
                        </div>
                        <img style={{ width: '100%', height: '300px' }} src={selectedProperty.thumbnail ? selectedProperty.thumbnail : 'https://res.cloudinary.com/dltd4gs4a/image/upload/v1618910694/WhatsApp_Image_2021-04-20_at_2.36.27_AM_xf1gpj.jpg'} alt="" />
                        <div >
                            <h1 className='mt-0 ' style={{ padding: '10px' }}>
                                {selectedProperty.title || ''}
                            </h1>
                            {selectedProperty && selectedProperty.taskLists && !selectedProperty.taskLists.length &&
                                <div className='text-danger schedule-status font-weight-bold' style={{ color: '#018486' }}>No Task List assigned to property</div>
                            }
                            {selectedProperty && selectedProperty.address &&
                                (<span>
                                <IonLabel className='adress-label d-block' style={{ padding: '10px' }}> {selectedProperty.address.stringFormat || ''}</IonLabel>
                                    <IonGrid className='ion-no-grid'>
                                        <IonLabel>Check In Time</IonLabel>
                                        <IonButton className='schedule-chip' onClick={() => updateActiveDropdown('Check-in Time')} >
                                            <IonLabel>{checkIn ? checkIn : 'select Check In Time'}</IonLabel>
                                            <IonIcon icon={chevronDownOutline} />
                                        </IonButton>

                                        {
                                            activeDropdown === 'Check-in Time' && (
                                                <ClickOutside onClickOutside={() => updateActiveDropdown()}>
                                                    <TimeKeeper
                                                        time='11:30PM'
                                                    onDoneClick={e => {
                                                            if (e.formatted12 !== checkIn) {
                                                                updateScheduleItem(selectedProperty.id, selectedSchedule.id, 'checkIn', e.formatted12)

                                                            }
                                                            setCheckIn(e.formatted12);
                                                            updateActiveDropdown();

                                                        }}
                                                    />
                                                </ClickOutside>
                                            )
                                        }
                                    </IonGrid>
                                    <IonGrid className='ion-no-grid'>
                                        <IonLabel>Check Out Time</IonLabel>
                                        <IonChip className='schedule-chip' onClick={() => updateActiveDropdown('Check-out Time')} >
                                            <IonLabel>{checkOut ? checkOut : 'select Check Out Time'}</IonLabel>
                                            <IonIcon icon={chevronDownOutline} />
                                        </IonChip>

                                        {
                                            activeDropdown === 'Check-out Time' && (
                                                <ClickOutside onClickOutside={() => updateActiveDropdown()}>
                                                    <TimeKeeper
                                                        time='11:30PM'
                                                    onDoneClick={e => {
                                                            if (e.formatted12 !== checkOut) {
                                                                updateScheduleItem(selectedProperty.id, selectedSchedule.id, 'checkOut', e.formatted12)
                                                            }
                                                            setCheckOut(e.formatted12);
                                                            updateActiveDropdown();

                                                        }}
                                                    />
                                                </ClickOutside>
                                            )
                                        }
                                    </IonGrid>
                                    
                                </span>)
                            }
                            <IonGrid className='ion-no-padding'>
                                <IonRow>
                                    <IonCol size='12'>
                                        <div style={{
                                            width: '97% !important',
                                            margin: "0px 5px"
                                        }} onClick={() => setNavbarCleanerDropdown(!navbarCleanerDropdown)} >

                                            <IonChip className='schedule-chip'>
                                                <IonLabel>{navSelectedCleaner.length > 0 ? navSelectedCleaner[0].displayName : 'All Team Members'}</IonLabel>
                                                <IonIcon icon={chevronDownOutline} />
                                            </IonChip>
                                        </div>
                                        {
                                            navbarCleanerDropdown && (

                                                <ClickOutside onClickOutside={() => console.log(false)}>

                                                    <div className='select-container'>
                                                        <IonList className='select-list'>

                                                            {
                                                                team && team.members && team.members.length > 0 ?
                                                                    team.members.map((el, idx) => (
                                                                        <IonItem key={'team-member-' + idx} className='cursor-pointer' onClick={async () => {
                                                                            updateTeamSchedule(selectedProperty.id, selectedSchedule.id, el.id);
                                                                            setNavSelectedCleaner([el]);
                                                                            setNavbarCleanerDropdown(false);
                                                                        }}>
                                                                            <div style={{ fontSize: '14px' }}>
                                                                                {el.displayName}
                                                                                <p >{el.email}</p>
                                                                            </div>
                                                                        </IonItem>
                                                                    )) : <h6 className='ml-2'>no cleaner Available</h6>
                                                            }

                                                        </IonList>
                                                    </div>
                                                </ClickOutside>
                                            )
                                        }
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        </div>
                        <div className="additional-note">
                            <h1 style={{ fontSize: '16px' }}>Additional Notes</h1>
                            <IonTextarea className='additional-text' debounce={1000} value={cleaningNotes} onIonChange={(e) => updateScheduleData(selectedProperty.id, selectedSchedule.id, 'notesToCleaner', e.detail.value)} />
                            <div className="additional-button d-flex">
                                <IonButton 
                                onClick={() =>
                                 history.push(`/cleaning?propertyId=${selectedSchedule.propertyId}&taskListId=${getPropertyTaskIds(selectedProperty)}&scheduleId=${selectedSchedule.id}`)
                                 }>
                                    Start Cleaning
                                    </IonButton>
                                <span className='m-auto text-underline align-middle'>Preview Checklist</span>
                            </div>
                        </div>

                        <IonList>
                            <IonItem>
                                <IonLabel>Property Information</IonLabel>
                                <IonIcon icon={chevronForwardOutline} slot='end' />
                            </IonItem>
                            <IonItem>
                                {
                                    currentUser?.accountType !== 'Cleaner' && (
                                        <IonLabel color='primary' onClick={() => {
                                            firestore.doc(`schedule/${selectedSchedule.propertyId}/dates/${selectedSchedule.id}`).update({
                                                assignedTo: [],
                                                status: 'canceled'
                                            })
}}>Cancel Cleaning</IonLabel>
                                    )
                                }

                            </IonItem>
                        </IonList>
                    </nav>
                </ClickOutside>
            </div>

            <IonGrid >

                <IonRow>
                    <IonCol size='12' sizeMd='8' offsetMd='2' offsetSm='0'  >
                        <IonRow>
                            <IonCol>
                                <IonSegment onIonChange={e => setSelectedFilters({ ...selectedFilters, selectedSegment: e.detail.value })} value={selectedFilters.selectedSegment} >
                                    <IonSegmentButton className='segment-full' value="allCleanings">
                                        <IonLabel className="listing-details">All <span className='mobile-hide'>Cleanings</span> </IonLabel>
                                    </IonSegmentButton>
                                    <IonSegmentButton className='segment-full' value="myCleanings">
                                        <IonLabel className="listing-details">My <span className='mobile-hide'>Cleanings</span></IonLabel>
                                    </IonSegmentButton>
                                    <IonSegmentButton className='segment-full' value="availableCleanings">
                                        <IonLabel className="listing-details">Available <span className='mobile-hide'>Cleanings</span></IonLabel>
                                    </IonSegmentButton>
                                </IonSegment>
                            </IonCol>
                        </IonRow>

                        <IonRow>
                            <IonCol size='4' className='ion-no-padding'>
                                <div style={{ margin: '9px' }} onClick={() => updateActiveDropdown('Select Cleaner')} >
                                    <IonChip className='schedule-chip'>
                                        <IonLabel>{selectedFilters.cleaners.length > 0 ? selectedFilters.cleaners[0].displayName : 'All Team Members'}</IonLabel>
                                        <IonIcon icon={chevronDownOutline} />
                                    </IonChip>
                                </div>

                                {
                                    activeDropdown === 'Select Cleaner' && (

                                        <ClickOutside onClickOutside={() => updateActiveDropdown()}>

                                            <div className='select-container'>
                                                <IonList className='select-list'>
                                                    <IonItem onClick={() => {
                                                        updateActiveDropdown();
                                                        setSelectedFilters({...selectedFilters, cleaners: []});
                                                    }

                                                    }>
                                                        <IonLabel>All Team Members</IonLabel>
                                                    </IonItem>
                                                    {
                                                        team.members.length > 0 ?
                                                            team.members.map((el, idx) => (
                                                                <IonItem key={'cleaner-' + idx} className='cursor-pointer py-1' onClick={() => {
                                                                updateActiveDropdown();
                                                                setSelectedFilters({...selectedFilters, cleaners: [el]});
                                                                }}>
                                                                    <IonLabel>
                                                                                                                                            <div style={{ fontSize: '14px' }}>
                                                                        {el.displayName}
                                                                        <p >{el.email}</p>
                                                                    </div>
                                                                    </IonLabel>

                                                                </IonItem>
                                                            )) : <h6 className='ml-2'>no cleaner Available</h6>
                                                    }


                                                </IonList>
                                            </div>
                                        </ClickOutside>


                                    )
                                }

                            </IonCol>
                            <IonCol size='4'>
                                <div onClick={(e) => updateActiveDropdown('Select Property')}>

                                    <IonChip className='schedule-chip'>
                                        <IonLabel>{selectedFilters.properties.length > 0 ? selectedFilters.properties[0].title : 'All Properties'}</IonLabel>
                                        <IonIcon icon={chevronDownOutline} />
                                    </IonChip>
                                </div>
                                {
                                    activeDropdown === 'Select Property' && (

                                        <ClickOutside onClickOutside={() => updateActiveDropdown()}>

                                            <div className='select-container'>
                                                <IonList className='select-list'>
                                                    <IonItem onClick={() => {
                                                        updateActiveDropdown();
                                                        setSelectedFilters({ ...selectedFilters, properties: [] });
                                                    }

                                                    }>
                                                        <IonLabel>All Properties</IonLabel>
                                                    </IonItem>
                                                    {
                                                        allProperties.length > 0 ?
                                                            allProperties.map((el, idx) => (
                                                                <IonItem key={'schedule-property-' + idx} className='cursor-pointer py-1' onClick={() => {
                                                                    updateActiveDropdown();
                                                                    setSelectedFilters({ ...selectedFilters, properties: [el] });
                                                                }}>
                                                                    <IonLabel className='d-flex'>
                                                                        <img
                                                                            className='mr-2'
                                                                            width='45px'
                                                                            src="https://res.cloudinary.com/dltd4gs4a/image/upload/v1613535611/home_od6whg.jpg" alt="" />
                                                                        <div style={{ fontSize: '14px' }}>
                                                                            {el.title}
                                                                            <p ><span className="pr-2">Unit: --</span> |<span className="px-2">Access Code: {el.accessCode}</span></p>
                                                                        </div>
                                                                    </IonLabel>

                                                                </IonItem>
                                                            )) : (<h5 className='ml-2'>No Property Exist</h5>)
                                                    }
                                                </IonList>
                                            </div>
                                        </ClickOutside>


                                    )
                                }
                            </IonCol>
                            <IonCol size='4'>
                                <div onClick={(e) => updateActiveDropdown('Status Filter')}>
                                    <IonChip className='schedule-chip'>
                                        <IonLabel>{selectedFilters.status ? selectedFilters.status : 'status'}</IonLabel>
                                        <IonIcon icon={chevronDownOutline} />
                                    </IonChip>
                                </div>
                                {
                                    activeDropdown === 'Status Filter' && (
                                        <ClickOutside onClickOutside={() => updateActiveDropdown()}>
                                            <div className='select-container'>
                                                <IonList className='select-list'>
                                                    <IonItem onClick={() => { setSelectedFilters({...selectedFilters, status: ''}); updateActiveDropdown(); }}>
                                                        <IonLabel>All / Both</IonLabel>
                                                    </IonItem>
                                                    <IonItem onClick={() => { setSelectedFilters({...selectedFilters, status: 'assigned'}); updateActiveDropdown(); }}>
                                                        <IonLabel>Assigned</IonLabel>
                                                    </IonItem>
                                                    <IonItem onClick={() => { setSelectedFilters({...selectedFilters, status: 'unassigned'}); updateActiveDropdown(); }}>
                                                        <IonLabel>Unassigned</IonLabel>
                                                    </IonItem>
                                                </IonList>
                                            </div>
                                        </ClickOutside>
                                    )
                                }
                            </IonCol>
                            <IonCol>
                                <IonChip className='schedule-chip' onClick={() => updateActiveDropdown('Day Week Month Filter')}>
                                    <IonLabel>{selectedFilters.timeframe}</IonLabel>
                                    <IonIcon icon={chevronDownOutline} />
                                </IonChip>

                                {
                                    activeDropdown === 'Day Week Month Filter' && (
                                        <ClickOutside onClickOutside={() => updateActiveDropdown()}>
                                            <div className='select-container'>
                                                <IonList className='select-list'>
                                                    <IonItem onClick={() => { updateDayWeekMonthFilter('Day'); updateActiveDropdown(); }}>
                                                        <IonLabel>Day</IonLabel>
                                                    </IonItem>
                                                    <IonItem onClick={() => { updateDayWeekMonthFilter('Week'); updateActiveDropdown(); }}>
                                                        <IonLabel>Week</IonLabel>
                                                    </IonItem>
                                                    <IonItem onClick={() => { updateDayWeekMonthFilter('Month'); updateActiveDropdown(); }}>
                                                        <IonLabel>Month</IonLabel>
                                                    </IonItem>
                                                </IonList>
                                            </div>
                                        </ClickOutside>
                                    )
                                }
                            </IonCol>
                            <IonCol size='9'>
                                <IonChip className='schedule-chip' onClick={() => setShowCalendar(!showCalendar)}>

                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <IonIcon icon={calendarOutline} />
                                        <IonLabel className='dates-label'>Dates ({getFormattedDate(selectedFilters.dateRange.startDate)} - {getFormattedDate(selectedFilters.dateRange.endDate)})</IonLabel>
                                    </div>
                                    <IonIcon icon={chevronDownOutline} />
                                </IonChip>

                                {/*  */}

                                {
                                    showCalendar && (
                                        <ClickOutside onClickOutside={() => setShowCalendar(false)}>
                                            <div>
                                                <DateRange
                                                    ranges={[selectedFilters.dateRange]}
                                                    onChange={handleSelectedDateRange}
                                                />
                                            </div>
                                        </ClickOutside>
                                    )

                                }
                            </IonCol>
                            {/* @ts-ignore */}
                            <IonCol size={width !== undefined && width <= 1420 ? '12' : 'auto'}>
                                <div className="buttons-side" style={{ margin: '6px 0px' }}>
                                <span className="side-button border-r" onClick={() => {traverseDayWeekMonths(selectedFilters.timeframe, false)}}>

                                        <IonIcon icon={chevronBackOutline} />
                                    </span>

                                       <span className="side-button border-l" onClick={() => {traverseDayWeekMonths(selectedFilters.timeframe, true)}}>
                                        <IonIcon icon={chevronForwardOutline} />
                                    </span>

                                </div>
                            </IonCol>
                        </IonRow>

                        {/* Schedule list Start */}
                        {Object.keys(filteredSchedule).length > 0 ? '' : <div className='loader'></div>}

                        {
                            Object.keys(filteredSchedule).map((day) => {

                                return filteredSchedule[day].schedule.length ? filteredSchedule[day].schedule.map((date, index) => {
                                    return (

                                        <>

                                        {
                                            isFilteredOut(selectedFilters, date, currentUser) ? (
                                                <>
                                                {
                                                    filteredSchedule[day].schedule[index - 1] && filteredSchedule[day].schedule[index].endDate ===   filteredSchedule[day].schedule[index - 1].endDate ?  null : (
                                                        <span key={'empty-schedule-day-' + day}>
                                                        {getDateTitle(day, date, currentDateFormatted)}
                                                                                                      <p>No Assigned Cleanings</p>
                                                                                                    </span> 
                                                    )
                                                }
                                      

                                                </>
                                            ) : (
                                                 <span style={{
                                                     display: 'block',
                                                     position: 'relative'
                                                 }} key={'schedule-day-' + day+ index}>
                                                     {
                                                        !(filteredSchedule[day].schedule[index - 1] && filteredSchedule[day].schedule[index].endDate ===   filteredSchedule[day].schedule[index - 1].endDate) && (
                                                            getDateTitle(day, date, currentDateFormatted)

                                                        )
                                                     }

                                            <IonRow>
                                                <IonCol>
                                            <IonList>
                                                        <IonItem lines="none" className='schedule-item cursor-pointer flex-row'
                                                         onClick={() => {
                                                             if (date.status === 'Completed') {
                                                                 history.push(`/cleaning?propertyId=${date.propertyId}&scheduleId=${date.id}`)
                                                                 return;
                                                             }
                                                             setShowNavbar(true);
                                                             setSelectedProperty(getSingleProperty(allProperties, date));
                                                             setSelectedSchedule(date);
                                                             setCleaningNotes(date.notesToCleaner);
                                                         }}>
                                                    <div className='align-grid'>
                                                        <img slot='start'
                                                                    style={{ borderRadius: '100%', width: '75px', height: '75px', alignSelf: 'center' }}
                                                                    src={getSingleProperty(allProperties, date).thumbnail ? getSingleProperty(allProperties, date).thumbnail : 'https://res.cloudinary.com/dltd4gs4a/image/upload/v1618910694/WhatsApp_Image_2021-04-20_at_2.36.27_AM_xf1gpj.jpg'} alt="" />
                                                                <div className='schedule-details align-self-center'>
                                                            <h4 className='schedule-heading'>
                                                                <div className='schedule-name font-weight-bold mb-1'>{getSingleProperty(allProperties, date).title}</div>
                                                                <div className='schedule-status font-weight-bold' style={{ color: '#018486' }}>Status: {date.status}</div>
                                                                {getSingleProperty(allProperties, date).taskLists && !getSingleProperty(allProperties, date).taskLists.length &&
                                                                <div className='text-danger schedule-status font-weight-bold' style={{ color: '#018486' }}>No Task List assigned to property</div>
                                                                }
                                                            </h4>
                                                            <h6 className='heading-time'>
                                                                <div className='mb-1'>Check-Out Time: {date.checkOut || getSingleProperty(allProperties, date).checkOut}</div>
                                                                        <div>{getSingleProperty(allProperties, date).address?.stringFormat}</div>
                                                            </h6>
                                                            <span className='schedule-details-btn'>
                                                                View Details
                                                            <IonIcon icon={chevronForwardOutline} className='align-middle' />
                                                            </span>
                                                        </div>
                                                        <div slot="end" className="d-flex grid-align flex-column" style={{ alignSelf: 'center' }}>
                                                            {
                                                                date.assignedTo && date.assignedTo.length ? <div className="mt-2">
                                                                    Assigned To:
                                                                    {date.assignedTo.map(assigneeId => <p key={'assignee-member-' + assigneeId}>{membersAsObject[assigneeId].displayName}</p>)}
                                                                {currentUser.accountType === 'Cleaner' && (
                                                                    <IonButton >Clock In</IonButton>
                                                                )}
                                                                </div> : null
                                                            }
                                                            {
                                                                date.status !== 'Completed' && currentUser.accountType === 'Admin' ? (
                                                                    <>

                                                                        <IonButton onClick={e => {
                                                                            e.stopPropagation();
                                                                            // @ts-ignore
                                                                            setShowAssignDropdown(`${date.id}${index}`  );
                                                                        }}>
                                                                            Assign Cleaning
                                                                        </IonButton>
                                                                        <IonButton color="primary" onClick={e => {
                                                                            e.stopPropagation();
                                                                            if (!date.visible) {
                                                                                 createMailForAvailableCleaners(currentUser.email)
                                                                            }
                                                                            updateScheduleItem(date.propertyId, date.id, 'visible', !date.visible);
                                                                            if (date.assignedTo && date.assignedTo.length) {
                                                                                updateScheduleItem(date.propertyId, date.id, 'assignedTo', []);
                                                                            }
                                                                            getFilteredTeamSchedule(teamSchedule.map(schedule => {
                                                                                if (schedule.id === date.id) {
                                                                                    return { ...schedule, visible: !date.visible, assignedTo: [] }
                                                                                }
                                                                                return schedule;
                                                                            }), selectedFilters.dateRange, true);
                                                                        }}>
                                                                            {date.visible ? 'Hide from cleaners' : 'Make available to cleaners'}
                                                                        </IonButton>



                                                                    </>
                                                                        ) : ''

                                                            }
                                                            {
                                                                date.visible && !date.assignedTo.length && !date.assignedTo.includes(firebase.auth().currentUser?.uid) && date.status !== 'Completed' && currentUser.accountType === 'Cleaner' ? (
                                                                    <IonButton color="primary" onClick={e => {
                                                                        e.stopPropagation();
                                                                        getFilteredTeamSchedule(teamSchedule.map(schedule => {
                                                                            if (schedule.id === date.id) {
                                                                                const admin = teamData.members.filter(el => el.role === 'Admin').map(el => el.email);
                                                                                sendMailToAdminOnCleanClaim(admin)
                                                                                updateScheduleItem(date.propertyId, date.id, 'assignedTo', [...schedule.assignedTo, firebase.auth().currentUser?.uid]);
                                                                                return { ...schedule, assignedTo: [...schedule.assignedTo, firebase.auth().currentUser?.uid] }
                                                                            }
                                                                            return schedule;
                                                                        }), selectedFilters.dateRange, true);
                                                                    }}>
                                                                        Claim Cleaning
                                                                    </IonButton>
                                                                ) : ''
                                                            }
                                                        </div>
                                                    </div>

                                                </IonItem>
                                            </IonList>
                                        </IonCol>
                                    </IonRow>


                                    {
                                                                            showAssignDropdown === date.id + index && (
                                                                                <ClickOutside onClickOutside={(e) => {
                                                                                    setShowAssignDropdown(null);
                                                                                }}>
                                                                                    <div className='dropdown-assign-cleaner'>
                                                                                        {team && team.members && team.members.length > 0 ?
                                                                                            (
                                                                                                <IonList>
                                                                                                    {
                                                                                                        team.members.map((el, index) =>
                                                                                                            <AssignCleanerList
                                                                                                                key={'cleaner-list-' + index}
                                                                                                                currentEl={el}
                                                                                                                date={date}
                                                                                                                cleaners={team.members}
                                                                                                                handleAdd={handleAddAssignCleaner}
                                                                                                                handleRemove={handleRemoveAssignCleaner}
                                                                                                            />
                                                                                                        )
                                                                                                    }

                                                                                                </IonList>
                                                                                            ) : <h3 className='ml-2'>No Cleaner Available</h3>}

                                                                                    </div>

                                                                                </ClickOutside>
                                                                            )
                                                                        }
                                </span>

                                            )
                                        }

                                       
                                </>
                                )
                                }) : (
                                <span key={'empty-schedule-day-' + day}>
                                            <h5>{getFormattedDates(day)}</h5>
                                            <p className='no-cleaning'> <IonIcon icon={calendarOutline} /> <span>No Cleanings</span>
                                                <IonButton onClick={() => {
                                                    setShowCleaning(true);
                                                    setSelectedDate(new Date(day))
                                                }} fill='clear' className='ml-auto'>
                                                    <IonIcon icon={addOutline} />
                                                Add Cleaning
                                            </IonButton> </p>
                                </span> )
                            })
                        }
 
                    </IonCol>
                </IonRow>


            </IonGrid>
        </>
    );
}

export default Schedule;

