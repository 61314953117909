import { createContext } from "react";

export interface Team {
  access: [];
  email: string;
}

export const authContext: any = createContext({
  currentUser: {},
  id: <string | null>"",
  displayName: <string | null>"",
  email: <string | null>"",
  teamData: {},
  teamsList: [],
  handleTeamData: (obj: object) => {},
  selectTeamFromList: (obj: object) => {},
  pendingTeam: <Team[]>[],
  showCleaning: false,
  setPendingTeam: (obj: object) => {},
  setShowCleaning: () => {},
  selectedDate: '',
  setSelectedDate: () => {},
});
