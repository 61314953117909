import React, { useState, useEffect,useContext } from "react";
import {
  IonText,
  IonButton,
  IonItem,
  IonLabel,
  IonRow,
  IonCol,
  IonInput,
  IonContent,
  IonIcon,
  IonModal,
} from "@ionic/react";
import { Plugins, CameraResultType } from "@capacitor/core";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { cloudUploadOutline, chevronBackOutline } from "ionicons/icons";
import { AppContext } from "../../context/app-context";
import SuccessModal from "../../components/successModal";
import "./TeamSettings.css";
import {useHistory} from "react-router";
import firebase, { getMyOwnedTeam, updateTeamDetails } from './../../lib/firebase'
import { authContext } from "../../context/auth-context/auth-context";

const { Camera } = Plugins;

interface TeamSettingsProps {
  teamId: string;
}

const TeamSettings: React.FC<TeamSettingsProps> = ({teamId}) => {
  const history = useHistory();
  const globalState = useContext(AppContext);
  const [teamName, setTeamName] = useState("");
  const [photo, setPhoto] = useState("");
  const [existingPhoto, setExistingPhoto] = useState("");
  const [status, setStatus] = useState("");
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const { currentUser } = useContext(authContext)
  
  useEffect(() => {
    defineCustomElements(window);
  });


  async function getTeam() {
    const myTeam: any = await getMyOwnedTeam(firebase.auth().currentUser?.uid);
    if (!myTeam) return;
    console.log(myTeam, firebase.auth().currentUser)
    setTeamName(myTeam.settings?.name || '');
    setExistingPhoto(myTeam.settings?.logo || '');
  }

  useEffect(() => {
    getTeam();
  }, [firebase.auth().currentUser])



  const handleCloseModal = () => {
    setSuccessModal(false);
    globalState.dispatch({ type: "RESET_TEAM_FLAGS" });
  };


  if(Object.keys(currentUser).length  && !(currentUser.accountType === 'Admin')) {
    history.push('/')
  }

  return (
    <>
      <IonContent>
        <div className="settings-root">
          <IonButton
            onClick={() => history.goBack()}
            fill="clear"
            className="back-btn"
          >
            <IonIcon slot="start" icon={chevronBackOutline} />
            Back to team
          </IonButton>
          <IonRow className="ion-align-items-center ion-justify-content-between">
            <IonCol sizeXs="7">
              <IonLabel>
                <h1>Team Settings</h1>
              </IonLabel>
            </IonCol>

          </IonRow>
          <div>
            <IonText>
              <h6>Company name</h6>
              <p>Use a name guests and team members will recognize</p>
            </IonText>
            <IonItem className="company-name">
              <IonInput
                placeholder="Enter Company Name"
                onIonChange={(e) => setTeamName(e.detail.value!)}
                value={teamName}
                disabled={!(currentUser.accountType === 'Admin')}
              >
                {" "}
              </IonInput>
            </IonItem>
            <IonText>
              <h6>Company logo</h6>
              <p>Add an image or logo that represents your team</p>
            </IonText>

            <IonRow className="upload-section ion-justify-content-center text-center">
              {photo || existingPhoto ? (
                <IonCol size="12" >
                  <div style={{ display: "inline-block", cursor: 'pointer', position: 'relative' }}>
                  <img
                    src={photo || existingPhoto}
                    alt="uploaded image"
                    height="200px"
                    style={{ cursor: "pointer" }}
                  />
                    <input
                    disabled={!(currentUser.accountType === 'Admin')}
                    onChange={(e: any) => setPhoto(URL.createObjectURL(e.target.files[0]))}
                    style={{ cursor: 'pointer' }} className="learn-btn btn-file-upload" type="file" name="filename"
                     />

                  </div>

                </IonCol>
              ) : ''}
              <IonCol className="col-auto text-center">
                <IonIcon name={cloudUploadOutline} slot="start" />
                <div style={{ display: "inline-block", cursor: 'pointer', position: 'relative' }}>

                <IonButton
                  className="learn-btn"
                  fill="outline"
                  disabled={true}
                >
                    Choose file
                </IonButton>{" "}
                  <input onChange={(e: any) => setPhoto(URL.createObjectURL(e.target.files[0]))} style={{ cursor: 'pointer' }} className="learn-btn btn-file-upload" type="file" name="filename" />


                </div>

              </IonCol>
            </IonRow>


            <IonButton
              className="save-btn"
              disabled={!(currentUser.accountType === 'Admin')}
              onClick={async () => {

                if(!(currentUser.accountType === 'Admin')) {
                 return alert('You dont have permission to change name and photo of the Team');
                }
                await updateTeamDetails(teamId, teamName, photo, existingPhoto);
                alert('Updated team details!');
                // handleSaveTeam()
              }}
            >
              Save
            </IonButton>
          </div>
          {/*<IonText>*/}
          {/*  <h6>Deactivate Team</h6>*/}
          {/*  <p>*/}
          {/*    This will permanently remove team member access to listings,*/}
          {/*    reservations, messages, and finances. You'll still have access to*/}
          {/*    your listings, and team members will keep their separate Airbnb*/}
          {/*    accounts.*/}
          {/*  </p>*/}
          {/*</IonText>*/}
          {/*<IonButton*/}
          {/*  onClick={() => handleDeactivateTeam()}*/}
          {/*  className="save-btn"*/}
          {/*>*/}
          {/*  {status === "active" ? "Deactivate" : "Active"} team*/}
          {/*</IonButton>*/}
        </div>
      </IonContent>
 
      <IonModal
        isOpen={successModal}
        cssClass="success-modal"
        onDidDismiss={handleCloseModal}
      >
        <SuccessModal
          handleCloseModal={handleCloseModal}
          heading="Success!"
          text={
            status === "deactivated"
              ? "Company is deactivated successfully."
              : status === "active"
              ? "Company is activated successfully"
              : "Company profile is updated successfully."
          }
        />
      </IonModal>
    </>
  );
};

export default TeamSettings;
