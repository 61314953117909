import React, { useContext, useEffect, useState } from "react";
import {
  IonText,
  IonLabel,
  IonContent,
  IonRow,
  IonChip,
} from "@ionic/react";
import "./Activity.css";
import { AppContext } from "../../context/app-context";

import { Activity } from "../../types";
import moment from "moment";

const ActivityPage: React.FC = ({}) => {
  const [activities, setActivities] = useState<Activity[] | []>([]);

  const globalState = useContext(AppContext);

  useEffect(() => {
    globalState.dispatch({
      type: "GET_ALL_ACTIVITIES",
      successAction: globalState.dispatch,
    });
  }, []);

  useEffect(() => {
    if (globalState) {
      globalState.state.activities.length > 0 &&
        setActivities(globalState.state.activities);
    }
  }, [globalState.state.activities]);

  return (
    <IonContent>
      <div className="activity-root">
        <IonRow>
          <IonText className="heading">All activity</IonText>
        </IonRow>
        <IonRow style={{ marginTop: "20px" }}>
          <IonChip outline={true} className="filter-chip">
            <IonLabel>Dates</IonLabel>
          </IonChip>
          <IonChip outline={true} className="filter-chip">
            <IonLabel>Listings</IonLabel>
          </IonChip>
          <IonChip outline={true} className="filter-chip">
            <IonLabel>Users</IonLabel>
          </IonChip>
        </IonRow>
        <>
          {activities && activities.length > 0
            ? 
            //@ts-ignore
              activities.map((item, index) => {
                //@ts-ignore
                // if(moment(activities[index].date).format('D MMMM YYYY') === moment(activities[index+1].date).format('D MMMM YYYY')){
                if (activities[index].date === (activities[index+1] && activities[index+1].date)) {
                  return (
                    <div style={{marginTop:"20px"}}>
                     <IonText>
                     <h6 style={{fontWeight:"bold"}}>{moment(item.date).format('D MMMM YYYY')}</h6>
                     </IonText>
                    <div className="activities-step">
                      <IonRow className="ion-justify-content-between">
                        <IonText>{item.propertyName}</IonText>
                        <IonText>{moment(item.date).format("LT")}</IonText>
                      </IonRow>
                        <h6 style={{fontWeight:"bold", margin:"3px 0px"}}>{item.status}</h6>
                        <p>By {item.userName}</p>
                    </div>
                    </div>
                  );
                }else{
                  return(
                    <div className="activities-step">
                   <IonRow className="ion-justify-content-between">
                     <IonText>{item.propertyName}</IonText>
                     <IonText>{moment(item.date).format("LT")}</IonText>
                   </IonRow>
                     <h6 style={{fontWeight:"bold", margin:"3px 0px"}}>{item.status}</h6>
                     <p>By {item.userName}</p>
                 </div>
                  )
                }
              })
            : null}
        </>
      </div>
    </IonContent>
  );
};

export default ActivityPage;
