import React, {useContext, useEffect, useState} from "react";
import './new-user-modal.styles.css'
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonListHeader,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonRow,
} from "@ionic/react";
import { userContext } from "../context/user/user-context";
import { createUserToDB } from "../lib/firebase";
import { authContext } from "../context/auth-context/auth-context";
import firebase from "../lib/firebase";

export interface NewUserModalProps {
  isOpen: boolean;
  handleShow: (p: boolean) => void;
}

const Step1 = ({ handleButton, handleAccountOwner }) => {
  return (
    <>
      <h1 className="heading-text">First Confirm The Account owner</h1>
      <h3 className="heading-text">
        A team Must have one account owner who manages the company account using
        a special set of permission{" "}
      </h3>

      <div style={{ marginLeft: "-14px" }}>
        <IonRadioGroup
        /*  onIonChange={(e) => setSelected(e.detail.value)} */
        >
          <IonListHeader>
            <h3>Are You Account Owner</h3>
          </IonListHeader>

          <IonItem
            lines="none"
            onClick={() => {
              handleButton(false);
              handleAccountOwner("Owner");
            }}
          >
            <IonLabel>Yes, I am Account owner</IonLabel>
            <IonRadio slot="start" value={true} />
          </IonItem>

          <IonItem
            lines="none"
            onClick={() => {
              handleButton(false);
              handleAccountOwner("Cleaner");
            }}
          >
            <IonLabel>No, That's not me!</IonLabel>
            <IonRadio slot="start" value={false} />
          </IonItem>
        </IonRadioGroup>
      </div>
    </>
  );
};

const Step2 = ({ userCredential, handleChange }) => {
  const {
    displayName,
    location,
  } = userCredential;

  return (
    <IonGrid>
      <IonRow>
        <IonCol>
          <IonItem>
            <IonLabel className="new-user-modal" position="floating">
              Display Name
            </IonLabel>
            <IonInput
                onIonChange={handleChange}
                name="displayName"
                value={displayName}
            ></IonInput>
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow>


        <IonCol size="12">
          <IonItem>
            <IonLabel className="new-user-modal" position="stacked">
              Location
            </IonLabel>
            <span className="message">
              This will be shown to team members and guest
            </span>
            <IonInput
              onIonChange={handleChange}
              name="location"
              value={location}
            ></IonInput>
          </IonItem>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

const NewUserModal: React.FC<NewUserModalProps> = ({ isOpen, handleShow }) => {
  const { id, displayName } = useContext(authContext);
  const [step, setStep] = useState(2);
  const { currentUser } = useContext(userContext);
  const [userCredential, setUserCredential] = useState({
    id: "",
    accountType: "",
    displayName: "",
    email: "",
    location: "",
  });
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    setUserCredential({
      ...userCredential,
      id: firebase.auth().currentUser?.uid || '',
      displayName: firebase.auth().currentUser?.displayName || '',
      email: firebase.auth().currentUser?.email || '',
    })
  }, [firebase.auth().currentUser])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserCredential({ ...userCredential, [name]: value });
  };

  return (
    <IonModal cssClass='width-control' backdropDismiss={false} isOpen={isOpen}>
      <IonContent>
        <div style={{ padding: "2rem" }}>
          <h2 className="px-3">Complete Account</h2>
          {/*<span style={{ fontWeight: "bold" }}>Step {step}</span>*/}
          {/*<div className={`underline underline-${step} `}>&nbsp;</div>*/}
          <Step2
            userCredential={userCredential}
            handleChange={handleChange}
          />

          {step === 1 ? (
            <IonButton
              disabled={buttonDisabled}
              onClick={() => {
                setStep(step + 1);
                setButtonDisabled(true);
              }}
            >
              Next
            </IonButton>
          ) : (
            <IonButton
              onClick={async () => {
                createUserToDB(id, userCredential.displayName, userCredential);
                handleShow(false);;
              }}
            >
              Submit
            </IonButton>
          )}
        </div>
      </IonContent>
    </IonModal>
  );
};

export default NewUserModal;
