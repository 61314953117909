import './property.styles.css'
import { IonCheckbox, IonCol, IonContent, IonGrid, IonIcon, IonItem, IonList, IonModal, IonRow } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import firebase, { firestore, getAllProperties } from '../lib/firebase';
import { closeOutline } from 'ionicons/icons';
export interface PropertyModalProps {
    propertyModal: boolean,
    setPropertyModal: any,
    selectedTasklist?: any
}







const PropertyModal: React.FC<PropertyModalProps> = ({ propertyModal, setPropertyModal, selectedTasklist }) => {
    const [properties, setProperties] = useState<any>([]);

    useEffect(() => {
        const getData = async () => {
            const data = await getAllProperties()
            setProperties(data)
        }
        getData()
    }, [])
    return (
        <IonModal
            isOpen={propertyModal}
            onDidDismiss={() => setPropertyModal(false)}
            cssClass='modal-property-tasklists'
        >
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <h6 style={{
                                marginLeft: '0px',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                display: 'flex',
                                padding: '0px 13px'
                            }}>Please select Properties <span onClick={() => setPropertyModal(false)} style={{ cursor: 'pointer' }}><IonIcon icon={closeOutline} /></span></h6>
                            <p style={{ marginLeft: '11px', padding: 0, }}>Add Task to The Properties</p>

                        </IonCol>
                    </IonRow>
                    <IonRow >
                        <IonCol>
                            <IonList>
                                {
                                    properties.map((el, index) =>

                                        <IonItem>
                                            <img
                                                className='mr-2'
                                                width='45px'
                                                src={el.thumbnail} alt="" />
                                            <div style={{ fontSize: '14px' }}>
                                                {el.title}
                                                <p><span className="pr-2">Unit: {el.unit}</span> |<span className="px-2">Access Code: {el.accessCode}</span></p>
                                            </div>
                                            <IonCheckbox
                                                onIonChange={(e) => {

                                                    if (e.detail.checked) {
                                                        firestore.doc(`properties/${el.id}`).update({
                                                            taskLists: firebase.firestore.FieldValue.arrayUnion(selectedTasklist)
                                                        })
                                                    } else {
                                                        firestore.doc(`properties/${el.id}`).update({
                                                            taskLists: firebase.firestore.FieldValue.arrayRemove(selectedTasklist)
                                                        })
                                                        console.log('Just get unchecked')
                                                    }
                                                }}
                                                checked={el?.taskLists?.some(el1 => el1 === selectedTasklist)} slot="end" color="primary"

                                            />

                                        </IonItem>
                                    )
                                }


                            </IonList>
                        </IonCol>
                    </IonRow>

                </IonGrid>
            </IonContent>

        </IonModal>
    );
}

export default PropertyModal;