import firebase from "firebase/app";
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

const config = {
    apiKey: "AIzaSyAWS5Su8cOhm4xUkpaWKJiadC9CirSPWNM",
    authDomain: "clean-stays.firebaseapp.com",
    databaseURL: "https://room-filler-engine.firebaseio.com",
    projectId: "clean-stays",
    storageBucket: "clean-stays.appspot.com",
    messagingSenderId: "423304962019",
    appId: "1:423304962019:web:cfcf299bfff597aff2cad6"
};

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}



export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage()

function httpGet(url) {
    new Promise(function (resolve, reject) {
        let xhr = new XMLHttpRequest();
        xhr.open('GET', url);
        xhr.onload = function () {
            if (this.status >= 200 && this.status < 300) {
                resolve(xhr.response);
            } else {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            }
        };
        xhr.onerror = function () {
            reject({
                status: this.status,
                statusText: xhr.statusText
            });
        };
        xhr.send();
    });
}

export const userProfileData = async (uid) => {
    const userDocument = await firestore.doc(`users/${uid}`);

    const snapShot = await userDocument.get();
    if (snapShot.exists) {
        return snapShot.data()
    } else {
        return false;
    }
}

export const cbAfterAuthLoaded = async (cb) => {
    return firebase.auth().onAuthStateChanged((auth) => cb(auth));
}


export const createUserToDB = async (uid, displayName, dataObject) => {
    const userDocument = await firestore.doc(`users/${uid}`);
    const pendingInvites = await firestore.collection('pendingTeamInvites').get();
    dataObject = { ...dataObject, accountType: 'Admin', email: firebase.auth().currentUser.email };
    for (var x = 0; x < pendingInvites.docs.length; x++) {
        const invite = pendingInvites.docs[x].data();
        const teamID = Object.keys(invite)[0];
        console.log(invite)
        if (dataObject.email.toLowerCase() === invite[teamID].email.toLowerCase()) {
            dataObject.accountType = invite[teamID].role;
            const newlyJoinedTeam = (await firestore.collection('teams').doc(teamID).get()).data();
            newlyJoinedTeam.members.push({
                id: uid,
                access: ['tasks', 'guests', 'listings', 'finances', 'teams'],
                role: dataObject.accountType,
                email: dataObject.email,
                displayName
            });

            await firestore.collection('teams').doc(teamID).update(newlyJoinedTeam);
            await firestore.collection('pendingTeamInvites').doc(pendingInvites.docs[x].id).delete();
        };
    };
    const { accountType, email } = dataObject;

    await userDocument.set({
        ...dataObject,
        displayName,
        id: uid
    });
    await firebase.auth().currentUser.updateProfile({
        displayName: displayName,
    })

    if (accountType === 'Admin') {
        const teamRef = firestore.collection('teams').doc();
        teamRef.set({
            id: teamRef.id,
            ownerID: uid,
            members: [{
                id: uid,
                access: ['tasks', 'guests', 'listings', 'finances', 'teams'],
                role: 'Admin',
                displayName,
                email
            }],
            properties: []
        }).then(() => {
            window.location.reload();
        });
    } else {
        window.location.reload();
    }

}


export const createTemplate = async (uid, obj) => {
    const documentRef = await firestore.doc(`users/${uid}`);
    try {
        await documentRef.update({
            templates: firebase.firestore.FieldValue.arrayUnion(obj)
        })
        return true;
    } catch (err) {
        return false
    }

}

export default firebase;

/* Extracting This function for better reusabilty */
const createProperty = async (propertyRef, obj, handleLoading, history, thumbnail) => {
    await propertyRef.set({
        id: propertyRef.id,
        ...obj,
        thumbnail,
        taskLists: [],
        calendars: []
    });

    const email = firebase.auth().currentUser.email;
    const team = await getMyTeam(email);
    if (!team[0].properties) {
        team[0].properties = [];
    }
    team[0].properties.push(propertyRef.id);
    await updateMyTeam(email, team[0]);

    handleLoading(false)
    history.push("/dashboard/listings");
}


export const createPropertyToDb = async (obj, photo, handleLoading, history) => {
    const propertyRef = firestore.collection('properties').doc();
    console.log(photo)

    if (!photo) {

        await createProperty(propertyRef, obj, handleLoading, history, '')

        return
    }
    var xhr = new XMLHttpRequest;
    xhr.responseType = 'blob';
    xhr.onload = function () {
        var recoveredBlob = xhr.response;
        // 'file' comes from the Blob or File API
        const storageRef = storage.ref();
        storageRef.child(`properties/${propertyRef.id}/thumbnail.jpg`).put(recoveredBlob).then((snapshot) => {
            storageRef.child(`properties/${propertyRef.id}/thumbnail.jpg`).getDownloadURL().then(async url => {
                try {
                    await createProperty(propertyRef, obj, handleLoading, history, url)
                } catch (err) {
                    console.log(err)
                }
            });
        });
    };
    xhr.open('GET', photo);
    xhr.send();
}

export const updateProperty = async (listing, listingId) => {


    const docRef = firestore.doc(`properties/${listingId}`);
    await docRef.update({
        ...listing
    });
}

export const createTask = async (obj) => {

    const docRef = firestore.collection('tasks').doc();
    docRef.set({
        ...obj
    })

}

export const getAllProperties = async () => {
    const docRef = await firestore.collection('properties').get();
    const data = docRef.docs.map(el => el.data());
    return data;
}

export const getAllTeamProperties = async (team) => {
    const promises = [];
    const properties = [];
    if (!team || !team.properties) return [];
    team.properties.forEach((id) => {
        promises.push(firestore.collection('properties').doc(id).get());
    });
    const results = await Promise.all(promises);
    results.forEach((result) => {
        properties.push(result.data());
    })
    return properties;
}

export const getSchedule = async (propertyId, scheduleId) => {
    const scheduleRef = await firestore.doc(`schedule/${propertyId}/dates/${scheduleId}`).get()
    const data = await scheduleRef.data();
    return data;

}
export const getScheduleRealtime = (propertyId, scheduleId) => {
    return firestore.doc(`schedule/${propertyId}/dates/${scheduleId}`);
}
export const updateScheduleRealtime = (propertyId, schedule) => {
    return firestore.doc(`schedule/${propertyId}/dates/${schedule.id}`).update(schedule);
}
export const getTeamSchedule = async (team) => {
    const promises = [];
    let schedule = [];
    if (!team || !team.properties) return [];
    team.properties.forEach((id) => {
        promises.push(firestore.collection('schedule').doc(id).collection('dates').get());
    });
    const results = await Promise.all(promises);
    for (var x = 0; x < results.length;) {
        schedule = [...schedule, ...results[x].docs.map(el => el.data())];
        x++;
    }
     return schedule.sort((a, b) => { return a.id - b.id });
}
export const getTeamScheduleRealtime = (propertyId, scheduleId) => {
    return firestore.collection('schedule');
}
export const updateTeamSchedule = async (id, dateID, cleanerID) => {

    const propertyRef = firestore.collection('schedule').doc(id).collection('dates').doc(dateID);

    await propertyRef.update({
        assignedTo: firebase.firestore.FieldValue.arrayUnion(cleanerID)

    })
}


export const getCleanerWorkingTime  = async  (team) => {
    const promises = [];
    let schedule = [];
    if (!team || !team.properties) return [];
    team.properties.forEach((id) => {
        promises.push(firestore.collection('schedule').doc(id).collection('dates').get());
    });
    const results = await Promise.all(promises);
    for (var x = 0; x < results.length;) {
        schedule = [...schedule, ...results[x].docs.map(el => el.data())];
        x++;
    }
    return schedule.sort((a, b) => { return a.id - b.id });

}

export const inviteMembers = async (arr, teamUID) => {
    const inputArray = [...arr]
    const teamRef = firestore.doc(`teams/${teamUID}`)
    const userCollection = await firestore.collection('users').get();
    const allUsers = await userCollection.docs.map(el => el.data())
    const registeredUser = arr.filter(invite => allUsers.some(user => user.email === invite.email));
    const unregisteredUser = inputArray.filter(invite => !allUsers.some(user => user.email === invite.email))


   registeredUser.forEach(async el => {
       const freshUser = await (await firestore.collection('users').where('email', '==', el.email).get()).docs.map(el => el.data())
      await teamRef.update({
          members: firebase.firestore.FieldValue.arrayUnion({ ...el, id: freshUser[0].id, displayName: freshUser[0].displayName })
      })
   })

   if (unregisteredUser.length > 0) {
       unregisteredUser.forEach(async (el) => {
           const pendingTeamRef = firestore.collection('pendingTeamInvites').doc()
           await pendingTeamRef.set({
               [teamUID]: {
                   ...el,
                   refID: pendingTeamRef.id,
                   teamUID
               }
           })
       })
   }
}

export const getMyTeam = async (email) => {
    const collectionRef = await firestore.collection('teams').get();
    const teamData = collectionRef.docs.map(el => el.data());
    const myTeamDetails = teamData.filter(team => team.members.some(el => el && el.email && email && el.email.toLowerCase() === email.toLowerCase()));
    return myTeamDetails;
}

export const getMyOwnedTeam = async (id) => {
    const collectionRef = await firestore.collection('teams').get();
    const teamData = collectionRef.docs.map(el => el.data());
    const myTeamDetails = teamData.filter(team => team.ownerID === id);
    return myTeamDetails[0];
}


export const updateMyTeam = async (email, team) => {
    const docRef = await firestore.doc(`teams/${team.id}`)
    await docRef.update(team)

}

export const getMyTeamPendingInvites = async (teamID) => {
    const docRef = await firestore.collection('pendingTeamInvites').get();
    const allPendingData = docRef.docs.map(el => el.data());
    const myPendings = allPendingData.filter(el => el[teamID])

    return myPendings;
}

// Function for removing team-members from the Team

export const removeTeamMembers = async (id, teamUID) => {

    const docRef = firestore.doc(`teams/${teamUID}`);
    const dataObj = await docRef.get()
    const teamMembers = await dataObj.data().members.filter(el => el.id !== id)
    docRef.update({
        members: teamMembers
    })

}

export const getProperty = async (listingId) => {
    const propertyRef = firestore.collection(`properties`);
    const data = await propertyRef.where('id', '==', listingId).get();
    const property = data.docs.map(el => el.data());
    return property;
}
export const getPropertyById = async (id) => {
    const ref = await firestore.doc(`properties/${id}`).get();
    const data = ref.data();


    return data

}
export const getTaskLists = async (setTaskLists,teamID) => {
    let data = []

    firestore.collection("taskLists").where('teamID', '==', teamID)
        .onSnapshot((querySnapshot) => {
            const taskList = []
            querySnapshot.forEach((doc) => {
                taskList.push(doc.data());
                data.push(doc.data());
            });
            setTaskLists(taskList)

        });

}


export const addCalendarToDB = async (calendarLink, listingId, calendarBrand, id) => {
    const docRef = firestore.doc(`properties/${listingId}`)
    await docRef.update({
        calendars: firebase.firestore.FieldValue.arrayUnion({ url: calendarLink, platform: calendarBrand, id })
    })
    await httpGet('https://us-central1-clean-stays.cloudfunctions.net/updateAllCalendars');
}

export const removeCalendarFromDB = async (calendarObj, listingData) => {
    console.log(calendarObj.id, listingData.calendars[0].id)
    const propertyRef = firestore.doc(`properties/${listingData.id}`)
    const filteredCalendar = listingData.calendars.filter(el => el.id === calendarObj.id);
    if (filteredCalendar.length === 1) {
        await propertyRef.update({
            calendars: []
        });
        return;
    }

    await propertyRef.update({
        calendars: filteredCalendar
    });
}


export const createTaskList = async (currentTaskList,teamID) => {
    const collectionRef = firestore.collection('taskLists').doc()
    await collectionRef.set({
        id: collectionRef.id,
        createdBy: firebase.auth().currentUser.uid,
        teamID,
        ...currentTaskList
    });
}

export const editTaskList = async (taskList, id) => {
    const collectionRef = firestore.doc(`taskLists/${id}`)
    await collectionRef.update(taskList);
}


//Getting the Task by ID

export const getTaskListById = async (id) => {
    if (!id) return;
    const docRef = await firestore.doc(`taskLists/${id}`).get();
    return docRef.data()

}


//Get cleaners

export const getCleaners = async (id) => {
    const docRef = await firestore.doc(`teams/${id}`).get()
    const team = await docRef.data();
    const data = team.members.filter(el => (el.accountType || el.role || '').toLowerCase() === 'cleaner')
    return data;
}


//


export const updateScheduleItem = async (propertyId, scheduleId, key, value) => {
    const scheduleRef = firestore.doc(`schedule/${propertyId}/dates/${scheduleId}`)

    await scheduleRef.update({
        [key]: value
    });
}

export const uploadImageTask = async (propertyID, scheduleID, sectionIdx, taskIDX, photo, setStorageImageUrl) => {
    var xhr = new XMLHttpRequest;
    xhr.responseType = 'blob';
    const updateImageUrl = async () => {
        xhr.onload = function () {
            var recoveredBlob = xhr.response;
            // 'file' comes from the Blob or File API
            const storageRef = storage.ref();
            storageRef.child(`schedule/${propertyID}/${scheduleID}/section-${sectionIdx}.task-${taskIDX}.jpg`).put(recoveredBlob).then((snapshot) => {
                storageRef.child(`schedule/${propertyID}/${scheduleID}/section-${sectionIdx}.task-${taskIDX}.jpg`).getDownloadURL().then(async url => {
                    setStorageImageUrl(url)
                });
            });
        };
        xhr.open('GET', photo);
        xhr.send();
    }

    await updateImageUrl()

}

export const updateTeamDetails = async (teamID, name, photo, existingPhoto) => {
    const docRef = firestore.doc(`teams/${teamID}`)

    if (!photo) {
        await docRef.update({
            settings: {
                logo: existingPhoto || '',
                name: name || ''
            }
        });
    } else {
        var xhr = new XMLHttpRequest;
        xhr.responseType = 'blob';
        const updateImageUrl = async () => {
            xhr.onload = function () {
                var recoveredBlob = xhr.response;
                // 'file' comes from the Blob or File API
                const storageRef = storage.ref();
                const task = storageRef.child(`teams/${teamID}/${name}.jpg`).put(recoveredBlob).then((snapshot) => {
                    /*                 const percantage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                                    document.getElementById('team-save').value = percantage */
                    storageRef.child(`teams/${teamID}/${name}.jpg`).getDownloadURL().then(async url => {
                        await docRef.update({
                            settings: {
                                logo: url,
                                name: name
                            }
                        })
                    })
                })
            };
            xhr.open('GET', photo);
            xhr.send();
        }

        await updateImageUrl()
    }



}

/* This function is for updating Team member role */

export const updateTeamMemberRole = async (data, teamUid) => {
    let freshData = { ...data };
    ['type', 'idx'].forEach(el => delete freshData[el])
    const teamRef = firestore.doc(`teams/${teamUid}`);
    const dataDb = await teamRef.get()
    const data1 = dataDb.data().members.filter(el => el.id !== data.id);


    teamRef.update({
        members: [...data1, freshData]
    })
}

/* This Function is for deleting Pending Team Invites */

export const removePendingMember = async (email) => {
    const teamRef = await firestore.collection('teams').get();
    const teamData = teamRef.docs.map(el => el.data())
    const propertyHaskKey = teamData.map(el => el.id)
    const pendingTeamCollection = await firestore.collection('pendingTeamInvites').get();
    const pendingData = pendingTeamCollection.docs.map(el => el.data())

    propertyHaskKey.forEach(el1 => pendingData.forEach(el2 => {
        if (el2[el1] && el2[el1].email === email) {
            const docRef = firestore.doc(`pendingTeamInvites/${el2[el1].refID}`)
            docRef.delete()
        }
    }))
}


/* Function for removing properties */

export const deleteProperty = async (id, teamUID, propertyTeam) => {
    const propertyRef = firestore.doc(`properties/${id}`)
    const teamRef = firestore.doc(`teams/${teamUID}`)
    console.log(propertyTeam)

    await teamRef.update({
        properties: propertyTeam

    })

    await propertyRef.delete()
}


export const createMailForAvailableCleaners = async (email) => {
    const emailRef = firestore.collection('mail');
    const docRef = emailRef.doc()
    const myTeam = await getMyTeam(email);

    await docRef.set({
        to: myTeam[0].members.map(el => el.email),
        message: {
            text: "New cleanings are available to claim!  Login to https://zioncleaning.co/ to check available cleanings.",
            subject: 'Available Cleanings',
            html: `
                <h5>New cleanings are available to claim!</h5>
                <p>Login to https://zioncleaning.co/ to check available cleanings.</p>
            `,
        },
    })


}


export const sendMailWhenCleanerSigned = async (id) => {
    const emailRef = firestore.collection('mail');
    const userRef = await (await firestore.doc(`users/${id}`).get()).data();
    const docRef = emailRef.doc();

    await docRef.set({
        to: userRef.email,
        message: {
            text: "A new cleaning has been assigned to you",
            subject: 'You have a new cleaning',
            html: `
                <h5>Check https://zioncleaning.co/ for more details about the cleaning</h5>
            `,
        },
    })

}


export const sendMailWhenNewTeamMemberInvited = async (email) => {
    email = email.map(el => el.email)
    const emailRef = firestore.collection('mail');
    const docRef = emailRef.doc();

    await docRef.set({
        to: email,
        message: {
            text: "Someone have invite you to https://zioncleaning.co/",
            subject: 'Team Invitation to Zion Cleaning',
            html: `
                <h5>Hello, you have been invited to join our team on clean-stays app</h5>
                <p>https://zioncleaning.co/</p>
            `,
        },
    })
}



export const sendMailWhenPropertyAssignedToAdmin = async (email) => {
    const emailRef = firestore.collection('mail');
    const docRef = emailRef.doc();
    await docRef.set({
        to: email,
        message: {
            text: "You've been added as a property owner.  Login or create an account @ https://zioncleaning.co/ to access this property.",
            subject: 'You\'ve been added as a property owner',
            html: `
                <h5>You have been added to a property as the owner.</h5>
                <p>Login or create an account @ https://zioncleaning.co/ to access this property.</p>
            `,
        },
    })
}


export const sendMailToAdminOnCleanClaim = async (email) => {
    const emailRef = firestore.collection('mail');
    const docRef = emailRef.doc();
    await docRef.set({
        to: email,
        message: {
            text: "A new cleaning has been claimed.  Login to the app (https://zioncleaning.co/) to view cleaning updates.",
            subject: 'A new cleaning has been claimed.',
            html: `
                <h5>A new cleaning has been claimed.</h5>
                <p>Login to the app (https://zioncleaning.co/) to view cleaning updates.</p>
            `,
        },
    })
}


export const updatePendingTeamData = async (obj, role) => {
    const collectionRef = await firestore.collection(`pendingTeamInvites`).get();
    const data = collectionRef.docs.map(el => el.data()).filter(el => el[obj.teamUID]).filter(el1 => el1[obj.teamUID].email === obj.email)[0][obj.teamUID];
    const docRef = firestore.doc(`pendingTeamInvites/${data.refID}`);
    docRef.update({
        [obj.teamUID]: {
            ...data,
            role
        }
    })

    // First I need to get that specific team member document and then need to update the document

}



export const getUser = async (userId) => {
    const ref = await firestore.doc(`users/${userId}`).get();
    const user = ref.data();

    return user.displayName
}