import React, { createContext, useReducer } from "react";
import { getAllTeams, getTeam, getTeamMember,addTeamMember, updateTeamMember, updateTeam, removeTeamMember } from "../../services/team";
import { getAllListings, getListing, addListing, updateListing, deleteListing } from "../../services/listing";
import {getAllActivities} from "../../services/activity";
import { User, Team, Task, TeamActions, Member, ListingActions, Property, Activity, ActivityActions } from "../../types";

export interface InitialStateType {
  loading: boolean;
  error: string;
  user: User | null;
  teams: Team[] | [];
  team: Team | null;
  tasks: Task[] | [];
  member: Member | null;
  isMemberUpdated: boolean;
  isTeamUpdated: boolean;
  listings: Property[] | [];
  listing: Property | null;
  isListingAdded: boolean;
  isListingUpdated: boolean;
  isListingDeleted: boolean;
  activities: Activity[] | [];
}

const initialState = {
  loading: false,
  error: "",
  user: null,
  teams: [],
  team: null,
  tasks: [],
  member: null,
  isMemberUpdated: false,
  isTeamUpdated: false,
  listings: [],
  listing: null,
  isListingAdded: false,
  isListingUpdated: false,
  isListingDeleted: false,
  activities: [],
};

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

const AppReducer = (state: InitialStateType, action: TeamActions | ListingActions | ActivityActions) => {
  switch (action.type) {
    // Team Reducer
    case "GET_ALL_TEAMS":
      getAllTeams(action.successAction);
      return { ...state, loading:true, error:"" };
    case "GET_ALL_TEAMS_SUCCESS":
      return { ...state, loading:false, error:"", teams: action.teams };
    case "GET_ALL_TEAMS_ERROR":
      return { ...state, loading:false, error:action.error, };
    case "GET_TEAM":
      getTeam(action.successAction, action.teamId)
      return { ...state, loading:true, error:"" };
    case "GET_TEAM_SUCCESS":
      return { ...state,loading:false, error:"", team: action.team };
    case "GET_TEAM_ERROR":
      return { ...state, loading:false, error:action.error, };
    case "GET_TEAM_MEMBER":
      getTeamMember(action.successAction, action.teamId, action.memberId)
      return { ...state, loading:true, error:"" };
    case "GET_TEAM_MEMBER_SUCCESS":
      return { ...state,loading:false, error:"", member: action.member };
    case "GET_TEAM_MEMBER_ERROR":
      return { ...state, loading:false, error:action.error, };
    // case "ADD_TEAM_MEMBER":
    //   return { ...state };
    case "UPDATE_TEAM_MEMBER":
      updateTeamMember(action.successAction, action.memberId, action.member)
      return { ...state, loading:true, error:""  };
    case "UPDATE_TEAM_MEMBER_SUCCESS":
      return { ...state, loading:false, error:"" , member: action.member, isMemberUpdated:true };
    case "UPDATE_TEAM_MEMBER_ERROR":
      return { ...state, loading:false, error: action.error ,};
    case "UPDATE_TEAM":
      updateTeam(action.successAction, action.teamId, action.name, action.logo, action.status);
      return { ...state, loading:true, error:""  };
    case "UPDATE_TEAM_SUCCESS":
      return { ...state,loading:false, error:"", team: action.team,isTeamUpdated:true };
    case "UPDATE_TEAM_ERROR":
      return { ...state, loading:false, error: action.error};
    case "REMOVE_TEAM_MEMBER":
      removeTeamMember(action.successAction, action.memberId)
      return { ...state, loading:true, error:"" };
    case "REMOVE_TEAM_MEMBER_SUCCESS":
      return { ...state, loading:false, error:"", team: action.team, isTeamUpdated:true };
    case "REMOVE_TEAM_MEMBER_ERROR":
      return { ...state, loading:false, error: action.error};
    case "RESET_TEAM_FLAGS":
      return { ...state, isMemberUpdated:false, isTeamUpdated:false  };
    // Listing Reducer
    case "GET_ALL_LISTINGS":
      getAllListings(action.successAction);
      return { ...state, loading:true, error:"" };
    case "GET_ALL_LISTINGS_SUCCESS":
      return { ...state, loading:false, error:"", listings: action.listings };
    case "GET_ALL_LISTINGS_ERROR":
      return { ...state, loading:false, error: action.error};
    case "GET_LISTING":
      getListing(action.successAction, action.listingId);
      return { ...state, loading:true, error:"" };
    case "GET_LISTING_SUCCESS":
      return { ...state,loading:false, error:"", listing: action.listing };
    case "GET_LISTING_ERROR":
      return { ...state, loading:false, error:action.error };
    case "ADD_LISTING":
      addListing(action.successAction, action.listing);
      return { ...state, loading:true, error:"" };
    case "ADD_LISTING_SUCCESS":
      return { ...state, loading:false, error:"",listings:action.listings, isListingAdded: true };
    case "ADD_LISTING_ERROR":
      return { ...state, loading:false, error:action.error };
    case "UPDATE_LISTING":
      updateListing(action.successAction, action.listingId, action.listing);
      return { ...state, loading:true, error:"" };
    case "UPDATE_LISTING_SUCCESS":
      return { ...state, listing: action.listing, isListingUpdated:true };
    case "UPDATE_LISTING_ERROR":
      return { ...state, loading:false, error:action.error };
    case "DELETE_LISTING":
      deleteListing(action.successAction,action.listingId)
      return { ...state, loading:true, error:"" };
    case "DELETE_LISTING_SUCCESS":
      return { ...state, loading:false, error:"" , listings: action.listings, isListingDeleted:true };
    case "DELETE_LISTING_ERROR":
      return { ...state, loading:false, error:action.error };
    case "RESET_LISTING_FLAGS":
      return { ...state, isListingAdded:false, isListingUpdated:false, isListingDeleted:false  };
    // Activity Reducer
    case "GET_ALL_ACTIVITIES":
      getAllActivities(action.successAction);
      return { ...state, loading:true, error:"" };
    case "GET_ALL_ACTIVITIES_SUCCESS":
      return { ...state, loading:false, error:"", activities: action.activities };
    case "GET_ALL_ACTIVITIES_ERROR":
      return { ...state, loading:false, error: action.error};
    default:
      throw new Error();
  }
};

const AppProvider: React.FC = ({ children }) => {
  //@ts-ignore
  const [state, dispatch] = useReducer(AppReducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
