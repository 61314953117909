import { string } from "prop-types";
import { createContext } from "react";

export const DashBoardContext = createContext({
  tasks: <any>[],
  addTasks: (task: object) => {},
  properties: {},
  taskDetails: {},
  addTaskDetails: (task: object) => {},
  cto: <any> '',
  setCto: <any> {}
});
