import React from "react";
import "./cardBox.css";
import DataTable from 'react-data-table-component';
import { Property } from '../types';
import "./customTable.css";

export interface CustomTableProps {
  data: Property[];
  columns: any;
  onRowClicked?: any;
  customCss?: object
}


const CustomTable: React.FC<CustomTableProps> = ({ data, columns, onRowClicked, customCss }) => {

  return (
    <DataTable
      columns={columns}
      data={data}
      selectableRows={false}
      highlightOnHover={true}
      pointerOnHover={true}
      pagination={true}
      className="custom-table"
      onRowClicked={onRowClicked}
      customStyles={customCss}
    />
  );
};

export default CustomTable;
