import React from "react";
import "./Table.css";
import {
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from "@ionic/react";
export interface tableProps {
  teamMember?: any[];
}

const Table: React.FC<tableProps> = ({  children }) => {
  return (
    <div className="team-table">
      <IonGrid>
        {children}
      </IonGrid>
    </div>
  );
};

export const TableRows = ({ children }) => {
  return <IonRow>{children}</IonRow>;
};

export const TableRow = ({ label, size,cssClass = '' }) => {
  return (
    <IonCol className={cssClass} size={size}>
      <IonText className="text-table-row">{label}</IonText>
    </IonCol>
  );
};

export const TableRowsItem = ({children }) => {
  return (
      <IonRow className='table-border'>
       {children}
       </IonRow>
  );
};

export const TableRowItem: React.FC<any> = ({children,size,isMiddle }) => {
  return (
    <IonCol className='table-row-item' size={size}>
            <p className="text-table-row text-table-row-item">{children}</p>
    </IonCol>
  );
};

export default Table;
